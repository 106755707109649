.radix-themes:where([data-scaling="90%"]) {
  --scaling: .9;
}

.radix-themes:where([data-scaling="95%"]) {
  --scaling: .95;
}

.radix-themes:where([data-scaling="100%"]) {
  --scaling: 1;
}

.radix-themes:where([data-scaling="105%"]) {
  --scaling: 1.05;
}

.radix-themes:where([data-scaling="110%"]) {
  --scaling: 1.1;
}

.radix-themes {
  --space-1: calc(4px * var(--scaling));
  --space-2: calc(8px * var(--scaling));
  --space-3: calc(12px * var(--scaling));
  --space-4: calc(16px * var(--scaling));
  --space-5: calc(24px * var(--scaling));
  --space-6: calc(32px * var(--scaling));
  --space-7: calc(40px * var(--scaling));
  --space-8: calc(48px * var(--scaling));
  --space-9: calc(64px * var(--scaling));
  --container-1: 448px;
  --container-2: 688px;
  --container-3: 880px;
  --container-4: 1136px;
}

.rt-Box {
  box-sizing: border-box;
  display: block;
}

.rt-Flex {
  box-sizing: border-box;
  justify-content: flex-start;
  display: flex;
}

.rt-Grid {
  box-sizing: border-box;
  grid-template-rows: none;
  grid-template-columns: minmax(0, 1fr);
  justify-content: flex-start;
  align-items: stretch;
  display: grid;
}

.rt-Section {
  box-sizing: border-box;
  flex-shrink: 0;
}

.rt-Section:where(.rt-r-size-1) {
  padding-top: var(--space-5);
  padding-bottom: var(--space-5);
}

.rt-Section:where(.rt-r-size-2) {
  padding-top: var(--space-7);
  padding-bottom: var(--space-7);
}

.rt-Section:where(.rt-r-size-3) {
  padding-top: var(--space-9);
  padding-bottom: var(--space-9);
}

.rt-Section:where(.rt-r-size-4) {
  padding-top: calc(80px * var(--scaling));
  padding-bottom: calc(80px * var(--scaling));
}

@media (min-width: 520px) {
  .rt-Section:where(.xs\:rt-r-size-1) {
    padding-top: var(--space-5);
    padding-bottom: var(--space-5);
  }

  .rt-Section:where(.xs\:rt-r-size-2) {
    padding-top: var(--space-7);
    padding-bottom: var(--space-7);
  }

  .rt-Section:where(.xs\:rt-r-size-3) {
    padding-top: var(--space-9);
    padding-bottom: var(--space-9);
  }

  .rt-Section:where(.xs\:rt-r-size-4) {
    padding-top: calc(80px * var(--scaling));
    padding-bottom: calc(80px * var(--scaling));
  }
}

@media (min-width: 768px) {
  .rt-Section:where(.sm\:rt-r-size-1) {
    padding-top: var(--space-5);
    padding-bottom: var(--space-5);
  }

  .rt-Section:where(.sm\:rt-r-size-2) {
    padding-top: var(--space-7);
    padding-bottom: var(--space-7);
  }

  .rt-Section:where(.sm\:rt-r-size-3) {
    padding-top: var(--space-9);
    padding-bottom: var(--space-9);
  }

  .rt-Section:where(.sm\:rt-r-size-4) {
    padding-top: calc(80px * var(--scaling));
    padding-bottom: calc(80px * var(--scaling));
  }
}

@media (min-width: 1024px) {
  .rt-Section:where(.md\:rt-r-size-1) {
    padding-top: var(--space-5);
    padding-bottom: var(--space-5);
  }

  .rt-Section:where(.md\:rt-r-size-2) {
    padding-top: var(--space-7);
    padding-bottom: var(--space-7);
  }

  .rt-Section:where(.md\:rt-r-size-3) {
    padding-top: var(--space-9);
    padding-bottom: var(--space-9);
  }

  .rt-Section:where(.md\:rt-r-size-4) {
    padding-top: calc(80px * var(--scaling));
    padding-bottom: calc(80px * var(--scaling));
  }
}

@media (min-width: 1280px) {
  .rt-Section:where(.lg\:rt-r-size-1) {
    padding-top: var(--space-5);
    padding-bottom: var(--space-5);
  }

  .rt-Section:where(.lg\:rt-r-size-2) {
    padding-top: var(--space-7);
    padding-bottom: var(--space-7);
  }

  .rt-Section:where(.lg\:rt-r-size-3) {
    padding-top: var(--space-9);
    padding-bottom: var(--space-9);
  }

  .rt-Section:where(.lg\:rt-r-size-4) {
    padding-top: calc(80px * var(--scaling));
    padding-bottom: calc(80px * var(--scaling));
  }
}

@media (min-width: 1640px) {
  .rt-Section:where(.xl\:rt-r-size-1) {
    padding-top: var(--space-5);
    padding-bottom: var(--space-5);
  }

  .rt-Section:where(.xl\:rt-r-size-2) {
    padding-top: var(--space-7);
    padding-bottom: var(--space-7);
  }

  .rt-Section:where(.xl\:rt-r-size-3) {
    padding-top: var(--space-9);
    padding-bottom: var(--space-9);
  }

  .rt-Section:where(.xl\:rt-r-size-4) {
    padding-top: calc(80px * var(--scaling));
    padding-bottom: calc(80px * var(--scaling));
  }
}

.rt-Container {
  box-sizing: border-box;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  align-items: center;
  display: flex;
}

.rt-ContainerInner {
  width: 100%;
}

:where(.rt-Container.rt-r-size-1) .rt-ContainerInner {
  max-width: var(--container-1);
}

:where(.rt-Container.rt-r-size-2) .rt-ContainerInner {
  max-width: var(--container-2);
}

:where(.rt-Container.rt-r-size-3) .rt-ContainerInner {
  max-width: var(--container-3);
}

:where(.rt-Container.rt-r-size-4) .rt-ContainerInner {
  max-width: var(--container-4);
}

@media (min-width: 520px) {
  :where(.rt-Container.xs\:rt-r-size-1) .rt-ContainerInner {
    max-width: var(--container-1);
  }

  :where(.rt-Container.xs\:rt-r-size-2) .rt-ContainerInner {
    max-width: var(--container-2);
  }

  :where(.rt-Container.xs\:rt-r-size-3) .rt-ContainerInner {
    max-width: var(--container-3);
  }

  :where(.rt-Container.xs\:rt-r-size-4) .rt-ContainerInner {
    max-width: var(--container-4);
  }
}

@media (min-width: 768px) {
  :where(.rt-Container.sm\:rt-r-size-1) .rt-ContainerInner {
    max-width: var(--container-1);
  }

  :where(.rt-Container.sm\:rt-r-size-2) .rt-ContainerInner {
    max-width: var(--container-2);
  }

  :where(.rt-Container.sm\:rt-r-size-3) .rt-ContainerInner {
    max-width: var(--container-3);
  }

  :where(.rt-Container.sm\:rt-r-size-4) .rt-ContainerInner {
    max-width: var(--container-4);
  }
}

@media (min-width: 1024px) {
  :where(.rt-Container.md\:rt-r-size-1) .rt-ContainerInner {
    max-width: var(--container-1);
  }

  :where(.rt-Container.md\:rt-r-size-2) .rt-ContainerInner {
    max-width: var(--container-2);
  }

  :where(.rt-Container.md\:rt-r-size-3) .rt-ContainerInner {
    max-width: var(--container-3);
  }

  :where(.rt-Container.md\:rt-r-size-4) .rt-ContainerInner {
    max-width: var(--container-4);
  }
}

@media (min-width: 1280px) {
  :where(.rt-Container.lg\:rt-r-size-1) .rt-ContainerInner {
    max-width: var(--container-1);
  }

  :where(.rt-Container.lg\:rt-r-size-2) .rt-ContainerInner {
    max-width: var(--container-2);
  }

  :where(.rt-Container.lg\:rt-r-size-3) .rt-ContainerInner {
    max-width: var(--container-3);
  }

  :where(.rt-Container.lg\:rt-r-size-4) .rt-ContainerInner {
    max-width: var(--container-4);
  }
}

@media (min-width: 1640px) {
  :where(.rt-Container.xl\:rt-r-size-1) .rt-ContainerInner {
    max-width: var(--container-1);
  }

  :where(.rt-Container.xl\:rt-r-size-2) .rt-ContainerInner {
    max-width: var(--container-2);
  }

  :where(.rt-Container.xl\:rt-r-size-3) .rt-ContainerInner {
    max-width: var(--container-3);
  }

  :where(.rt-Container.xl\:rt-r-size-4) .rt-ContainerInner {
    max-width: var(--container-4);
  }
}

.rt-r-ai-start {
  align-items: flex-start;
}

.rt-r-ai-center {
  align-items: center;
}

.rt-r-ai-end {
  align-items: flex-end;
}

.rt-r-ai-baseline {
  align-items: baseline;
}

.rt-r-ai-stretch {
  align-items: stretch;
}

@media (min-width: 520px) {
  .xs\:rt-r-ai-start {
    align-items: flex-start;
  }

  .xs\:rt-r-ai-center {
    align-items: center;
  }

  .xs\:rt-r-ai-end {
    align-items: flex-end;
  }

  .xs\:rt-r-ai-baseline {
    align-items: baseline;
  }

  .xs\:rt-r-ai-stretch {
    align-items: stretch;
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-ai-start {
    align-items: flex-start;
  }

  .sm\:rt-r-ai-center {
    align-items: center;
  }

  .sm\:rt-r-ai-end {
    align-items: flex-end;
  }

  .sm\:rt-r-ai-baseline {
    align-items: baseline;
  }

  .sm\:rt-r-ai-stretch {
    align-items: stretch;
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-ai-start {
    align-items: flex-start;
  }

  .md\:rt-r-ai-center {
    align-items: center;
  }

  .md\:rt-r-ai-end {
    align-items: flex-end;
  }

  .md\:rt-r-ai-baseline {
    align-items: baseline;
  }

  .md\:rt-r-ai-stretch {
    align-items: stretch;
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-ai-start {
    align-items: flex-start;
  }

  .lg\:rt-r-ai-center {
    align-items: center;
  }

  .lg\:rt-r-ai-end {
    align-items: flex-end;
  }

  .lg\:rt-r-ai-baseline {
    align-items: baseline;
  }

  .lg\:rt-r-ai-stretch {
    align-items: stretch;
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-ai-start {
    align-items: flex-start;
  }

  .xl\:rt-r-ai-center {
    align-items: center;
  }

  .xl\:rt-r-ai-end {
    align-items: flex-end;
  }

  .xl\:rt-r-ai-baseline {
    align-items: baseline;
  }

  .xl\:rt-r-ai-stretch {
    align-items: stretch;
  }
}

.rt-r-as-start {
  align-self: flex-start;
}

.rt-r-as-center {
  align-self: center;
}

.rt-r-as-end {
  align-self: flex-end;
}

.rt-r-as-baseline {
  align-self: baseline;
}

.rt-r-as-stretch {
  align-self: stretch;
}

@media (min-width: 520px) {
  .xs\:rt-r-as-start {
    align-self: flex-start;
  }

  .xs\:rt-r-as-center {
    align-self: center;
  }

  .xs\:rt-r-as-end {
    align-self: flex-end;
  }

  .xs\:rt-r-as-baseline {
    align-self: baseline;
  }

  .xs\:rt-r-as-stretch {
    align-self: stretch;
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-as-start {
    align-self: flex-start;
  }

  .sm\:rt-r-as-center {
    align-self: center;
  }

  .sm\:rt-r-as-end {
    align-self: flex-end;
  }

  .sm\:rt-r-as-baseline {
    align-self: baseline;
  }

  .sm\:rt-r-as-stretch {
    align-self: stretch;
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-as-start {
    align-self: flex-start;
  }

  .md\:rt-r-as-center {
    align-self: center;
  }

  .md\:rt-r-as-end {
    align-self: flex-end;
  }

  .md\:rt-r-as-baseline {
    align-self: baseline;
  }

  .md\:rt-r-as-stretch {
    align-self: stretch;
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-as-start {
    align-self: flex-start;
  }

  .lg\:rt-r-as-center {
    align-self: center;
  }

  .lg\:rt-r-as-end {
    align-self: flex-end;
  }

  .lg\:rt-r-as-baseline {
    align-self: baseline;
  }

  .lg\:rt-r-as-stretch {
    align-self: stretch;
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-as-start {
    align-self: flex-start;
  }

  .xl\:rt-r-as-center {
    align-self: center;
  }

  .xl\:rt-r-as-end {
    align-self: flex-end;
  }

  .xl\:rt-r-as-baseline {
    align-self: baseline;
  }

  .xl\:rt-r-as-stretch {
    align-self: stretch;
  }
}

.rt-r-display-block {
  display: block;
}

.rt-r-display-inline {
  display: inline;
}

.rt-r-display-inline-block {
  display: inline-block;
}

.rt-r-display-flex {
  display: flex;
}

.rt-r-display-inline-flex {
  display: inline-flex;
}

.rt-r-display-grid {
  display: grid;
}

.rt-r-display-inline-grid {
  display: inline-grid;
}

.rt-r-display-none {
  display: none;
}

@media (min-width: 520px) {
  .xs\:rt-r-display-block {
    display: block;
  }

  .xs\:rt-r-display-inline {
    display: inline;
  }

  .xs\:rt-r-display-inline-block {
    display: inline-block;
  }

  .xs\:rt-r-display-flex {
    display: flex;
  }

  .xs\:rt-r-display-inline-flex {
    display: inline-flex;
  }

  .xs\:rt-r-display-grid {
    display: grid;
  }

  .xs\:rt-r-display-inline-grid {
    display: inline-grid;
  }

  .xs\:rt-r-display-none {
    display: none;
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-display-block {
    display: block;
  }

  .sm\:rt-r-display-inline {
    display: inline;
  }

  .sm\:rt-r-display-inline-block {
    display: inline-block;
  }

  .sm\:rt-r-display-flex {
    display: flex;
  }

  .sm\:rt-r-display-inline-flex {
    display: inline-flex;
  }

  .sm\:rt-r-display-grid {
    display: grid;
  }

  .sm\:rt-r-display-inline-grid {
    display: inline-grid;
  }

  .sm\:rt-r-display-none {
    display: none;
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-display-block {
    display: block;
  }

  .md\:rt-r-display-inline {
    display: inline;
  }

  .md\:rt-r-display-inline-block {
    display: inline-block;
  }

  .md\:rt-r-display-flex {
    display: flex;
  }

  .md\:rt-r-display-inline-flex {
    display: inline-flex;
  }

  .md\:rt-r-display-grid {
    display: grid;
  }

  .md\:rt-r-display-inline-grid {
    display: inline-grid;
  }

  .md\:rt-r-display-none {
    display: none;
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-display-block {
    display: block;
  }

  .lg\:rt-r-display-inline {
    display: inline;
  }

  .lg\:rt-r-display-inline-block {
    display: inline-block;
  }

  .lg\:rt-r-display-flex {
    display: flex;
  }

  .lg\:rt-r-display-inline-flex {
    display: inline-flex;
  }

  .lg\:rt-r-display-grid {
    display: grid;
  }

  .lg\:rt-r-display-inline-grid {
    display: inline-grid;
  }

  .lg\:rt-r-display-none {
    display: none;
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-display-block {
    display: block;
  }

  .xl\:rt-r-display-inline {
    display: inline;
  }

  .xl\:rt-r-display-inline-block {
    display: inline-block;
  }

  .xl\:rt-r-display-flex {
    display: flex;
  }

  .xl\:rt-r-display-inline-flex {
    display: inline-flex;
  }

  .xl\:rt-r-display-grid {
    display: grid;
  }

  .xl\:rt-r-display-inline-grid {
    display: inline-grid;
  }

  .xl\:rt-r-display-none {
    display: none;
  }
}

.rt-r-fb {
  flex-basis: var(--flex-basis);
}

@media (min-width: 520px) {
  .xs\:rt-r-fb {
    flex-basis: var(--flex-basis-xs);
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-fb {
    flex-basis: var(--flex-basis-sm);
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-fb {
    flex-basis: var(--flex-basis-md);
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-fb {
    flex-basis: var(--flex-basis-lg);
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-fb {
    flex-basis: var(--flex-basis-xl);
  }
}

.rt-r-fd-row {
  flex-direction: row;
}

.rt-r-fd-column {
  flex-direction: column;
}

.rt-r-fd-row-reverse {
  flex-direction: row-reverse;
}

.rt-r-fd-column-reverse {
  flex-direction: column-reverse;
}

@media (min-width: 520px) {
  .xs\:rt-r-fd-row {
    flex-direction: row;
  }

  .xs\:rt-r-fd-column {
    flex-direction: column;
  }

  .xs\:rt-r-fd-row-reverse {
    flex-direction: row-reverse;
  }

  .xs\:rt-r-fd-column-reverse {
    flex-direction: column-reverse;
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-fd-row {
    flex-direction: row;
  }

  .sm\:rt-r-fd-column {
    flex-direction: column;
  }

  .sm\:rt-r-fd-row-reverse {
    flex-direction: row-reverse;
  }

  .sm\:rt-r-fd-column-reverse {
    flex-direction: column-reverse;
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-fd-row {
    flex-direction: row;
  }

  .md\:rt-r-fd-column {
    flex-direction: column;
  }

  .md\:rt-r-fd-row-reverse {
    flex-direction: row-reverse;
  }

  .md\:rt-r-fd-column-reverse {
    flex-direction: column-reverse;
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-fd-row {
    flex-direction: row;
  }

  .lg\:rt-r-fd-column {
    flex-direction: column;
  }

  .lg\:rt-r-fd-row-reverse {
    flex-direction: row-reverse;
  }

  .lg\:rt-r-fd-column-reverse {
    flex-direction: column-reverse;
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-fd-row {
    flex-direction: row;
  }

  .xl\:rt-r-fd-column {
    flex-direction: column;
  }

  .xl\:rt-r-fd-row-reverse {
    flex-direction: row-reverse;
  }

  .xl\:rt-r-fd-column-reverse {
    flex-direction: column-reverse;
  }
}

.rt-r-fg {
  flex-grow: var(--flex-grow);
}

.rt-r-fg-0 {
  flex-grow: 0;
}

.rt-r-fg-1 {
  flex-grow: 1;
}

@media (min-width: 520px) {
  .xs\:rt-r-fg {
    flex-grow: var(--flex-grow-xs);
  }

  .xs\:rt-r-fg-0 {
    flex-grow: 0;
  }

  .xs\:rt-r-fg-1 {
    flex-grow: 1;
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-fg {
    flex-grow: var(--flex-grow-sm);
  }

  .sm\:rt-r-fg-0 {
    flex-grow: 0;
  }

  .sm\:rt-r-fg-1 {
    flex-grow: 1;
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-fg {
    flex-grow: var(--flex-grow-md);
  }

  .md\:rt-r-fg-0 {
    flex-grow: 0;
  }

  .md\:rt-r-fg-1 {
    flex-grow: 1;
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-fg {
    flex-grow: var(--flex-grow-lg);
  }

  .lg\:rt-r-fg-0 {
    flex-grow: 0;
  }

  .lg\:rt-r-fg-1 {
    flex-grow: 1;
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-fg {
    flex-grow: var(--flex-grow-xl);
  }

  .xl\:rt-r-fg-0 {
    flex-grow: 0;
  }

  .xl\:rt-r-fg-1 {
    flex-grow: 1;
  }
}

.rt-r-fs {
  flex-shrink: var(--flex-shrink);
}

.rt-r-fs-0 {
  flex-shrink: 0;
}

.rt-r-fs-1 {
  flex-shrink: 1;
}

@media (min-width: 520px) {
  .xs\:rt-r-fs {
    flex-shrink: var(--flex-shrink-xs);
  }

  .xs\:rt-r-fs-0 {
    flex-shrink: 0;
  }

  .xs\:rt-r-fs-1 {
    flex-shrink: 1;
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-fs {
    flex-shrink: var(--flex-shrink-sm);
  }

  .sm\:rt-r-fs-0 {
    flex-shrink: 0;
  }

  .sm\:rt-r-fs-1 {
    flex-shrink: 1;
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-fs {
    flex-shrink: var(--flex-shrink-md);
  }

  .md\:rt-r-fs-0 {
    flex-shrink: 0;
  }

  .md\:rt-r-fs-1 {
    flex-shrink: 1;
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-fs {
    flex-shrink: var(--flex-shrink-lg);
  }

  .lg\:rt-r-fs-0 {
    flex-shrink: 0;
  }

  .lg\:rt-r-fs-1 {
    flex-shrink: 1;
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-fs {
    flex-shrink: var(--flex-shrink-xl);
  }

  .xl\:rt-r-fs-0 {
    flex-shrink: 0;
  }

  .xl\:rt-r-fs-1 {
    flex-shrink: 1;
  }
}

.rt-r-fw-nowrap {
  flex-wrap: nowrap;
}

.rt-r-fw-wrap {
  flex-wrap: wrap;
}

.rt-r-fw-wrap-reverse {
  flex-wrap: wrap-reverse;
}

@media (min-width: 520px) {
  .xs\:rt-r-fw-nowrap {
    flex-wrap: nowrap;
  }

  .xs\:rt-r-fw-wrap {
    flex-wrap: wrap;
  }

  .xs\:rt-r-fw-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-fw-nowrap {
    flex-wrap: nowrap;
  }

  .sm\:rt-r-fw-wrap {
    flex-wrap: wrap;
  }

  .sm\:rt-r-fw-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-fw-nowrap {
    flex-wrap: nowrap;
  }

  .md\:rt-r-fw-wrap {
    flex-wrap: wrap;
  }

  .md\:rt-r-fw-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-fw-nowrap {
    flex-wrap: nowrap;
  }

  .lg\:rt-r-fw-wrap {
    flex-wrap: wrap;
  }

  .lg\:rt-r-fw-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-fw-nowrap {
    flex-wrap: nowrap;
  }

  .xl\:rt-r-fw-wrap {
    flex-wrap: wrap;
  }

  .xl\:rt-r-fw-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
}

.rt-r-gap {
  gap: var(--gap);
}

.rt-r-gap-0 {
  gap: 0;
}

.rt-r-gap-1 {
  gap: var(--space-1);
}

.rt-r-gap-2 {
  gap: var(--space-2);
}

.rt-r-gap-3 {
  gap: var(--space-3);
}

.rt-r-gap-4 {
  gap: var(--space-4);
}

.rt-r-gap-5 {
  gap: var(--space-5);
}

.rt-r-gap-6 {
  gap: var(--space-6);
}

.rt-r-gap-7 {
  gap: var(--space-7);
}

.rt-r-gap-8 {
  gap: var(--space-8);
}

.rt-r-gap-9 {
  gap: var(--space-9);
}

.rt-r-cg {
  column-gap: var(--column-gap);
}

.rt-r-cg-0 {
  column-gap: 0;
}

.rt-r-cg-1 {
  column-gap: var(--space-1);
}

.rt-r-cg-2 {
  column-gap: var(--space-2);
}

.rt-r-cg-3 {
  column-gap: var(--space-3);
}

.rt-r-cg-4 {
  column-gap: var(--space-4);
}

.rt-r-cg-5 {
  column-gap: var(--space-5);
}

.rt-r-cg-6 {
  column-gap: var(--space-6);
}

.rt-r-cg-7 {
  column-gap: var(--space-7);
}

.rt-r-cg-8 {
  column-gap: var(--space-8);
}

.rt-r-cg-9 {
  column-gap: var(--space-9);
}

.rt-r-rg {
  row-gap: var(--row-gap);
}

.rt-r-rg-0 {
  row-gap: 0;
}

.rt-r-rg-1 {
  row-gap: var(--space-1);
}

.rt-r-rg-2 {
  row-gap: var(--space-2);
}

.rt-r-rg-3 {
  row-gap: var(--space-3);
}

.rt-r-rg-4 {
  row-gap: var(--space-4);
}

.rt-r-rg-5 {
  row-gap: var(--space-5);
}

.rt-r-rg-6 {
  row-gap: var(--space-6);
}

.rt-r-rg-7 {
  row-gap: var(--space-7);
}

.rt-r-rg-8 {
  row-gap: var(--space-8);
}

.rt-r-rg-9 {
  row-gap: var(--space-9);
}

@media (min-width: 520px) {
  .xs\:rt-r-gap {
    gap: var(--gap-xs);
  }

  .xs\:rt-r-gap-0 {
    gap: 0;
  }

  .xs\:rt-r-gap-1 {
    gap: var(--space-1);
  }

  .xs\:rt-r-gap-2 {
    gap: var(--space-2);
  }

  .xs\:rt-r-gap-3 {
    gap: var(--space-3);
  }

  .xs\:rt-r-gap-4 {
    gap: var(--space-4);
  }

  .xs\:rt-r-gap-5 {
    gap: var(--space-5);
  }

  .xs\:rt-r-gap-6 {
    gap: var(--space-6);
  }

  .xs\:rt-r-gap-7 {
    gap: var(--space-7);
  }

  .xs\:rt-r-gap-8 {
    gap: var(--space-8);
  }

  .xs\:rt-r-gap-9 {
    gap: var(--space-9);
  }

  .xs\:rt-r-cg {
    column-gap: var(--column-gap-xs);
  }

  .xs\:rt-r-cg-0 {
    column-gap: 0;
  }

  .xs\:rt-r-cg-1 {
    column-gap: var(--space-1);
  }

  .xs\:rt-r-cg-2 {
    column-gap: var(--space-2);
  }

  .xs\:rt-r-cg-3 {
    column-gap: var(--space-3);
  }

  .xs\:rt-r-cg-4 {
    column-gap: var(--space-4);
  }

  .xs\:rt-r-cg-5 {
    column-gap: var(--space-5);
  }

  .xs\:rt-r-cg-6 {
    column-gap: var(--space-6);
  }

  .xs\:rt-r-cg-7 {
    column-gap: var(--space-7);
  }

  .xs\:rt-r-cg-8 {
    column-gap: var(--space-8);
  }

  .xs\:rt-r-cg-9 {
    column-gap: var(--space-9);
  }

  .xs\:rt-r-rg {
    row-gap: var(--row-gap-xs);
  }

  .xs\:rt-r-rg-0 {
    row-gap: 0;
  }

  .xs\:rt-r-rg-1 {
    row-gap: var(--space-1);
  }

  .xs\:rt-r-rg-2 {
    row-gap: var(--space-2);
  }

  .xs\:rt-r-rg-3 {
    row-gap: var(--space-3);
  }

  .xs\:rt-r-rg-4 {
    row-gap: var(--space-4);
  }

  .xs\:rt-r-rg-5 {
    row-gap: var(--space-5);
  }

  .xs\:rt-r-rg-6 {
    row-gap: var(--space-6);
  }

  .xs\:rt-r-rg-7 {
    row-gap: var(--space-7);
  }

  .xs\:rt-r-rg-8 {
    row-gap: var(--space-8);
  }

  .xs\:rt-r-rg-9 {
    row-gap: var(--space-9);
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-gap {
    gap: var(--gap-sm);
  }

  .sm\:rt-r-gap-0 {
    gap: 0;
  }

  .sm\:rt-r-gap-1 {
    gap: var(--space-1);
  }

  .sm\:rt-r-gap-2 {
    gap: var(--space-2);
  }

  .sm\:rt-r-gap-3 {
    gap: var(--space-3);
  }

  .sm\:rt-r-gap-4 {
    gap: var(--space-4);
  }

  .sm\:rt-r-gap-5 {
    gap: var(--space-5);
  }

  .sm\:rt-r-gap-6 {
    gap: var(--space-6);
  }

  .sm\:rt-r-gap-7 {
    gap: var(--space-7);
  }

  .sm\:rt-r-gap-8 {
    gap: var(--space-8);
  }

  .sm\:rt-r-gap-9 {
    gap: var(--space-9);
  }

  .sm\:rt-r-cg {
    column-gap: var(--column-gap-sm);
  }

  .sm\:rt-r-cg-0 {
    column-gap: 0;
  }

  .sm\:rt-r-cg-1 {
    column-gap: var(--space-1);
  }

  .sm\:rt-r-cg-2 {
    column-gap: var(--space-2);
  }

  .sm\:rt-r-cg-3 {
    column-gap: var(--space-3);
  }

  .sm\:rt-r-cg-4 {
    column-gap: var(--space-4);
  }

  .sm\:rt-r-cg-5 {
    column-gap: var(--space-5);
  }

  .sm\:rt-r-cg-6 {
    column-gap: var(--space-6);
  }

  .sm\:rt-r-cg-7 {
    column-gap: var(--space-7);
  }

  .sm\:rt-r-cg-8 {
    column-gap: var(--space-8);
  }

  .sm\:rt-r-cg-9 {
    column-gap: var(--space-9);
  }

  .sm\:rt-r-rg {
    row-gap: var(--row-gap-sm);
  }

  .sm\:rt-r-rg-0 {
    row-gap: 0;
  }

  .sm\:rt-r-rg-1 {
    row-gap: var(--space-1);
  }

  .sm\:rt-r-rg-2 {
    row-gap: var(--space-2);
  }

  .sm\:rt-r-rg-3 {
    row-gap: var(--space-3);
  }

  .sm\:rt-r-rg-4 {
    row-gap: var(--space-4);
  }

  .sm\:rt-r-rg-5 {
    row-gap: var(--space-5);
  }

  .sm\:rt-r-rg-6 {
    row-gap: var(--space-6);
  }

  .sm\:rt-r-rg-7 {
    row-gap: var(--space-7);
  }

  .sm\:rt-r-rg-8 {
    row-gap: var(--space-8);
  }

  .sm\:rt-r-rg-9 {
    row-gap: var(--space-9);
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-gap {
    gap: var(--gap-md);
  }

  .md\:rt-r-gap-0 {
    gap: 0;
  }

  .md\:rt-r-gap-1 {
    gap: var(--space-1);
  }

  .md\:rt-r-gap-2 {
    gap: var(--space-2);
  }

  .md\:rt-r-gap-3 {
    gap: var(--space-3);
  }

  .md\:rt-r-gap-4 {
    gap: var(--space-4);
  }

  .md\:rt-r-gap-5 {
    gap: var(--space-5);
  }

  .md\:rt-r-gap-6 {
    gap: var(--space-6);
  }

  .md\:rt-r-gap-7 {
    gap: var(--space-7);
  }

  .md\:rt-r-gap-8 {
    gap: var(--space-8);
  }

  .md\:rt-r-gap-9 {
    gap: var(--space-9);
  }

  .md\:rt-r-cg {
    column-gap: var(--column-gap-md);
  }

  .md\:rt-r-cg-0 {
    column-gap: 0;
  }

  .md\:rt-r-cg-1 {
    column-gap: var(--space-1);
  }

  .md\:rt-r-cg-2 {
    column-gap: var(--space-2);
  }

  .md\:rt-r-cg-3 {
    column-gap: var(--space-3);
  }

  .md\:rt-r-cg-4 {
    column-gap: var(--space-4);
  }

  .md\:rt-r-cg-5 {
    column-gap: var(--space-5);
  }

  .md\:rt-r-cg-6 {
    column-gap: var(--space-6);
  }

  .md\:rt-r-cg-7 {
    column-gap: var(--space-7);
  }

  .md\:rt-r-cg-8 {
    column-gap: var(--space-8);
  }

  .md\:rt-r-cg-9 {
    column-gap: var(--space-9);
  }

  .md\:rt-r-rg {
    row-gap: var(--row-gap-md);
  }

  .md\:rt-r-rg-0 {
    row-gap: 0;
  }

  .md\:rt-r-rg-1 {
    row-gap: var(--space-1);
  }

  .md\:rt-r-rg-2 {
    row-gap: var(--space-2);
  }

  .md\:rt-r-rg-3 {
    row-gap: var(--space-3);
  }

  .md\:rt-r-rg-4 {
    row-gap: var(--space-4);
  }

  .md\:rt-r-rg-5 {
    row-gap: var(--space-5);
  }

  .md\:rt-r-rg-6 {
    row-gap: var(--space-6);
  }

  .md\:rt-r-rg-7 {
    row-gap: var(--space-7);
  }

  .md\:rt-r-rg-8 {
    row-gap: var(--space-8);
  }

  .md\:rt-r-rg-9 {
    row-gap: var(--space-9);
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-gap {
    gap: var(--gap-lg);
  }

  .lg\:rt-r-gap-0 {
    gap: 0;
  }

  .lg\:rt-r-gap-1 {
    gap: var(--space-1);
  }

  .lg\:rt-r-gap-2 {
    gap: var(--space-2);
  }

  .lg\:rt-r-gap-3 {
    gap: var(--space-3);
  }

  .lg\:rt-r-gap-4 {
    gap: var(--space-4);
  }

  .lg\:rt-r-gap-5 {
    gap: var(--space-5);
  }

  .lg\:rt-r-gap-6 {
    gap: var(--space-6);
  }

  .lg\:rt-r-gap-7 {
    gap: var(--space-7);
  }

  .lg\:rt-r-gap-8 {
    gap: var(--space-8);
  }

  .lg\:rt-r-gap-9 {
    gap: var(--space-9);
  }

  .lg\:rt-r-cg {
    column-gap: var(--column-gap-lg);
  }

  .lg\:rt-r-cg-0 {
    column-gap: 0;
  }

  .lg\:rt-r-cg-1 {
    column-gap: var(--space-1);
  }

  .lg\:rt-r-cg-2 {
    column-gap: var(--space-2);
  }

  .lg\:rt-r-cg-3 {
    column-gap: var(--space-3);
  }

  .lg\:rt-r-cg-4 {
    column-gap: var(--space-4);
  }

  .lg\:rt-r-cg-5 {
    column-gap: var(--space-5);
  }

  .lg\:rt-r-cg-6 {
    column-gap: var(--space-6);
  }

  .lg\:rt-r-cg-7 {
    column-gap: var(--space-7);
  }

  .lg\:rt-r-cg-8 {
    column-gap: var(--space-8);
  }

  .lg\:rt-r-cg-9 {
    column-gap: var(--space-9);
  }

  .lg\:rt-r-rg {
    row-gap: var(--row-gap-lg);
  }

  .lg\:rt-r-rg-0 {
    row-gap: 0;
  }

  .lg\:rt-r-rg-1 {
    row-gap: var(--space-1);
  }

  .lg\:rt-r-rg-2 {
    row-gap: var(--space-2);
  }

  .lg\:rt-r-rg-3 {
    row-gap: var(--space-3);
  }

  .lg\:rt-r-rg-4 {
    row-gap: var(--space-4);
  }

  .lg\:rt-r-rg-5 {
    row-gap: var(--space-5);
  }

  .lg\:rt-r-rg-6 {
    row-gap: var(--space-6);
  }

  .lg\:rt-r-rg-7 {
    row-gap: var(--space-7);
  }

  .lg\:rt-r-rg-8 {
    row-gap: var(--space-8);
  }

  .lg\:rt-r-rg-9 {
    row-gap: var(--space-9);
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-gap {
    gap: var(--gap-xl);
  }

  .xl\:rt-r-gap-0 {
    gap: 0;
  }

  .xl\:rt-r-gap-1 {
    gap: var(--space-1);
  }

  .xl\:rt-r-gap-2 {
    gap: var(--space-2);
  }

  .xl\:rt-r-gap-3 {
    gap: var(--space-3);
  }

  .xl\:rt-r-gap-4 {
    gap: var(--space-4);
  }

  .xl\:rt-r-gap-5 {
    gap: var(--space-5);
  }

  .xl\:rt-r-gap-6 {
    gap: var(--space-6);
  }

  .xl\:rt-r-gap-7 {
    gap: var(--space-7);
  }

  .xl\:rt-r-gap-8 {
    gap: var(--space-8);
  }

  .xl\:rt-r-gap-9 {
    gap: var(--space-9);
  }

  .xl\:rt-r-cg {
    column-gap: var(--column-gap-xl);
  }

  .xl\:rt-r-cg-0 {
    column-gap: 0;
  }

  .xl\:rt-r-cg-1 {
    column-gap: var(--space-1);
  }

  .xl\:rt-r-cg-2 {
    column-gap: var(--space-2);
  }

  .xl\:rt-r-cg-3 {
    column-gap: var(--space-3);
  }

  .xl\:rt-r-cg-4 {
    column-gap: var(--space-4);
  }

  .xl\:rt-r-cg-5 {
    column-gap: var(--space-5);
  }

  .xl\:rt-r-cg-6 {
    column-gap: var(--space-6);
  }

  .xl\:rt-r-cg-7 {
    column-gap: var(--space-7);
  }

  .xl\:rt-r-cg-8 {
    column-gap: var(--space-8);
  }

  .xl\:rt-r-cg-9 {
    column-gap: var(--space-9);
  }

  .xl\:rt-r-rg {
    row-gap: var(--row-gap-xl);
  }

  .xl\:rt-r-rg-0 {
    row-gap: 0;
  }

  .xl\:rt-r-rg-1 {
    row-gap: var(--space-1);
  }

  .xl\:rt-r-rg-2 {
    row-gap: var(--space-2);
  }

  .xl\:rt-r-rg-3 {
    row-gap: var(--space-3);
  }

  .xl\:rt-r-rg-4 {
    row-gap: var(--space-4);
  }

  .xl\:rt-r-rg-5 {
    row-gap: var(--space-5);
  }

  .xl\:rt-r-rg-6 {
    row-gap: var(--space-6);
  }

  .xl\:rt-r-rg-7 {
    row-gap: var(--space-7);
  }

  .xl\:rt-r-rg-8 {
    row-gap: var(--space-8);
  }

  .xl\:rt-r-rg-9 {
    row-gap: var(--space-9);
  }
}

.rt-r-ga {
  grid-area: var(--grid-area);
}

@media (min-width: 520px) {
  .xs\:rt-r-ga {
    grid-area: var(--grid-area-xs);
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-ga {
    grid-area: var(--grid-area-sm);
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-ga {
    grid-area: var(--grid-area-md);
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-ga {
    grid-area: var(--grid-area-lg);
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-ga {
    grid-area: var(--grid-area-xl);
  }
}

.rt-r-gaf-row {
  grid-auto-flow: row;
}

.rt-r-gaf-column {
  grid-auto-flow: column;
}

.rt-r-gaf-dense {
  grid-auto-flow: dense;
}

.rt-r-gaf-row-dense {
  grid-auto-flow: row dense;
}

.rt-r-gaf-column-dense {
  grid-auto-flow: column dense;
}

@media (min-width: 520px) {
  .xs\:rt-r-gaf-row {
    grid-auto-flow: row;
  }

  .xs\:rt-r-gaf-column {
    grid-auto-flow: column;
  }

  .xs\:rt-r-gaf-dense {
    grid-auto-flow: dense;
  }

  .xs\:rt-r-gaf-row-dense {
    grid-auto-flow: row dense;
  }

  .xs\:rt-r-gaf-column-dense {
    grid-auto-flow: column dense;
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-gaf-row {
    grid-auto-flow: row;
  }

  .sm\:rt-r-gaf-column {
    grid-auto-flow: column;
  }

  .sm\:rt-r-gaf-dense {
    grid-auto-flow: dense;
  }

  .sm\:rt-r-gaf-row-dense {
    grid-auto-flow: row dense;
  }

  .sm\:rt-r-gaf-column-dense {
    grid-auto-flow: column dense;
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-gaf-row {
    grid-auto-flow: row;
  }

  .md\:rt-r-gaf-column {
    grid-auto-flow: column;
  }

  .md\:rt-r-gaf-dense {
    grid-auto-flow: dense;
  }

  .md\:rt-r-gaf-row-dense {
    grid-auto-flow: row dense;
  }

  .md\:rt-r-gaf-column-dense {
    grid-auto-flow: column dense;
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-gaf-row {
    grid-auto-flow: row;
  }

  .lg\:rt-r-gaf-column {
    grid-auto-flow: column;
  }

  .lg\:rt-r-gaf-dense {
    grid-auto-flow: dense;
  }

  .lg\:rt-r-gaf-row-dense {
    grid-auto-flow: row dense;
  }

  .lg\:rt-r-gaf-column-dense {
    grid-auto-flow: column dense;
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-gaf-row {
    grid-auto-flow: row;
  }

  .xl\:rt-r-gaf-column {
    grid-auto-flow: column;
  }

  .xl\:rt-r-gaf-dense {
    grid-auto-flow: dense;
  }

  .xl\:rt-r-gaf-row-dense {
    grid-auto-flow: row dense;
  }

  .xl\:rt-r-gaf-column-dense {
    grid-auto-flow: column dense;
  }
}

.rt-r-gc {
  grid-column: var(--grid-column);
}

.rt-r-gc-1 {
  grid-column: 1;
}

.rt-r-gc-2 {
  grid-column: 2;
}

.rt-r-gc-3 {
  grid-column: 3;
}

.rt-r-gc-4 {
  grid-column: 4;
}

.rt-r-gc-5 {
  grid-column: 5;
}

.rt-r-gc-6 {
  grid-column: 6;
}

.rt-r-gc-7 {
  grid-column: 7;
}

.rt-r-gc-8 {
  grid-column: 8;
}

.rt-r-gc-9 {
  grid-column: 9;
}

@media (min-width: 520px) {
  .xs\:rt-r-gc {
    grid-column: var(--grid-column-xs);
  }

  .xs\:rt-r-gc-1 {
    grid-column: 1;
  }

  .xs\:rt-r-gc-2 {
    grid-column: 2;
  }

  .xs\:rt-r-gc-3 {
    grid-column: 3;
  }

  .xs\:rt-r-gc-4 {
    grid-column: 4;
  }

  .xs\:rt-r-gc-5 {
    grid-column: 5;
  }

  .xs\:rt-r-gc-6 {
    grid-column: 6;
  }

  .xs\:rt-r-gc-7 {
    grid-column: 7;
  }

  .xs\:rt-r-gc-8 {
    grid-column: 8;
  }

  .xs\:rt-r-gc-9 {
    grid-column: 9;
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-gc {
    grid-column: var(--grid-column-sm);
  }

  .sm\:rt-r-gc-1 {
    grid-column: 1;
  }

  .sm\:rt-r-gc-2 {
    grid-column: 2;
  }

  .sm\:rt-r-gc-3 {
    grid-column: 3;
  }

  .sm\:rt-r-gc-4 {
    grid-column: 4;
  }

  .sm\:rt-r-gc-5 {
    grid-column: 5;
  }

  .sm\:rt-r-gc-6 {
    grid-column: 6;
  }

  .sm\:rt-r-gc-7 {
    grid-column: 7;
  }

  .sm\:rt-r-gc-8 {
    grid-column: 8;
  }

  .sm\:rt-r-gc-9 {
    grid-column: 9;
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-gc {
    grid-column: var(--grid-column-md);
  }

  .md\:rt-r-gc-1 {
    grid-column: 1;
  }

  .md\:rt-r-gc-2 {
    grid-column: 2;
  }

  .md\:rt-r-gc-3 {
    grid-column: 3;
  }

  .md\:rt-r-gc-4 {
    grid-column: 4;
  }

  .md\:rt-r-gc-5 {
    grid-column: 5;
  }

  .md\:rt-r-gc-6 {
    grid-column: 6;
  }

  .md\:rt-r-gc-7 {
    grid-column: 7;
  }

  .md\:rt-r-gc-8 {
    grid-column: 8;
  }

  .md\:rt-r-gc-9 {
    grid-column: 9;
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-gc {
    grid-column: var(--grid-column-lg);
  }

  .lg\:rt-r-gc-1 {
    grid-column: 1;
  }

  .lg\:rt-r-gc-2 {
    grid-column: 2;
  }

  .lg\:rt-r-gc-3 {
    grid-column: 3;
  }

  .lg\:rt-r-gc-4 {
    grid-column: 4;
  }

  .lg\:rt-r-gc-5 {
    grid-column: 5;
  }

  .lg\:rt-r-gc-6 {
    grid-column: 6;
  }

  .lg\:rt-r-gc-7 {
    grid-column: 7;
  }

  .lg\:rt-r-gc-8 {
    grid-column: 8;
  }

  .lg\:rt-r-gc-9 {
    grid-column: 9;
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-gc {
    grid-column: var(--grid-column-xl);
  }

  .xl\:rt-r-gc-1 {
    grid-column: 1;
  }

  .xl\:rt-r-gc-2 {
    grid-column: 2;
  }

  .xl\:rt-r-gc-3 {
    grid-column: 3;
  }

  .xl\:rt-r-gc-4 {
    grid-column: 4;
  }

  .xl\:rt-r-gc-5 {
    grid-column: 5;
  }

  .xl\:rt-r-gc-6 {
    grid-column: 6;
  }

  .xl\:rt-r-gc-7 {
    grid-column: 7;
  }

  .xl\:rt-r-gc-8 {
    grid-column: 8;
  }

  .xl\:rt-r-gc-9 {
    grid-column: 9;
  }
}

.rt-r-gcs {
  grid-column-start: var(--grid-column-start);
}

.rt-r-gcs-1 {
  grid-column-start: 1;
}

.rt-r-gcs-2 {
  grid-column-start: 2;
}

.rt-r-gcs-3 {
  grid-column-start: 3;
}

.rt-r-gcs-4 {
  grid-column-start: 4;
}

.rt-r-gcs-5 {
  grid-column-start: 5;
}

.rt-r-gcs-6 {
  grid-column-start: 6;
}

.rt-r-gcs-7 {
  grid-column-start: 7;
}

.rt-r-gcs-8 {
  grid-column-start: 8;
}

.rt-r-gcs-9 {
  grid-column-start: 9;
}

@media (min-width: 520px) {
  .xs\:rt-r-gcs {
    grid-column-start: var(--grid-column-start-xs);
  }

  .xs\:rt-r-gcs-1 {
    grid-column-start: 1;
  }

  .xs\:rt-r-gcs-2 {
    grid-column-start: 2;
  }

  .xs\:rt-r-gcs-3 {
    grid-column-start: 3;
  }

  .xs\:rt-r-gcs-4 {
    grid-column-start: 4;
  }

  .xs\:rt-r-gcs-5 {
    grid-column-start: 5;
  }

  .xs\:rt-r-gcs-6 {
    grid-column-start: 6;
  }

  .xs\:rt-r-gcs-7 {
    grid-column-start: 7;
  }

  .xs\:rt-r-gcs-8 {
    grid-column-start: 8;
  }

  .xs\:rt-r-gcs-9 {
    grid-column-start: 9;
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-gcs {
    grid-column-start: var(--grid-column-start-sm);
  }

  .sm\:rt-r-gcs-1 {
    grid-column-start: 1;
  }

  .sm\:rt-r-gcs-2 {
    grid-column-start: 2;
  }

  .sm\:rt-r-gcs-3 {
    grid-column-start: 3;
  }

  .sm\:rt-r-gcs-4 {
    grid-column-start: 4;
  }

  .sm\:rt-r-gcs-5 {
    grid-column-start: 5;
  }

  .sm\:rt-r-gcs-6 {
    grid-column-start: 6;
  }

  .sm\:rt-r-gcs-7 {
    grid-column-start: 7;
  }

  .sm\:rt-r-gcs-8 {
    grid-column-start: 8;
  }

  .sm\:rt-r-gcs-9 {
    grid-column-start: 9;
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-gcs {
    grid-column-start: var(--grid-column-start-md);
  }

  .md\:rt-r-gcs-1 {
    grid-column-start: 1;
  }

  .md\:rt-r-gcs-2 {
    grid-column-start: 2;
  }

  .md\:rt-r-gcs-3 {
    grid-column-start: 3;
  }

  .md\:rt-r-gcs-4 {
    grid-column-start: 4;
  }

  .md\:rt-r-gcs-5 {
    grid-column-start: 5;
  }

  .md\:rt-r-gcs-6 {
    grid-column-start: 6;
  }

  .md\:rt-r-gcs-7 {
    grid-column-start: 7;
  }

  .md\:rt-r-gcs-8 {
    grid-column-start: 8;
  }

  .md\:rt-r-gcs-9 {
    grid-column-start: 9;
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-gcs {
    grid-column-start: var(--grid-column-start-lg);
  }

  .lg\:rt-r-gcs-1 {
    grid-column-start: 1;
  }

  .lg\:rt-r-gcs-2 {
    grid-column-start: 2;
  }

  .lg\:rt-r-gcs-3 {
    grid-column-start: 3;
  }

  .lg\:rt-r-gcs-4 {
    grid-column-start: 4;
  }

  .lg\:rt-r-gcs-5 {
    grid-column-start: 5;
  }

  .lg\:rt-r-gcs-6 {
    grid-column-start: 6;
  }

  .lg\:rt-r-gcs-7 {
    grid-column-start: 7;
  }

  .lg\:rt-r-gcs-8 {
    grid-column-start: 8;
  }

  .lg\:rt-r-gcs-9 {
    grid-column-start: 9;
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-gcs {
    grid-column-start: var(--grid-column-start-xl);
  }

  .xl\:rt-r-gcs-1 {
    grid-column-start: 1;
  }

  .xl\:rt-r-gcs-2 {
    grid-column-start: 2;
  }

  .xl\:rt-r-gcs-3 {
    grid-column-start: 3;
  }

  .xl\:rt-r-gcs-4 {
    grid-column-start: 4;
  }

  .xl\:rt-r-gcs-5 {
    grid-column-start: 5;
  }

  .xl\:rt-r-gcs-6 {
    grid-column-start: 6;
  }

  .xl\:rt-r-gcs-7 {
    grid-column-start: 7;
  }

  .xl\:rt-r-gcs-8 {
    grid-column-start: 8;
  }

  .xl\:rt-r-gcs-9 {
    grid-column-start: 9;
  }
}

.rt-r-gce {
  grid-column-end: var(--grid-column-end);
}

.rt-r-gce-1 {
  grid-column-end: 1;
}

.rt-r-gce-2 {
  grid-column-end: 2;
}

.rt-r-gce-3 {
  grid-column-end: 3;
}

.rt-r-gce-4 {
  grid-column-end: 4;
}

.rt-r-gce-5 {
  grid-column-end: 5;
}

.rt-r-gce-6 {
  grid-column-end: 6;
}

.rt-r-gce-7 {
  grid-column-end: 7;
}

.rt-r-gce-8 {
  grid-column-end: 8;
}

.rt-r-gce-9 {
  grid-column-end: 9;
}

@media (min-width: 520px) {
  .xs\:rt-r-gce {
    grid-column-end: var(--grid-column-end-xs);
  }

  .xs\:rt-r-gce-1 {
    grid-column-end: 1;
  }

  .xs\:rt-r-gce-2 {
    grid-column-end: 2;
  }

  .xs\:rt-r-gce-3 {
    grid-column-end: 3;
  }

  .xs\:rt-r-gce-4 {
    grid-column-end: 4;
  }

  .xs\:rt-r-gce-5 {
    grid-column-end: 5;
  }

  .xs\:rt-r-gce-6 {
    grid-column-end: 6;
  }

  .xs\:rt-r-gce-7 {
    grid-column-end: 7;
  }

  .xs\:rt-r-gce-8 {
    grid-column-end: 8;
  }

  .xs\:rt-r-gce-9 {
    grid-column-end: 9;
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-gce {
    grid-column-end: var(--grid-column-end-sm);
  }

  .sm\:rt-r-gce-1 {
    grid-column-end: 1;
  }

  .sm\:rt-r-gce-2 {
    grid-column-end: 2;
  }

  .sm\:rt-r-gce-3 {
    grid-column-end: 3;
  }

  .sm\:rt-r-gce-4 {
    grid-column-end: 4;
  }

  .sm\:rt-r-gce-5 {
    grid-column-end: 5;
  }

  .sm\:rt-r-gce-6 {
    grid-column-end: 6;
  }

  .sm\:rt-r-gce-7 {
    grid-column-end: 7;
  }

  .sm\:rt-r-gce-8 {
    grid-column-end: 8;
  }

  .sm\:rt-r-gce-9 {
    grid-column-end: 9;
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-gce {
    grid-column-end: var(--grid-column-end-md);
  }

  .md\:rt-r-gce-1 {
    grid-column-end: 1;
  }

  .md\:rt-r-gce-2 {
    grid-column-end: 2;
  }

  .md\:rt-r-gce-3 {
    grid-column-end: 3;
  }

  .md\:rt-r-gce-4 {
    grid-column-end: 4;
  }

  .md\:rt-r-gce-5 {
    grid-column-end: 5;
  }

  .md\:rt-r-gce-6 {
    grid-column-end: 6;
  }

  .md\:rt-r-gce-7 {
    grid-column-end: 7;
  }

  .md\:rt-r-gce-8 {
    grid-column-end: 8;
  }

  .md\:rt-r-gce-9 {
    grid-column-end: 9;
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-gce {
    grid-column-end: var(--grid-column-end-lg);
  }

  .lg\:rt-r-gce-1 {
    grid-column-end: 1;
  }

  .lg\:rt-r-gce-2 {
    grid-column-end: 2;
  }

  .lg\:rt-r-gce-3 {
    grid-column-end: 3;
  }

  .lg\:rt-r-gce-4 {
    grid-column-end: 4;
  }

  .lg\:rt-r-gce-5 {
    grid-column-end: 5;
  }

  .lg\:rt-r-gce-6 {
    grid-column-end: 6;
  }

  .lg\:rt-r-gce-7 {
    grid-column-end: 7;
  }

  .lg\:rt-r-gce-8 {
    grid-column-end: 8;
  }

  .lg\:rt-r-gce-9 {
    grid-column-end: 9;
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-gce {
    grid-column-end: var(--grid-column-end-xl);
  }

  .xl\:rt-r-gce-1 {
    grid-column-end: 1;
  }

  .xl\:rt-r-gce-2 {
    grid-column-end: 2;
  }

  .xl\:rt-r-gce-3 {
    grid-column-end: 3;
  }

  .xl\:rt-r-gce-4 {
    grid-column-end: 4;
  }

  .xl\:rt-r-gce-5 {
    grid-column-end: 5;
  }

  .xl\:rt-r-gce-6 {
    grid-column-end: 6;
  }

  .xl\:rt-r-gce-7 {
    grid-column-end: 7;
  }

  .xl\:rt-r-gce-8 {
    grid-column-end: 8;
  }

  .xl\:rt-r-gce-9 {
    grid-column-end: 9;
  }
}

.rt-r-gr {
  grid-row: var(--grid-row);
}

.rt-r-gr-1 {
  grid-row: 1;
}

.rt-r-gr-2 {
  grid-row: 2;
}

.rt-r-gr-3 {
  grid-row: 3;
}

.rt-r-gr-4 {
  grid-row: 4;
}

.rt-r-gr-5 {
  grid-row: 5;
}

.rt-r-gr-6 {
  grid-row: 6;
}

.rt-r-gr-7 {
  grid-row: 7;
}

.rt-r-gr-8 {
  grid-row: 8;
}

.rt-r-gr-9 {
  grid-row: 9;
}

@media (min-width: 520px) {
  .xs\:rt-r-gr {
    grid-row: var(--grid-row-xs);
  }

  .xs\:rt-r-gr-1 {
    grid-row: 1;
  }

  .xs\:rt-r-gr-2 {
    grid-row: 2;
  }

  .xs\:rt-r-gr-3 {
    grid-row: 3;
  }

  .xs\:rt-r-gr-4 {
    grid-row: 4;
  }

  .xs\:rt-r-gr-5 {
    grid-row: 5;
  }

  .xs\:rt-r-gr-6 {
    grid-row: 6;
  }

  .xs\:rt-r-gr-7 {
    grid-row: 7;
  }

  .xs\:rt-r-gr-8 {
    grid-row: 8;
  }

  .xs\:rt-r-gr-9 {
    grid-row: 9;
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-gr {
    grid-row: var(--grid-row-sm);
  }

  .sm\:rt-r-gr-1 {
    grid-row: 1;
  }

  .sm\:rt-r-gr-2 {
    grid-row: 2;
  }

  .sm\:rt-r-gr-3 {
    grid-row: 3;
  }

  .sm\:rt-r-gr-4 {
    grid-row: 4;
  }

  .sm\:rt-r-gr-5 {
    grid-row: 5;
  }

  .sm\:rt-r-gr-6 {
    grid-row: 6;
  }

  .sm\:rt-r-gr-7 {
    grid-row: 7;
  }

  .sm\:rt-r-gr-8 {
    grid-row: 8;
  }

  .sm\:rt-r-gr-9 {
    grid-row: 9;
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-gr {
    grid-row: var(--grid-row-md);
  }

  .md\:rt-r-gr-1 {
    grid-row: 1;
  }

  .md\:rt-r-gr-2 {
    grid-row: 2;
  }

  .md\:rt-r-gr-3 {
    grid-row: 3;
  }

  .md\:rt-r-gr-4 {
    grid-row: 4;
  }

  .md\:rt-r-gr-5 {
    grid-row: 5;
  }

  .md\:rt-r-gr-6 {
    grid-row: 6;
  }

  .md\:rt-r-gr-7 {
    grid-row: 7;
  }

  .md\:rt-r-gr-8 {
    grid-row: 8;
  }

  .md\:rt-r-gr-9 {
    grid-row: 9;
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-gr {
    grid-row: var(--grid-row-lg);
  }

  .lg\:rt-r-gr-1 {
    grid-row: 1;
  }

  .lg\:rt-r-gr-2 {
    grid-row: 2;
  }

  .lg\:rt-r-gr-3 {
    grid-row: 3;
  }

  .lg\:rt-r-gr-4 {
    grid-row: 4;
  }

  .lg\:rt-r-gr-5 {
    grid-row: 5;
  }

  .lg\:rt-r-gr-6 {
    grid-row: 6;
  }

  .lg\:rt-r-gr-7 {
    grid-row: 7;
  }

  .lg\:rt-r-gr-8 {
    grid-row: 8;
  }

  .lg\:rt-r-gr-9 {
    grid-row: 9;
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-gr {
    grid-row: var(--grid-row-xl);
  }

  .xl\:rt-r-gr-1 {
    grid-row: 1;
  }

  .xl\:rt-r-gr-2 {
    grid-row: 2;
  }

  .xl\:rt-r-gr-3 {
    grid-row: 3;
  }

  .xl\:rt-r-gr-4 {
    grid-row: 4;
  }

  .xl\:rt-r-gr-5 {
    grid-row: 5;
  }

  .xl\:rt-r-gr-6 {
    grid-row: 6;
  }

  .xl\:rt-r-gr-7 {
    grid-row: 7;
  }

  .xl\:rt-r-gr-8 {
    grid-row: 8;
  }

  .xl\:rt-r-gr-9 {
    grid-row: 9;
  }
}

.rt-r-grs {
  grid-row-start: var(--grid-row-start);
}

.rt-r-grs-1 {
  grid-row-start: 1;
}

.rt-r-grs-2 {
  grid-row-start: 2;
}

.rt-r-grs-3 {
  grid-row-start: 3;
}

.rt-r-grs-4 {
  grid-row-start: 4;
}

.rt-r-grs-5 {
  grid-row-start: 5;
}

.rt-r-grs-6 {
  grid-row-start: 6;
}

.rt-r-grs-7 {
  grid-row-start: 7;
}

.rt-r-grs-8 {
  grid-row-start: 8;
}

.rt-r-grs-9 {
  grid-row-start: 9;
}

@media (min-width: 520px) {
  .xs\:rt-r-grs {
    grid-row-start: var(--grid-row-start-xs);
  }

  .xs\:rt-r-grs-1 {
    grid-row-start: 1;
  }

  .xs\:rt-r-grs-2 {
    grid-row-start: 2;
  }

  .xs\:rt-r-grs-3 {
    grid-row-start: 3;
  }

  .xs\:rt-r-grs-4 {
    grid-row-start: 4;
  }

  .xs\:rt-r-grs-5 {
    grid-row-start: 5;
  }

  .xs\:rt-r-grs-6 {
    grid-row-start: 6;
  }

  .xs\:rt-r-grs-7 {
    grid-row-start: 7;
  }

  .xs\:rt-r-grs-8 {
    grid-row-start: 8;
  }

  .xs\:rt-r-grs-9 {
    grid-row-start: 9;
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-grs {
    grid-row-start: var(--grid-row-start-sm);
  }

  .sm\:rt-r-grs-1 {
    grid-row-start: 1;
  }

  .sm\:rt-r-grs-2 {
    grid-row-start: 2;
  }

  .sm\:rt-r-grs-3 {
    grid-row-start: 3;
  }

  .sm\:rt-r-grs-4 {
    grid-row-start: 4;
  }

  .sm\:rt-r-grs-5 {
    grid-row-start: 5;
  }

  .sm\:rt-r-grs-6 {
    grid-row-start: 6;
  }

  .sm\:rt-r-grs-7 {
    grid-row-start: 7;
  }

  .sm\:rt-r-grs-8 {
    grid-row-start: 8;
  }

  .sm\:rt-r-grs-9 {
    grid-row-start: 9;
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-grs {
    grid-row-start: var(--grid-row-start-md);
  }

  .md\:rt-r-grs-1 {
    grid-row-start: 1;
  }

  .md\:rt-r-grs-2 {
    grid-row-start: 2;
  }

  .md\:rt-r-grs-3 {
    grid-row-start: 3;
  }

  .md\:rt-r-grs-4 {
    grid-row-start: 4;
  }

  .md\:rt-r-grs-5 {
    grid-row-start: 5;
  }

  .md\:rt-r-grs-6 {
    grid-row-start: 6;
  }

  .md\:rt-r-grs-7 {
    grid-row-start: 7;
  }

  .md\:rt-r-grs-8 {
    grid-row-start: 8;
  }

  .md\:rt-r-grs-9 {
    grid-row-start: 9;
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-grs {
    grid-row-start: var(--grid-row-start-lg);
  }

  .lg\:rt-r-grs-1 {
    grid-row-start: 1;
  }

  .lg\:rt-r-grs-2 {
    grid-row-start: 2;
  }

  .lg\:rt-r-grs-3 {
    grid-row-start: 3;
  }

  .lg\:rt-r-grs-4 {
    grid-row-start: 4;
  }

  .lg\:rt-r-grs-5 {
    grid-row-start: 5;
  }

  .lg\:rt-r-grs-6 {
    grid-row-start: 6;
  }

  .lg\:rt-r-grs-7 {
    grid-row-start: 7;
  }

  .lg\:rt-r-grs-8 {
    grid-row-start: 8;
  }

  .lg\:rt-r-grs-9 {
    grid-row-start: 9;
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-grs {
    grid-row-start: var(--grid-row-start-xl);
  }

  .xl\:rt-r-grs-1 {
    grid-row-start: 1;
  }

  .xl\:rt-r-grs-2 {
    grid-row-start: 2;
  }

  .xl\:rt-r-grs-3 {
    grid-row-start: 3;
  }

  .xl\:rt-r-grs-4 {
    grid-row-start: 4;
  }

  .xl\:rt-r-grs-5 {
    grid-row-start: 5;
  }

  .xl\:rt-r-grs-6 {
    grid-row-start: 6;
  }

  .xl\:rt-r-grs-7 {
    grid-row-start: 7;
  }

  .xl\:rt-r-grs-8 {
    grid-row-start: 8;
  }

  .xl\:rt-r-grs-9 {
    grid-row-start: 9;
  }
}

.rt-r-gre {
  grid-row-end: var(--grid-row-end);
}

.rt-r-gre-1 {
  grid-row-end: 1;
}

.rt-r-gre-2 {
  grid-row-end: 2;
}

.rt-r-gre-3 {
  grid-row-end: 3;
}

.rt-r-gre-4 {
  grid-row-end: 4;
}

.rt-r-gre-5 {
  grid-row-end: 5;
}

.rt-r-gre-6 {
  grid-row-end: 6;
}

.rt-r-gre-7 {
  grid-row-end: 7;
}

.rt-r-gre-8 {
  grid-row-end: 8;
}

.rt-r-gre-9 {
  grid-row-end: 9;
}

@media (min-width: 520px) {
  .xs\:rt-r-gre {
    grid-row-end: var(--grid-row-end-xs);
  }

  .xs\:rt-r-gre-1 {
    grid-row-end: 1;
  }

  .xs\:rt-r-gre-2 {
    grid-row-end: 2;
  }

  .xs\:rt-r-gre-3 {
    grid-row-end: 3;
  }

  .xs\:rt-r-gre-4 {
    grid-row-end: 4;
  }

  .xs\:rt-r-gre-5 {
    grid-row-end: 5;
  }

  .xs\:rt-r-gre-6 {
    grid-row-end: 6;
  }

  .xs\:rt-r-gre-7 {
    grid-row-end: 7;
  }

  .xs\:rt-r-gre-8 {
    grid-row-end: 8;
  }

  .xs\:rt-r-gre-9 {
    grid-row-end: 9;
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-gre {
    grid-row-end: var(--grid-row-end-sm);
  }

  .sm\:rt-r-gre-1 {
    grid-row-end: 1;
  }

  .sm\:rt-r-gre-2 {
    grid-row-end: 2;
  }

  .sm\:rt-r-gre-3 {
    grid-row-end: 3;
  }

  .sm\:rt-r-gre-4 {
    grid-row-end: 4;
  }

  .sm\:rt-r-gre-5 {
    grid-row-end: 5;
  }

  .sm\:rt-r-gre-6 {
    grid-row-end: 6;
  }

  .sm\:rt-r-gre-7 {
    grid-row-end: 7;
  }

  .sm\:rt-r-gre-8 {
    grid-row-end: 8;
  }

  .sm\:rt-r-gre-9 {
    grid-row-end: 9;
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-gre {
    grid-row-end: var(--grid-row-end-md);
  }

  .md\:rt-r-gre-1 {
    grid-row-end: 1;
  }

  .md\:rt-r-gre-2 {
    grid-row-end: 2;
  }

  .md\:rt-r-gre-3 {
    grid-row-end: 3;
  }

  .md\:rt-r-gre-4 {
    grid-row-end: 4;
  }

  .md\:rt-r-gre-5 {
    grid-row-end: 5;
  }

  .md\:rt-r-gre-6 {
    grid-row-end: 6;
  }

  .md\:rt-r-gre-7 {
    grid-row-end: 7;
  }

  .md\:rt-r-gre-8 {
    grid-row-end: 8;
  }

  .md\:rt-r-gre-9 {
    grid-row-end: 9;
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-gre {
    grid-row-end: var(--grid-row-end-lg);
  }

  .lg\:rt-r-gre-1 {
    grid-row-end: 1;
  }

  .lg\:rt-r-gre-2 {
    grid-row-end: 2;
  }

  .lg\:rt-r-gre-3 {
    grid-row-end: 3;
  }

  .lg\:rt-r-gre-4 {
    grid-row-end: 4;
  }

  .lg\:rt-r-gre-5 {
    grid-row-end: 5;
  }

  .lg\:rt-r-gre-6 {
    grid-row-end: 6;
  }

  .lg\:rt-r-gre-7 {
    grid-row-end: 7;
  }

  .lg\:rt-r-gre-8 {
    grid-row-end: 8;
  }

  .lg\:rt-r-gre-9 {
    grid-row-end: 9;
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-gre {
    grid-row-end: var(--grid-row-end-xl);
  }

  .xl\:rt-r-gre-1 {
    grid-row-end: 1;
  }

  .xl\:rt-r-gre-2 {
    grid-row-end: 2;
  }

  .xl\:rt-r-gre-3 {
    grid-row-end: 3;
  }

  .xl\:rt-r-gre-4 {
    grid-row-end: 4;
  }

  .xl\:rt-r-gre-5 {
    grid-row-end: 5;
  }

  .xl\:rt-r-gre-6 {
    grid-row-end: 6;
  }

  .xl\:rt-r-gre-7 {
    grid-row-end: 7;
  }

  .xl\:rt-r-gre-8 {
    grid-row-end: 8;
  }

  .xl\:rt-r-gre-9 {
    grid-row-end: 9;
  }
}

.rt-r-gta {
  grid-template-areas: var(--grid-template-areas);
}

@media (min-width: 520px) {
  .xs\:rt-r-gta {
    grid-template-areas: var(--grid-template-areas-xs);
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-gta {
    grid-template-areas: var(--grid-template-areas-sm);
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-gta {
    grid-template-areas: var(--grid-template-areas-md);
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-gta {
    grid-template-areas: var(--grid-template-areas-lg);
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-gta {
    grid-template-areas: var(--grid-template-areas-xl);
  }
}

.rt-r-gtc {
  grid-template-columns: var(--grid-template-columns);
}

.rt-r-gtc-1 {
  grid-template-columns: minmax(0, 1fr);
}

.rt-r-gtc-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.rt-r-gtc-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.rt-r-gtc-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.rt-r-gtc-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.rt-r-gtc-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.rt-r-gtc-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.rt-r-gtc-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}

.rt-r-gtc-9 {
  grid-template-columns: repeat(9, minmax(0, 1fr));
}

@media (min-width: 520px) {
  .xs\:rt-r-gtc {
    grid-template-columns: var(--grid-template-columns-xs);
  }

  .xs\:rt-r-gtc-1 {
    grid-template-columns: minmax(0, 1fr);
  }

  .xs\:rt-r-gtc-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .xs\:rt-r-gtc-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .xs\:rt-r-gtc-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .xs\:rt-r-gtc-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .xs\:rt-r-gtc-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .xs\:rt-r-gtc-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .xs\:rt-r-gtc-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .xs\:rt-r-gtc-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-gtc {
    grid-template-columns: var(--grid-template-columns-sm);
  }

  .sm\:rt-r-gtc-1 {
    grid-template-columns: minmax(0, 1fr);
  }

  .sm\:rt-r-gtc-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .sm\:rt-r-gtc-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .sm\:rt-r-gtc-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .sm\:rt-r-gtc-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .sm\:rt-r-gtc-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .sm\:rt-r-gtc-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .sm\:rt-r-gtc-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .sm\:rt-r-gtc-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-gtc {
    grid-template-columns: var(--grid-template-columns-md);
  }

  .md\:rt-r-gtc-1 {
    grid-template-columns: minmax(0, 1fr);
  }

  .md\:rt-r-gtc-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:rt-r-gtc-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:rt-r-gtc-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .md\:rt-r-gtc-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .md\:rt-r-gtc-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .md\:rt-r-gtc-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .md\:rt-r-gtc-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .md\:rt-r-gtc-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-gtc {
    grid-template-columns: var(--grid-template-columns-lg);
  }

  .lg\:rt-r-gtc-1 {
    grid-template-columns: minmax(0, 1fr);
  }

  .lg\:rt-r-gtc-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg\:rt-r-gtc-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:rt-r-gtc-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .lg\:rt-r-gtc-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .lg\:rt-r-gtc-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .lg\:rt-r-gtc-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .lg\:rt-r-gtc-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .lg\:rt-r-gtc-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-gtc {
    grid-template-columns: var(--grid-template-columns-xl);
  }

  .xl\:rt-r-gtc-1 {
    grid-template-columns: minmax(0, 1fr);
  }

  .xl\:rt-r-gtc-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .xl\:rt-r-gtc-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .xl\:rt-r-gtc-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .xl\:rt-r-gtc-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .xl\:rt-r-gtc-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .xl\:rt-r-gtc-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .xl\:rt-r-gtc-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .xl\:rt-r-gtc-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }
}

.rt-r-gtr {
  grid-template-rows: var(--grid-template-rows);
}

.rt-r-gtr-1 {
  grid-template-rows: minmax(0, 1fr);
}

.rt-r-gtr-2 {
  grid-template-rows: repeat(2, minmax(0, 1fr));
}

.rt-r-gtr-3 {
  grid-template-rows: repeat(3, minmax(0, 1fr));
}

.rt-r-gtr-4 {
  grid-template-rows: repeat(4, minmax(0, 1fr));
}

.rt-r-gtr-5 {
  grid-template-rows: repeat(5, minmax(0, 1fr));
}

.rt-r-gtr-6 {
  grid-template-rows: repeat(6, minmax(0, 1fr));
}

.rt-r-gtr-7 {
  grid-template-rows: repeat(7, minmax(0, 1fr));
}

.rt-r-gtr-8 {
  grid-template-rows: repeat(8, minmax(0, 1fr));
}

.rt-r-gtr-9 {
  grid-template-rows: repeat(9, minmax(0, 1fr));
}

@media (min-width: 520px) {
  .xs\:rt-r-gtr {
    grid-template-rows: var(--grid-template-rows-xs);
  }

  .xs\:rt-r-gtr-1 {
    grid-template-rows: minmax(0, 1fr);
  }

  .xs\:rt-r-gtr-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  .xs\:rt-r-gtr-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }

  .xs\:rt-r-gtr-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }

  .xs\:rt-r-gtr-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }

  .xs\:rt-r-gtr-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }

  .xs\:rt-r-gtr-7 {
    grid-template-rows: repeat(7, minmax(0, 1fr));
  }

  .xs\:rt-r-gtr-8 {
    grid-template-rows: repeat(8, minmax(0, 1fr));
  }

  .xs\:rt-r-gtr-9 {
    grid-template-rows: repeat(9, minmax(0, 1fr));
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-gtr {
    grid-template-rows: var(--grid-template-rows-sm);
  }

  .sm\:rt-r-gtr-1 {
    grid-template-rows: minmax(0, 1fr);
  }

  .sm\:rt-r-gtr-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  .sm\:rt-r-gtr-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }

  .sm\:rt-r-gtr-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }

  .sm\:rt-r-gtr-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }

  .sm\:rt-r-gtr-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }

  .sm\:rt-r-gtr-7 {
    grid-template-rows: repeat(7, minmax(0, 1fr));
  }

  .sm\:rt-r-gtr-8 {
    grid-template-rows: repeat(8, minmax(0, 1fr));
  }

  .sm\:rt-r-gtr-9 {
    grid-template-rows: repeat(9, minmax(0, 1fr));
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-gtr {
    grid-template-rows: var(--grid-template-rows-md);
  }

  .md\:rt-r-gtr-1 {
    grid-template-rows: minmax(0, 1fr);
  }

  .md\:rt-r-gtr-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  .md\:rt-r-gtr-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }

  .md\:rt-r-gtr-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }

  .md\:rt-r-gtr-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }

  .md\:rt-r-gtr-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }

  .md\:rt-r-gtr-7 {
    grid-template-rows: repeat(7, minmax(0, 1fr));
  }

  .md\:rt-r-gtr-8 {
    grid-template-rows: repeat(8, minmax(0, 1fr));
  }

  .md\:rt-r-gtr-9 {
    grid-template-rows: repeat(9, minmax(0, 1fr));
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-gtr {
    grid-template-rows: var(--grid-template-rows-lg);
  }

  .lg\:rt-r-gtr-1 {
    grid-template-rows: minmax(0, 1fr);
  }

  .lg\:rt-r-gtr-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  .lg\:rt-r-gtr-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }

  .lg\:rt-r-gtr-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }

  .lg\:rt-r-gtr-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }

  .lg\:rt-r-gtr-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }

  .lg\:rt-r-gtr-7 {
    grid-template-rows: repeat(7, minmax(0, 1fr));
  }

  .lg\:rt-r-gtr-8 {
    grid-template-rows: repeat(8, minmax(0, 1fr));
  }

  .lg\:rt-r-gtr-9 {
    grid-template-rows: repeat(9, minmax(0, 1fr));
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-gtr {
    grid-template-rows: var(--grid-template-rows-xl);
  }

  .xl\:rt-r-gtr-1 {
    grid-template-rows: minmax(0, 1fr);
  }

  .xl\:rt-r-gtr-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  .xl\:rt-r-gtr-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }

  .xl\:rt-r-gtr-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }

  .xl\:rt-r-gtr-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }

  .xl\:rt-r-gtr-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }

  .xl\:rt-r-gtr-7 {
    grid-template-rows: repeat(7, minmax(0, 1fr));
  }

  .xl\:rt-r-gtr-8 {
    grid-template-rows: repeat(8, minmax(0, 1fr));
  }

  .xl\:rt-r-gtr-9 {
    grid-template-rows: repeat(9, minmax(0, 1fr));
  }
}

.rt-r-h {
  height: var(--height);
}

@media (min-width: 520px) {
  .xs\:rt-r-h {
    height: var(--height-xs);
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-h {
    height: var(--height-sm);
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-h {
    height: var(--height-md);
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-h {
    height: var(--height-lg);
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-h {
    height: var(--height-xl);
  }
}

.rt-r-min-h {
  min-height: var(--min-height);
}

@media (min-width: 520px) {
  .xs\:rt-r-min-h {
    min-height: var(--min-height-xs);
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-min-h {
    min-height: var(--min-height-sm);
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-min-h {
    min-height: var(--min-height-md);
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-min-h {
    min-height: var(--min-height-lg);
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-min-h {
    min-height: var(--min-height-xl);
  }
}

.rt-r-max-h {
  max-height: var(--max-height);
}

@media (min-width: 520px) {
  .xs\:rt-r-max-h {
    max-height: var(--max-height-xs);
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-max-h {
    max-height: var(--max-height-sm);
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-max-h {
    max-height: var(--max-height-md);
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-max-h {
    max-height: var(--max-height-lg);
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-max-h {
    max-height: var(--max-height-xl);
  }
}

.rt-r-inset {
  inset: var(--inset);
}

.rt-r-inset-0 {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.rt-r-inset-1 {
  inset: var(--space-1);
}

.rt-r-inset-2 {
  inset: var(--space-2);
}

.rt-r-inset-3 {
  inset: var(--space-3);
}

.rt-r-inset-4 {
  inset: var(--space-4);
}

.rt-r-inset-5 {
  inset: var(--space-5);
}

.rt-r-inset-6 {
  inset: var(--space-6);
}

.rt-r-inset-7 {
  inset: var(--space-7);
}

.rt-r-inset-8 {
  inset: var(--space-8);
}

.rt-r-inset-9 {
  inset: var(--space-9);
}

@media (min-width: 520px) {
  .xs\:rt-r-inset {
    inset: var(--inset-xs);
  }

  .xs\:rt-r-inset-0 {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .xs\:rt-r-inset-1 {
    inset: var(--space-1);
  }

  .xs\:rt-r-inset-2 {
    inset: var(--space-2);
  }

  .xs\:rt-r-inset-3 {
    inset: var(--space-3);
  }

  .xs\:rt-r-inset-4 {
    inset: var(--space-4);
  }

  .xs\:rt-r-inset-5 {
    inset: var(--space-5);
  }

  .xs\:rt-r-inset-6 {
    inset: var(--space-6);
  }

  .xs\:rt-r-inset-7 {
    inset: var(--space-7);
  }

  .xs\:rt-r-inset-8 {
    inset: var(--space-8);
  }

  .xs\:rt-r-inset-9 {
    inset: var(--space-9);
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-inset {
    inset: var(--inset-sm);
  }

  .sm\:rt-r-inset-0 {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .sm\:rt-r-inset-1 {
    inset: var(--space-1);
  }

  .sm\:rt-r-inset-2 {
    inset: var(--space-2);
  }

  .sm\:rt-r-inset-3 {
    inset: var(--space-3);
  }

  .sm\:rt-r-inset-4 {
    inset: var(--space-4);
  }

  .sm\:rt-r-inset-5 {
    inset: var(--space-5);
  }

  .sm\:rt-r-inset-6 {
    inset: var(--space-6);
  }

  .sm\:rt-r-inset-7 {
    inset: var(--space-7);
  }

  .sm\:rt-r-inset-8 {
    inset: var(--space-8);
  }

  .sm\:rt-r-inset-9 {
    inset: var(--space-9);
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-inset {
    inset: var(--inset-md);
  }

  .md\:rt-r-inset-0 {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .md\:rt-r-inset-1 {
    inset: var(--space-1);
  }

  .md\:rt-r-inset-2 {
    inset: var(--space-2);
  }

  .md\:rt-r-inset-3 {
    inset: var(--space-3);
  }

  .md\:rt-r-inset-4 {
    inset: var(--space-4);
  }

  .md\:rt-r-inset-5 {
    inset: var(--space-5);
  }

  .md\:rt-r-inset-6 {
    inset: var(--space-6);
  }

  .md\:rt-r-inset-7 {
    inset: var(--space-7);
  }

  .md\:rt-r-inset-8 {
    inset: var(--space-8);
  }

  .md\:rt-r-inset-9 {
    inset: var(--space-9);
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-inset {
    inset: var(--inset-lg);
  }

  .lg\:rt-r-inset-0 {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .lg\:rt-r-inset-1 {
    inset: var(--space-1);
  }

  .lg\:rt-r-inset-2 {
    inset: var(--space-2);
  }

  .lg\:rt-r-inset-3 {
    inset: var(--space-3);
  }

  .lg\:rt-r-inset-4 {
    inset: var(--space-4);
  }

  .lg\:rt-r-inset-5 {
    inset: var(--space-5);
  }

  .lg\:rt-r-inset-6 {
    inset: var(--space-6);
  }

  .lg\:rt-r-inset-7 {
    inset: var(--space-7);
  }

  .lg\:rt-r-inset-8 {
    inset: var(--space-8);
  }

  .lg\:rt-r-inset-9 {
    inset: var(--space-9);
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-inset {
    inset: var(--inset-xl);
  }

  .xl\:rt-r-inset-0 {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .xl\:rt-r-inset-1 {
    inset: var(--space-1);
  }

  .xl\:rt-r-inset-2 {
    inset: var(--space-2);
  }

  .xl\:rt-r-inset-3 {
    inset: var(--space-3);
  }

  .xl\:rt-r-inset-4 {
    inset: var(--space-4);
  }

  .xl\:rt-r-inset-5 {
    inset: var(--space-5);
  }

  .xl\:rt-r-inset-6 {
    inset: var(--space-6);
  }

  .xl\:rt-r-inset-7 {
    inset: var(--space-7);
  }

  .xl\:rt-r-inset-8 {
    inset: var(--space-8);
  }

  .xl\:rt-r-inset-9 {
    inset: var(--space-9);
  }
}

.rt-r-top {
  top: var(--top);
}

.rt-r-top-0 {
  top: 0;
}

.rt-r-top-1 {
  top: var(--space-1);
}

.rt-r-top-2 {
  top: var(--space-2);
}

.rt-r-top-3 {
  top: var(--space-3);
}

.rt-r-top-4 {
  top: var(--space-4);
}

.rt-r-top-5 {
  top: var(--space-5);
}

.rt-r-top-6 {
  top: var(--space-6);
}

.rt-r-top-7 {
  top: var(--space-7);
}

.rt-r-top-8 {
  top: var(--space-8);
}

.rt-r-top-9 {
  top: var(--space-9);
}

@media (min-width: 520px) {
  .xs\:rt-r-top {
    top: var(--top-xs);
  }

  .xs\:rt-r-top-0 {
    top: 0;
  }

  .xs\:rt-r-top-1 {
    top: var(--space-1);
  }

  .xs\:rt-r-top-2 {
    top: var(--space-2);
  }

  .xs\:rt-r-top-3 {
    top: var(--space-3);
  }

  .xs\:rt-r-top-4 {
    top: var(--space-4);
  }

  .xs\:rt-r-top-5 {
    top: var(--space-5);
  }

  .xs\:rt-r-top-6 {
    top: var(--space-6);
  }

  .xs\:rt-r-top-7 {
    top: var(--space-7);
  }

  .xs\:rt-r-top-8 {
    top: var(--space-8);
  }

  .xs\:rt-r-top-9 {
    top: var(--space-9);
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-top {
    top: var(--top-sm);
  }

  .sm\:rt-r-top-0 {
    top: 0;
  }

  .sm\:rt-r-top-1 {
    top: var(--space-1);
  }

  .sm\:rt-r-top-2 {
    top: var(--space-2);
  }

  .sm\:rt-r-top-3 {
    top: var(--space-3);
  }

  .sm\:rt-r-top-4 {
    top: var(--space-4);
  }

  .sm\:rt-r-top-5 {
    top: var(--space-5);
  }

  .sm\:rt-r-top-6 {
    top: var(--space-6);
  }

  .sm\:rt-r-top-7 {
    top: var(--space-7);
  }

  .sm\:rt-r-top-8 {
    top: var(--space-8);
  }

  .sm\:rt-r-top-9 {
    top: var(--space-9);
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-top {
    top: var(--top-md);
  }

  .md\:rt-r-top-0 {
    top: 0;
  }

  .md\:rt-r-top-1 {
    top: var(--space-1);
  }

  .md\:rt-r-top-2 {
    top: var(--space-2);
  }

  .md\:rt-r-top-3 {
    top: var(--space-3);
  }

  .md\:rt-r-top-4 {
    top: var(--space-4);
  }

  .md\:rt-r-top-5 {
    top: var(--space-5);
  }

  .md\:rt-r-top-6 {
    top: var(--space-6);
  }

  .md\:rt-r-top-7 {
    top: var(--space-7);
  }

  .md\:rt-r-top-8 {
    top: var(--space-8);
  }

  .md\:rt-r-top-9 {
    top: var(--space-9);
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-top {
    top: var(--top-lg);
  }

  .lg\:rt-r-top-0 {
    top: 0;
  }

  .lg\:rt-r-top-1 {
    top: var(--space-1);
  }

  .lg\:rt-r-top-2 {
    top: var(--space-2);
  }

  .lg\:rt-r-top-3 {
    top: var(--space-3);
  }

  .lg\:rt-r-top-4 {
    top: var(--space-4);
  }

  .lg\:rt-r-top-5 {
    top: var(--space-5);
  }

  .lg\:rt-r-top-6 {
    top: var(--space-6);
  }

  .lg\:rt-r-top-7 {
    top: var(--space-7);
  }

  .lg\:rt-r-top-8 {
    top: var(--space-8);
  }

  .lg\:rt-r-top-9 {
    top: var(--space-9);
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-top {
    top: var(--top-xl);
  }

  .xl\:rt-r-top-0 {
    top: 0;
  }

  .xl\:rt-r-top-1 {
    top: var(--space-1);
  }

  .xl\:rt-r-top-2 {
    top: var(--space-2);
  }

  .xl\:rt-r-top-3 {
    top: var(--space-3);
  }

  .xl\:rt-r-top-4 {
    top: var(--space-4);
  }

  .xl\:rt-r-top-5 {
    top: var(--space-5);
  }

  .xl\:rt-r-top-6 {
    top: var(--space-6);
  }

  .xl\:rt-r-top-7 {
    top: var(--space-7);
  }

  .xl\:rt-r-top-8 {
    top: var(--space-8);
  }

  .xl\:rt-r-top-9 {
    top: var(--space-9);
  }
}

.rt-r-right {
  right: var(--right);
}

.rt-r-right-0 {
  right: 0;
}

.rt-r-right-1 {
  right: var(--space-1);
}

.rt-r-right-2 {
  right: var(--space-2);
}

.rt-r-right-3 {
  right: var(--space-3);
}

.rt-r-right-4 {
  right: var(--space-4);
}

.rt-r-right-5 {
  right: var(--space-5);
}

.rt-r-right-6 {
  right: var(--space-6);
}

.rt-r-right-7 {
  right: var(--space-7);
}

.rt-r-right-8 {
  right: var(--space-8);
}

.rt-r-right-9 {
  right: var(--space-9);
}

@media (min-width: 520px) {
  .xs\:rt-r-right {
    right: var(--right-xs);
  }

  .xs\:rt-r-right-0 {
    right: 0;
  }

  .xs\:rt-r-right-1 {
    right: var(--space-1);
  }

  .xs\:rt-r-right-2 {
    right: var(--space-2);
  }

  .xs\:rt-r-right-3 {
    right: var(--space-3);
  }

  .xs\:rt-r-right-4 {
    right: var(--space-4);
  }

  .xs\:rt-r-right-5 {
    right: var(--space-5);
  }

  .xs\:rt-r-right-6 {
    right: var(--space-6);
  }

  .xs\:rt-r-right-7 {
    right: var(--space-7);
  }

  .xs\:rt-r-right-8 {
    right: var(--space-8);
  }

  .xs\:rt-r-right-9 {
    right: var(--space-9);
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-right {
    right: var(--right-sm);
  }

  .sm\:rt-r-right-0 {
    right: 0;
  }

  .sm\:rt-r-right-1 {
    right: var(--space-1);
  }

  .sm\:rt-r-right-2 {
    right: var(--space-2);
  }

  .sm\:rt-r-right-3 {
    right: var(--space-3);
  }

  .sm\:rt-r-right-4 {
    right: var(--space-4);
  }

  .sm\:rt-r-right-5 {
    right: var(--space-5);
  }

  .sm\:rt-r-right-6 {
    right: var(--space-6);
  }

  .sm\:rt-r-right-7 {
    right: var(--space-7);
  }

  .sm\:rt-r-right-8 {
    right: var(--space-8);
  }

  .sm\:rt-r-right-9 {
    right: var(--space-9);
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-right {
    right: var(--right-md);
  }

  .md\:rt-r-right-0 {
    right: 0;
  }

  .md\:rt-r-right-1 {
    right: var(--space-1);
  }

  .md\:rt-r-right-2 {
    right: var(--space-2);
  }

  .md\:rt-r-right-3 {
    right: var(--space-3);
  }

  .md\:rt-r-right-4 {
    right: var(--space-4);
  }

  .md\:rt-r-right-5 {
    right: var(--space-5);
  }

  .md\:rt-r-right-6 {
    right: var(--space-6);
  }

  .md\:rt-r-right-7 {
    right: var(--space-7);
  }

  .md\:rt-r-right-8 {
    right: var(--space-8);
  }

  .md\:rt-r-right-9 {
    right: var(--space-9);
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-right {
    right: var(--right-lg);
  }

  .lg\:rt-r-right-0 {
    right: 0;
  }

  .lg\:rt-r-right-1 {
    right: var(--space-1);
  }

  .lg\:rt-r-right-2 {
    right: var(--space-2);
  }

  .lg\:rt-r-right-3 {
    right: var(--space-3);
  }

  .lg\:rt-r-right-4 {
    right: var(--space-4);
  }

  .lg\:rt-r-right-5 {
    right: var(--space-5);
  }

  .lg\:rt-r-right-6 {
    right: var(--space-6);
  }

  .lg\:rt-r-right-7 {
    right: var(--space-7);
  }

  .lg\:rt-r-right-8 {
    right: var(--space-8);
  }

  .lg\:rt-r-right-9 {
    right: var(--space-9);
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-right {
    right: var(--right-xl);
  }

  .xl\:rt-r-right-0 {
    right: 0;
  }

  .xl\:rt-r-right-1 {
    right: var(--space-1);
  }

  .xl\:rt-r-right-2 {
    right: var(--space-2);
  }

  .xl\:rt-r-right-3 {
    right: var(--space-3);
  }

  .xl\:rt-r-right-4 {
    right: var(--space-4);
  }

  .xl\:rt-r-right-5 {
    right: var(--space-5);
  }

  .xl\:rt-r-right-6 {
    right: var(--space-6);
  }

  .xl\:rt-r-right-7 {
    right: var(--space-7);
  }

  .xl\:rt-r-right-8 {
    right: var(--space-8);
  }

  .xl\:rt-r-right-9 {
    right: var(--space-9);
  }
}

.rt-r-bottom {
  bottom: var(--bottom);
}

.rt-r-bottom-0 {
  bottom: 0;
}

.rt-r-bottom-1 {
  bottom: var(--space-1);
}

.rt-r-bottom-2 {
  bottom: var(--space-2);
}

.rt-r-bottom-3 {
  bottom: var(--space-3);
}

.rt-r-bottom-4 {
  bottom: var(--space-4);
}

.rt-r-bottom-5 {
  bottom: var(--space-5);
}

.rt-r-bottom-6 {
  bottom: var(--space-6);
}

.rt-r-bottom-7 {
  bottom: var(--space-7);
}

.rt-r-bottom-8 {
  bottom: var(--space-8);
}

.rt-r-bottom-9 {
  bottom: var(--space-9);
}

@media (min-width: 520px) {
  .xs\:rt-r-bottom {
    bottom: var(--bottom-xs);
  }

  .xs\:rt-r-bottom-0 {
    bottom: 0;
  }

  .xs\:rt-r-bottom-1 {
    bottom: var(--space-1);
  }

  .xs\:rt-r-bottom-2 {
    bottom: var(--space-2);
  }

  .xs\:rt-r-bottom-3 {
    bottom: var(--space-3);
  }

  .xs\:rt-r-bottom-4 {
    bottom: var(--space-4);
  }

  .xs\:rt-r-bottom-5 {
    bottom: var(--space-5);
  }

  .xs\:rt-r-bottom-6 {
    bottom: var(--space-6);
  }

  .xs\:rt-r-bottom-7 {
    bottom: var(--space-7);
  }

  .xs\:rt-r-bottom-8 {
    bottom: var(--space-8);
  }

  .xs\:rt-r-bottom-9 {
    bottom: var(--space-9);
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-bottom {
    bottom: var(--bottom-sm);
  }

  .sm\:rt-r-bottom-0 {
    bottom: 0;
  }

  .sm\:rt-r-bottom-1 {
    bottom: var(--space-1);
  }

  .sm\:rt-r-bottom-2 {
    bottom: var(--space-2);
  }

  .sm\:rt-r-bottom-3 {
    bottom: var(--space-3);
  }

  .sm\:rt-r-bottom-4 {
    bottom: var(--space-4);
  }

  .sm\:rt-r-bottom-5 {
    bottom: var(--space-5);
  }

  .sm\:rt-r-bottom-6 {
    bottom: var(--space-6);
  }

  .sm\:rt-r-bottom-7 {
    bottom: var(--space-7);
  }

  .sm\:rt-r-bottom-8 {
    bottom: var(--space-8);
  }

  .sm\:rt-r-bottom-9 {
    bottom: var(--space-9);
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-bottom {
    bottom: var(--bottom-md);
  }

  .md\:rt-r-bottom-0 {
    bottom: 0;
  }

  .md\:rt-r-bottom-1 {
    bottom: var(--space-1);
  }

  .md\:rt-r-bottom-2 {
    bottom: var(--space-2);
  }

  .md\:rt-r-bottom-3 {
    bottom: var(--space-3);
  }

  .md\:rt-r-bottom-4 {
    bottom: var(--space-4);
  }

  .md\:rt-r-bottom-5 {
    bottom: var(--space-5);
  }

  .md\:rt-r-bottom-6 {
    bottom: var(--space-6);
  }

  .md\:rt-r-bottom-7 {
    bottom: var(--space-7);
  }

  .md\:rt-r-bottom-8 {
    bottom: var(--space-8);
  }

  .md\:rt-r-bottom-9 {
    bottom: var(--space-9);
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-bottom {
    bottom: var(--bottom-lg);
  }

  .lg\:rt-r-bottom-0 {
    bottom: 0;
  }

  .lg\:rt-r-bottom-1 {
    bottom: var(--space-1);
  }

  .lg\:rt-r-bottom-2 {
    bottom: var(--space-2);
  }

  .lg\:rt-r-bottom-3 {
    bottom: var(--space-3);
  }

  .lg\:rt-r-bottom-4 {
    bottom: var(--space-4);
  }

  .lg\:rt-r-bottom-5 {
    bottom: var(--space-5);
  }

  .lg\:rt-r-bottom-6 {
    bottom: var(--space-6);
  }

  .lg\:rt-r-bottom-7 {
    bottom: var(--space-7);
  }

  .lg\:rt-r-bottom-8 {
    bottom: var(--space-8);
  }

  .lg\:rt-r-bottom-9 {
    bottom: var(--space-9);
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-bottom {
    bottom: var(--bottom-xl);
  }

  .xl\:rt-r-bottom-0 {
    bottom: 0;
  }

  .xl\:rt-r-bottom-1 {
    bottom: var(--space-1);
  }

  .xl\:rt-r-bottom-2 {
    bottom: var(--space-2);
  }

  .xl\:rt-r-bottom-3 {
    bottom: var(--space-3);
  }

  .xl\:rt-r-bottom-4 {
    bottom: var(--space-4);
  }

  .xl\:rt-r-bottom-5 {
    bottom: var(--space-5);
  }

  .xl\:rt-r-bottom-6 {
    bottom: var(--space-6);
  }

  .xl\:rt-r-bottom-7 {
    bottom: var(--space-7);
  }

  .xl\:rt-r-bottom-8 {
    bottom: var(--space-8);
  }

  .xl\:rt-r-bottom-9 {
    bottom: var(--space-9);
  }
}

.rt-r-left {
  left: var(--left);
}

.rt-r-left-0 {
  left: 0;
}

.rt-r-left-1 {
  left: var(--space-1);
}

.rt-r-left-2 {
  left: var(--space-2);
}

.rt-r-left-3 {
  left: var(--space-3);
}

.rt-r-left-4 {
  left: var(--space-4);
}

.rt-r-left-5 {
  left: var(--space-5);
}

.rt-r-left-6 {
  left: var(--space-6);
}

.rt-r-left-7 {
  left: var(--space-7);
}

.rt-r-left-8 {
  left: var(--space-8);
}

.rt-r-left-9 {
  left: var(--space-9);
}

@media (min-width: 520px) {
  .xs\:rt-r-left {
    left: var(--left-xs);
  }

  .xs\:rt-r-left-0 {
    left: 0;
  }

  .xs\:rt-r-left-1 {
    left: var(--space-1);
  }

  .xs\:rt-r-left-2 {
    left: var(--space-2);
  }

  .xs\:rt-r-left-3 {
    left: var(--space-3);
  }

  .xs\:rt-r-left-4 {
    left: var(--space-4);
  }

  .xs\:rt-r-left-5 {
    left: var(--space-5);
  }

  .xs\:rt-r-left-6 {
    left: var(--space-6);
  }

  .xs\:rt-r-left-7 {
    left: var(--space-7);
  }

  .xs\:rt-r-left-8 {
    left: var(--space-8);
  }

  .xs\:rt-r-left-9 {
    left: var(--space-9);
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-left {
    left: var(--left-sm);
  }

  .sm\:rt-r-left-0 {
    left: 0;
  }

  .sm\:rt-r-left-1 {
    left: var(--space-1);
  }

  .sm\:rt-r-left-2 {
    left: var(--space-2);
  }

  .sm\:rt-r-left-3 {
    left: var(--space-3);
  }

  .sm\:rt-r-left-4 {
    left: var(--space-4);
  }

  .sm\:rt-r-left-5 {
    left: var(--space-5);
  }

  .sm\:rt-r-left-6 {
    left: var(--space-6);
  }

  .sm\:rt-r-left-7 {
    left: var(--space-7);
  }

  .sm\:rt-r-left-8 {
    left: var(--space-8);
  }

  .sm\:rt-r-left-9 {
    left: var(--space-9);
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-left {
    left: var(--left-md);
  }

  .md\:rt-r-left-0 {
    left: 0;
  }

  .md\:rt-r-left-1 {
    left: var(--space-1);
  }

  .md\:rt-r-left-2 {
    left: var(--space-2);
  }

  .md\:rt-r-left-3 {
    left: var(--space-3);
  }

  .md\:rt-r-left-4 {
    left: var(--space-4);
  }

  .md\:rt-r-left-5 {
    left: var(--space-5);
  }

  .md\:rt-r-left-6 {
    left: var(--space-6);
  }

  .md\:rt-r-left-7 {
    left: var(--space-7);
  }

  .md\:rt-r-left-8 {
    left: var(--space-8);
  }

  .md\:rt-r-left-9 {
    left: var(--space-9);
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-left {
    left: var(--left-lg);
  }

  .lg\:rt-r-left-0 {
    left: 0;
  }

  .lg\:rt-r-left-1 {
    left: var(--space-1);
  }

  .lg\:rt-r-left-2 {
    left: var(--space-2);
  }

  .lg\:rt-r-left-3 {
    left: var(--space-3);
  }

  .lg\:rt-r-left-4 {
    left: var(--space-4);
  }

  .lg\:rt-r-left-5 {
    left: var(--space-5);
  }

  .lg\:rt-r-left-6 {
    left: var(--space-6);
  }

  .lg\:rt-r-left-7 {
    left: var(--space-7);
  }

  .lg\:rt-r-left-8 {
    left: var(--space-8);
  }

  .lg\:rt-r-left-9 {
    left: var(--space-9);
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-left {
    left: var(--left-xl);
  }

  .xl\:rt-r-left-0 {
    left: 0;
  }

  .xl\:rt-r-left-1 {
    left: var(--space-1);
  }

  .xl\:rt-r-left-2 {
    left: var(--space-2);
  }

  .xl\:rt-r-left-3 {
    left: var(--space-3);
  }

  .xl\:rt-r-left-4 {
    left: var(--space-4);
  }

  .xl\:rt-r-left-5 {
    left: var(--space-5);
  }

  .xl\:rt-r-left-6 {
    left: var(--space-6);
  }

  .xl\:rt-r-left-7 {
    left: var(--space-7);
  }

  .xl\:rt-r-left-8 {
    left: var(--space-8);
  }

  .xl\:rt-r-left-9 {
    left: var(--space-9);
  }
}

.rt-r-jc-start {
  justify-content: flex-start;
}

.rt-r-jc-center {
  justify-content: center;
}

.rt-r-jc-end {
  justify-content: flex-end;
}

.rt-r-jc-space-between {
  justify-content: space-between;
}

@media (min-width: 520px) {
  .xs\:rt-r-jc-start {
    justify-content: flex-start;
  }

  .xs\:rt-r-jc-center {
    justify-content: center;
  }

  .xs\:rt-r-jc-end {
    justify-content: flex-end;
  }

  .xs\:rt-r-jc-space-between {
    justify-content: space-between;
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-jc-start {
    justify-content: flex-start;
  }

  .sm\:rt-r-jc-center {
    justify-content: center;
  }

  .sm\:rt-r-jc-end {
    justify-content: flex-end;
  }

  .sm\:rt-r-jc-space-between {
    justify-content: space-between;
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-jc-start {
    justify-content: flex-start;
  }

  .md\:rt-r-jc-center {
    justify-content: center;
  }

  .md\:rt-r-jc-end {
    justify-content: flex-end;
  }

  .md\:rt-r-jc-space-between {
    justify-content: space-between;
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-jc-start {
    justify-content: flex-start;
  }

  .lg\:rt-r-jc-center {
    justify-content: center;
  }

  .lg\:rt-r-jc-end {
    justify-content: flex-end;
  }

  .lg\:rt-r-jc-space-between {
    justify-content: space-between;
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-jc-start {
    justify-content: flex-start;
  }

  .xl\:rt-r-jc-center {
    justify-content: center;
  }

  .xl\:rt-r-jc-end {
    justify-content: flex-end;
  }

  .xl\:rt-r-jc-space-between {
    justify-content: space-between;
  }
}

.rt-r-m, .rt-r-m-0, .rt-r-m-1, .rt-r-m-2, .rt-r-m-3, .rt-r-m-4, .rt-r-m-5, .rt-r-m-6, .rt-r-m-7, .rt-r-m-8, .rt-r-m-9, .-rt-r-m-1, .-rt-r-m-2, .-rt-r-m-3, .-rt-r-m-4, .-rt-r-m-5, .-rt-r-m-6, .-rt-r-m-7, .-rt-r-m-8, .-rt-r-m-9 {
  margin-top: var(--margin-top-override, var(--margin-top));
  margin-right: var(--margin-right-override, var(--margin-right));
  margin-bottom: var(--margin-bottom-override, var(--margin-bottom));
  margin-left: var(--margin-left-override, var(--margin-left));
}

.rt-r-m {
  --margin-top: var(--m);
  --margin-right: var(--m);
  --margin-bottom: var(--m);
  --margin-left: var(--m);
}

.rt-r-m-0 {
  --margin-top: 0px;
  --margin-right: 0px;
  --margin-bottom: 0px;
  --margin-left: 0px;
}

.rt-r-m-1 {
  --margin-top: var(--space-1);
  --margin-right: var(--space-1);
  --margin-bottom: var(--space-1);
  --margin-left: var(--space-1);
}

.rt-r-m-2 {
  --margin-top: var(--space-2);
  --margin-right: var(--space-2);
  --margin-bottom: var(--space-2);
  --margin-left: var(--space-2);
}

.rt-r-m-3 {
  --margin-top: var(--space-3);
  --margin-right: var(--space-3);
  --margin-bottom: var(--space-3);
  --margin-left: var(--space-3);
}

.rt-r-m-4 {
  --margin-top: var(--space-4);
  --margin-right: var(--space-4);
  --margin-bottom: var(--space-4);
  --margin-left: var(--space-4);
}

.rt-r-m-5 {
  --margin-top: var(--space-5);
  --margin-right: var(--space-5);
  --margin-bottom: var(--space-5);
  --margin-left: var(--space-5);
}

.rt-r-m-6 {
  --margin-top: var(--space-6);
  --margin-right: var(--space-6);
  --margin-bottom: var(--space-6);
  --margin-left: var(--space-6);
}

.rt-r-m-7 {
  --margin-top: var(--space-7);
  --margin-right: var(--space-7);
  --margin-bottom: var(--space-7);
  --margin-left: var(--space-7);
}

.rt-r-m-8 {
  --margin-top: var(--space-8);
  --margin-right: var(--space-8);
  --margin-bottom: var(--space-8);
  --margin-left: var(--space-8);
}

.rt-r-m-9 {
  --margin-top: var(--space-9);
  --margin-right: var(--space-9);
  --margin-bottom: var(--space-9);
  --margin-left: var(--space-9);
}

.-rt-r-m-1 {
  --margin-top: calc(-1 * var(--space-1));
  --margin-right: calc(-1 * var(--space-1));
  --margin-bottom: calc(-1 * var(--space-1));
  --margin-left: calc(-1 * var(--space-1));
}

.-rt-r-m-2 {
  --margin-top: calc(-1 * var(--space-2));
  --margin-right: calc(-1 * var(--space-2));
  --margin-bottom: calc(-1 * var(--space-2));
  --margin-left: calc(-1 * var(--space-2));
}

.-rt-r-m-3 {
  --margin-top: calc(-1 * var(--space-3));
  --margin-right: calc(-1 * var(--space-3));
  --margin-bottom: calc(-1 * var(--space-3));
  --margin-left: calc(-1 * var(--space-3));
}

.-rt-r-m-4 {
  --margin-top: calc(-1 * var(--space-4));
  --margin-right: calc(-1 * var(--space-4));
  --margin-bottom: calc(-1 * var(--space-4));
  --margin-left: calc(-1 * var(--space-4));
}

.-rt-r-m-5 {
  --margin-top: calc(-1 * var(--space-5));
  --margin-right: calc(-1 * var(--space-5));
  --margin-bottom: calc(-1 * var(--space-5));
  --margin-left: calc(-1 * var(--space-5));
}

.-rt-r-m-6 {
  --margin-top: calc(-1 * var(--space-6));
  --margin-right: calc(-1 * var(--space-6));
  --margin-bottom: calc(-1 * var(--space-6));
  --margin-left: calc(-1 * var(--space-6));
}

.-rt-r-m-7 {
  --margin-top: calc(-1 * var(--space-7));
  --margin-right: calc(-1 * var(--space-7));
  --margin-bottom: calc(-1 * var(--space-7));
  --margin-left: calc(-1 * var(--space-7));
}

.-rt-r-m-8 {
  --margin-top: calc(-1 * var(--space-8));
  --margin-right: calc(-1 * var(--space-8));
  --margin-bottom: calc(-1 * var(--space-8));
  --margin-left: calc(-1 * var(--space-8));
}

.-rt-r-m-9 {
  --margin-top: calc(-1 * var(--space-9));
  --margin-right: calc(-1 * var(--space-9));
  --margin-bottom: calc(-1 * var(--space-9));
  --margin-left: calc(-1 * var(--space-9));
}

@media (min-width: 520px) {
  .xs\:rt-r-m, .xs\:rt-r-m-0, .xs\:rt-r-m-1, .xs\:rt-r-m-2, .xs\:rt-r-m-3, .xs\:rt-r-m-4, .xs\:rt-r-m-5, .xs\:rt-r-m-6, .xs\:rt-r-m-7, .xs\:rt-r-m-8, .xs\:rt-r-m-9, .xs\:-rt-r-m-1, .xs\:-rt-r-m-2, .xs\:-rt-r-m-3, .xs\:-rt-r-m-4, .xs\:-rt-r-m-5, .xs\:-rt-r-m-6, .xs\:-rt-r-m-7, .xs\:-rt-r-m-8, .xs\:-rt-r-m-9 {
    margin-top: var(--margin-top-override, var(--margin-top));
    margin-right: var(--margin-right-override, var(--margin-right));
    margin-bottom: var(--margin-bottom-override, var(--margin-bottom));
    margin-left: var(--margin-left-override, var(--margin-left));
  }

  .xs\:rt-r-m {
    --margin-top: var(--m-xs);
    --margin-right: var(--m-xs);
    --margin-bottom: var(--m-xs);
    --margin-left: var(--m-xs);
  }

  .xs\:rt-r-m-0 {
    --margin-top: 0px;
    --margin-right: 0px;
    --margin-bottom: 0px;
    --margin-left: 0px;
  }

  .xs\:rt-r-m-1 {
    --margin-top: var(--space-1);
    --margin-right: var(--space-1);
    --margin-bottom: var(--space-1);
    --margin-left: var(--space-1);
  }

  .xs\:rt-r-m-2 {
    --margin-top: var(--space-2);
    --margin-right: var(--space-2);
    --margin-bottom: var(--space-2);
    --margin-left: var(--space-2);
  }

  .xs\:rt-r-m-3 {
    --margin-top: var(--space-3);
    --margin-right: var(--space-3);
    --margin-bottom: var(--space-3);
    --margin-left: var(--space-3);
  }

  .xs\:rt-r-m-4 {
    --margin-top: var(--space-4);
    --margin-right: var(--space-4);
    --margin-bottom: var(--space-4);
    --margin-left: var(--space-4);
  }

  .xs\:rt-r-m-5 {
    --margin-top: var(--space-5);
    --margin-right: var(--space-5);
    --margin-bottom: var(--space-5);
    --margin-left: var(--space-5);
  }

  .xs\:rt-r-m-6 {
    --margin-top: var(--space-6);
    --margin-right: var(--space-6);
    --margin-bottom: var(--space-6);
    --margin-left: var(--space-6);
  }

  .xs\:rt-r-m-7 {
    --margin-top: var(--space-7);
    --margin-right: var(--space-7);
    --margin-bottom: var(--space-7);
    --margin-left: var(--space-7);
  }

  .xs\:rt-r-m-8 {
    --margin-top: var(--space-8);
    --margin-right: var(--space-8);
    --margin-bottom: var(--space-8);
    --margin-left: var(--space-8);
  }

  .xs\:rt-r-m-9 {
    --margin-top: var(--space-9);
    --margin-right: var(--space-9);
    --margin-bottom: var(--space-9);
    --margin-left: var(--space-9);
  }

  .xs\:-rt-r-m-1 {
    --margin-top: calc(-1 * var(--space-1));
    --margin-right: calc(-1 * var(--space-1));
    --margin-bottom: calc(-1 * var(--space-1));
    --margin-left: calc(-1 * var(--space-1));
  }

  .xs\:-rt-r-m-2 {
    --margin-top: calc(-1 * var(--space-2));
    --margin-right: calc(-1 * var(--space-2));
    --margin-bottom: calc(-1 * var(--space-2));
    --margin-left: calc(-1 * var(--space-2));
  }

  .xs\:-rt-r-m-3 {
    --margin-top: calc(-1 * var(--space-3));
    --margin-right: calc(-1 * var(--space-3));
    --margin-bottom: calc(-1 * var(--space-3));
    --margin-left: calc(-1 * var(--space-3));
  }

  .xs\:-rt-r-m-4 {
    --margin-top: calc(-1 * var(--space-4));
    --margin-right: calc(-1 * var(--space-4));
    --margin-bottom: calc(-1 * var(--space-4));
    --margin-left: calc(-1 * var(--space-4));
  }

  .xs\:-rt-r-m-5 {
    --margin-top: calc(-1 * var(--space-5));
    --margin-right: calc(-1 * var(--space-5));
    --margin-bottom: calc(-1 * var(--space-5));
    --margin-left: calc(-1 * var(--space-5));
  }

  .xs\:-rt-r-m-6 {
    --margin-top: calc(-1 * var(--space-6));
    --margin-right: calc(-1 * var(--space-6));
    --margin-bottom: calc(-1 * var(--space-6));
    --margin-left: calc(-1 * var(--space-6));
  }

  .xs\:-rt-r-m-7 {
    --margin-top: calc(-1 * var(--space-7));
    --margin-right: calc(-1 * var(--space-7));
    --margin-bottom: calc(-1 * var(--space-7));
    --margin-left: calc(-1 * var(--space-7));
  }

  .xs\:-rt-r-m-8 {
    --margin-top: calc(-1 * var(--space-8));
    --margin-right: calc(-1 * var(--space-8));
    --margin-bottom: calc(-1 * var(--space-8));
    --margin-left: calc(-1 * var(--space-8));
  }

  .xs\:-rt-r-m-9 {
    --margin-top: calc(-1 * var(--space-9));
    --margin-right: calc(-1 * var(--space-9));
    --margin-bottom: calc(-1 * var(--space-9));
    --margin-left: calc(-1 * var(--space-9));
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-m, .sm\:rt-r-m-0, .sm\:rt-r-m-1, .sm\:rt-r-m-2, .sm\:rt-r-m-3, .sm\:rt-r-m-4, .sm\:rt-r-m-5, .sm\:rt-r-m-6, .sm\:rt-r-m-7, .sm\:rt-r-m-8, .sm\:rt-r-m-9, .sm\:-rt-r-m-1, .sm\:-rt-r-m-2, .sm\:-rt-r-m-3, .sm\:-rt-r-m-4, .sm\:-rt-r-m-5, .sm\:-rt-r-m-6, .sm\:-rt-r-m-7, .sm\:-rt-r-m-8, .sm\:-rt-r-m-9 {
    margin-top: var(--margin-top-override, var(--margin-top));
    margin-right: var(--margin-right-override, var(--margin-right));
    margin-bottom: var(--margin-bottom-override, var(--margin-bottom));
    margin-left: var(--margin-left-override, var(--margin-left));
  }

  .sm\:rt-r-m {
    --margin-top: var(--m-sm);
    --margin-right: var(--m-sm);
    --margin-bottom: var(--m-sm);
    --margin-left: var(--m-sm);
  }

  .sm\:rt-r-m-0 {
    --margin-top: 0px;
    --margin-right: 0px;
    --margin-bottom: 0px;
    --margin-left: 0px;
  }

  .sm\:rt-r-m-1 {
    --margin-top: var(--space-1);
    --margin-right: var(--space-1);
    --margin-bottom: var(--space-1);
    --margin-left: var(--space-1);
  }

  .sm\:rt-r-m-2 {
    --margin-top: var(--space-2);
    --margin-right: var(--space-2);
    --margin-bottom: var(--space-2);
    --margin-left: var(--space-2);
  }

  .sm\:rt-r-m-3 {
    --margin-top: var(--space-3);
    --margin-right: var(--space-3);
    --margin-bottom: var(--space-3);
    --margin-left: var(--space-3);
  }

  .sm\:rt-r-m-4 {
    --margin-top: var(--space-4);
    --margin-right: var(--space-4);
    --margin-bottom: var(--space-4);
    --margin-left: var(--space-4);
  }

  .sm\:rt-r-m-5 {
    --margin-top: var(--space-5);
    --margin-right: var(--space-5);
    --margin-bottom: var(--space-5);
    --margin-left: var(--space-5);
  }

  .sm\:rt-r-m-6 {
    --margin-top: var(--space-6);
    --margin-right: var(--space-6);
    --margin-bottom: var(--space-6);
    --margin-left: var(--space-6);
  }

  .sm\:rt-r-m-7 {
    --margin-top: var(--space-7);
    --margin-right: var(--space-7);
    --margin-bottom: var(--space-7);
    --margin-left: var(--space-7);
  }

  .sm\:rt-r-m-8 {
    --margin-top: var(--space-8);
    --margin-right: var(--space-8);
    --margin-bottom: var(--space-8);
    --margin-left: var(--space-8);
  }

  .sm\:rt-r-m-9 {
    --margin-top: var(--space-9);
    --margin-right: var(--space-9);
    --margin-bottom: var(--space-9);
    --margin-left: var(--space-9);
  }

  .sm\:-rt-r-m-1 {
    --margin-top: calc(-1 * var(--space-1));
    --margin-right: calc(-1 * var(--space-1));
    --margin-bottom: calc(-1 * var(--space-1));
    --margin-left: calc(-1 * var(--space-1));
  }

  .sm\:-rt-r-m-2 {
    --margin-top: calc(-1 * var(--space-2));
    --margin-right: calc(-1 * var(--space-2));
    --margin-bottom: calc(-1 * var(--space-2));
    --margin-left: calc(-1 * var(--space-2));
  }

  .sm\:-rt-r-m-3 {
    --margin-top: calc(-1 * var(--space-3));
    --margin-right: calc(-1 * var(--space-3));
    --margin-bottom: calc(-1 * var(--space-3));
    --margin-left: calc(-1 * var(--space-3));
  }

  .sm\:-rt-r-m-4 {
    --margin-top: calc(-1 * var(--space-4));
    --margin-right: calc(-1 * var(--space-4));
    --margin-bottom: calc(-1 * var(--space-4));
    --margin-left: calc(-1 * var(--space-4));
  }

  .sm\:-rt-r-m-5 {
    --margin-top: calc(-1 * var(--space-5));
    --margin-right: calc(-1 * var(--space-5));
    --margin-bottom: calc(-1 * var(--space-5));
    --margin-left: calc(-1 * var(--space-5));
  }

  .sm\:-rt-r-m-6 {
    --margin-top: calc(-1 * var(--space-6));
    --margin-right: calc(-1 * var(--space-6));
    --margin-bottom: calc(-1 * var(--space-6));
    --margin-left: calc(-1 * var(--space-6));
  }

  .sm\:-rt-r-m-7 {
    --margin-top: calc(-1 * var(--space-7));
    --margin-right: calc(-1 * var(--space-7));
    --margin-bottom: calc(-1 * var(--space-7));
    --margin-left: calc(-1 * var(--space-7));
  }

  .sm\:-rt-r-m-8 {
    --margin-top: calc(-1 * var(--space-8));
    --margin-right: calc(-1 * var(--space-8));
    --margin-bottom: calc(-1 * var(--space-8));
    --margin-left: calc(-1 * var(--space-8));
  }

  .sm\:-rt-r-m-9 {
    --margin-top: calc(-1 * var(--space-9));
    --margin-right: calc(-1 * var(--space-9));
    --margin-bottom: calc(-1 * var(--space-9));
    --margin-left: calc(-1 * var(--space-9));
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-m, .md\:rt-r-m-0, .md\:rt-r-m-1, .md\:rt-r-m-2, .md\:rt-r-m-3, .md\:rt-r-m-4, .md\:rt-r-m-5, .md\:rt-r-m-6, .md\:rt-r-m-7, .md\:rt-r-m-8, .md\:rt-r-m-9, .md\:-rt-r-m-1, .md\:-rt-r-m-2, .md\:-rt-r-m-3, .md\:-rt-r-m-4, .md\:-rt-r-m-5, .md\:-rt-r-m-6, .md\:-rt-r-m-7, .md\:-rt-r-m-8, .md\:-rt-r-m-9 {
    margin-top: var(--margin-top-override, var(--margin-top));
    margin-right: var(--margin-right-override, var(--margin-right));
    margin-bottom: var(--margin-bottom-override, var(--margin-bottom));
    margin-left: var(--margin-left-override, var(--margin-left));
  }

  .md\:rt-r-m {
    --margin-top: var(--m-md);
    --margin-right: var(--m-md);
    --margin-bottom: var(--m-md);
    --margin-left: var(--m-md);
  }

  .md\:rt-r-m-0 {
    --margin-top: 0px;
    --margin-right: 0px;
    --margin-bottom: 0px;
    --margin-left: 0px;
  }

  .md\:rt-r-m-1 {
    --margin-top: var(--space-1);
    --margin-right: var(--space-1);
    --margin-bottom: var(--space-1);
    --margin-left: var(--space-1);
  }

  .md\:rt-r-m-2 {
    --margin-top: var(--space-2);
    --margin-right: var(--space-2);
    --margin-bottom: var(--space-2);
    --margin-left: var(--space-2);
  }

  .md\:rt-r-m-3 {
    --margin-top: var(--space-3);
    --margin-right: var(--space-3);
    --margin-bottom: var(--space-3);
    --margin-left: var(--space-3);
  }

  .md\:rt-r-m-4 {
    --margin-top: var(--space-4);
    --margin-right: var(--space-4);
    --margin-bottom: var(--space-4);
    --margin-left: var(--space-4);
  }

  .md\:rt-r-m-5 {
    --margin-top: var(--space-5);
    --margin-right: var(--space-5);
    --margin-bottom: var(--space-5);
    --margin-left: var(--space-5);
  }

  .md\:rt-r-m-6 {
    --margin-top: var(--space-6);
    --margin-right: var(--space-6);
    --margin-bottom: var(--space-6);
    --margin-left: var(--space-6);
  }

  .md\:rt-r-m-7 {
    --margin-top: var(--space-7);
    --margin-right: var(--space-7);
    --margin-bottom: var(--space-7);
    --margin-left: var(--space-7);
  }

  .md\:rt-r-m-8 {
    --margin-top: var(--space-8);
    --margin-right: var(--space-8);
    --margin-bottom: var(--space-8);
    --margin-left: var(--space-8);
  }

  .md\:rt-r-m-9 {
    --margin-top: var(--space-9);
    --margin-right: var(--space-9);
    --margin-bottom: var(--space-9);
    --margin-left: var(--space-9);
  }

  .md\:-rt-r-m-1 {
    --margin-top: calc(-1 * var(--space-1));
    --margin-right: calc(-1 * var(--space-1));
    --margin-bottom: calc(-1 * var(--space-1));
    --margin-left: calc(-1 * var(--space-1));
  }

  .md\:-rt-r-m-2 {
    --margin-top: calc(-1 * var(--space-2));
    --margin-right: calc(-1 * var(--space-2));
    --margin-bottom: calc(-1 * var(--space-2));
    --margin-left: calc(-1 * var(--space-2));
  }

  .md\:-rt-r-m-3 {
    --margin-top: calc(-1 * var(--space-3));
    --margin-right: calc(-1 * var(--space-3));
    --margin-bottom: calc(-1 * var(--space-3));
    --margin-left: calc(-1 * var(--space-3));
  }

  .md\:-rt-r-m-4 {
    --margin-top: calc(-1 * var(--space-4));
    --margin-right: calc(-1 * var(--space-4));
    --margin-bottom: calc(-1 * var(--space-4));
    --margin-left: calc(-1 * var(--space-4));
  }

  .md\:-rt-r-m-5 {
    --margin-top: calc(-1 * var(--space-5));
    --margin-right: calc(-1 * var(--space-5));
    --margin-bottom: calc(-1 * var(--space-5));
    --margin-left: calc(-1 * var(--space-5));
  }

  .md\:-rt-r-m-6 {
    --margin-top: calc(-1 * var(--space-6));
    --margin-right: calc(-1 * var(--space-6));
    --margin-bottom: calc(-1 * var(--space-6));
    --margin-left: calc(-1 * var(--space-6));
  }

  .md\:-rt-r-m-7 {
    --margin-top: calc(-1 * var(--space-7));
    --margin-right: calc(-1 * var(--space-7));
    --margin-bottom: calc(-1 * var(--space-7));
    --margin-left: calc(-1 * var(--space-7));
  }

  .md\:-rt-r-m-8 {
    --margin-top: calc(-1 * var(--space-8));
    --margin-right: calc(-1 * var(--space-8));
    --margin-bottom: calc(-1 * var(--space-8));
    --margin-left: calc(-1 * var(--space-8));
  }

  .md\:-rt-r-m-9 {
    --margin-top: calc(-1 * var(--space-9));
    --margin-right: calc(-1 * var(--space-9));
    --margin-bottom: calc(-1 * var(--space-9));
    --margin-left: calc(-1 * var(--space-9));
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-m, .lg\:rt-r-m-0, .lg\:rt-r-m-1, .lg\:rt-r-m-2, .lg\:rt-r-m-3, .lg\:rt-r-m-4, .lg\:rt-r-m-5, .lg\:rt-r-m-6, .lg\:rt-r-m-7, .lg\:rt-r-m-8, .lg\:rt-r-m-9, .lg\:-rt-r-m-1, .lg\:-rt-r-m-2, .lg\:-rt-r-m-3, .lg\:-rt-r-m-4, .lg\:-rt-r-m-5, .lg\:-rt-r-m-6, .lg\:-rt-r-m-7, .lg\:-rt-r-m-8, .lg\:-rt-r-m-9 {
    margin-top: var(--margin-top-override, var(--margin-top));
    margin-right: var(--margin-right-override, var(--margin-right));
    margin-bottom: var(--margin-bottom-override, var(--margin-bottom));
    margin-left: var(--margin-left-override, var(--margin-left));
  }

  .lg\:rt-r-m {
    --margin-top: var(--m-lg);
    --margin-right: var(--m-lg);
    --margin-bottom: var(--m-lg);
    --margin-left: var(--m-lg);
  }

  .lg\:rt-r-m-0 {
    --margin-top: 0px;
    --margin-right: 0px;
    --margin-bottom: 0px;
    --margin-left: 0px;
  }

  .lg\:rt-r-m-1 {
    --margin-top: var(--space-1);
    --margin-right: var(--space-1);
    --margin-bottom: var(--space-1);
    --margin-left: var(--space-1);
  }

  .lg\:rt-r-m-2 {
    --margin-top: var(--space-2);
    --margin-right: var(--space-2);
    --margin-bottom: var(--space-2);
    --margin-left: var(--space-2);
  }

  .lg\:rt-r-m-3 {
    --margin-top: var(--space-3);
    --margin-right: var(--space-3);
    --margin-bottom: var(--space-3);
    --margin-left: var(--space-3);
  }

  .lg\:rt-r-m-4 {
    --margin-top: var(--space-4);
    --margin-right: var(--space-4);
    --margin-bottom: var(--space-4);
    --margin-left: var(--space-4);
  }

  .lg\:rt-r-m-5 {
    --margin-top: var(--space-5);
    --margin-right: var(--space-5);
    --margin-bottom: var(--space-5);
    --margin-left: var(--space-5);
  }

  .lg\:rt-r-m-6 {
    --margin-top: var(--space-6);
    --margin-right: var(--space-6);
    --margin-bottom: var(--space-6);
    --margin-left: var(--space-6);
  }

  .lg\:rt-r-m-7 {
    --margin-top: var(--space-7);
    --margin-right: var(--space-7);
    --margin-bottom: var(--space-7);
    --margin-left: var(--space-7);
  }

  .lg\:rt-r-m-8 {
    --margin-top: var(--space-8);
    --margin-right: var(--space-8);
    --margin-bottom: var(--space-8);
    --margin-left: var(--space-8);
  }

  .lg\:rt-r-m-9 {
    --margin-top: var(--space-9);
    --margin-right: var(--space-9);
    --margin-bottom: var(--space-9);
    --margin-left: var(--space-9);
  }

  .lg\:-rt-r-m-1 {
    --margin-top: calc(-1 * var(--space-1));
    --margin-right: calc(-1 * var(--space-1));
    --margin-bottom: calc(-1 * var(--space-1));
    --margin-left: calc(-1 * var(--space-1));
  }

  .lg\:-rt-r-m-2 {
    --margin-top: calc(-1 * var(--space-2));
    --margin-right: calc(-1 * var(--space-2));
    --margin-bottom: calc(-1 * var(--space-2));
    --margin-left: calc(-1 * var(--space-2));
  }

  .lg\:-rt-r-m-3 {
    --margin-top: calc(-1 * var(--space-3));
    --margin-right: calc(-1 * var(--space-3));
    --margin-bottom: calc(-1 * var(--space-3));
    --margin-left: calc(-1 * var(--space-3));
  }

  .lg\:-rt-r-m-4 {
    --margin-top: calc(-1 * var(--space-4));
    --margin-right: calc(-1 * var(--space-4));
    --margin-bottom: calc(-1 * var(--space-4));
    --margin-left: calc(-1 * var(--space-4));
  }

  .lg\:-rt-r-m-5 {
    --margin-top: calc(-1 * var(--space-5));
    --margin-right: calc(-1 * var(--space-5));
    --margin-bottom: calc(-1 * var(--space-5));
    --margin-left: calc(-1 * var(--space-5));
  }

  .lg\:-rt-r-m-6 {
    --margin-top: calc(-1 * var(--space-6));
    --margin-right: calc(-1 * var(--space-6));
    --margin-bottom: calc(-1 * var(--space-6));
    --margin-left: calc(-1 * var(--space-6));
  }

  .lg\:-rt-r-m-7 {
    --margin-top: calc(-1 * var(--space-7));
    --margin-right: calc(-1 * var(--space-7));
    --margin-bottom: calc(-1 * var(--space-7));
    --margin-left: calc(-1 * var(--space-7));
  }

  .lg\:-rt-r-m-8 {
    --margin-top: calc(-1 * var(--space-8));
    --margin-right: calc(-1 * var(--space-8));
    --margin-bottom: calc(-1 * var(--space-8));
    --margin-left: calc(-1 * var(--space-8));
  }

  .lg\:-rt-r-m-9 {
    --margin-top: calc(-1 * var(--space-9));
    --margin-right: calc(-1 * var(--space-9));
    --margin-bottom: calc(-1 * var(--space-9));
    --margin-left: calc(-1 * var(--space-9));
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-m, .xl\:rt-r-m-0, .xl\:rt-r-m-1, .xl\:rt-r-m-2, .xl\:rt-r-m-3, .xl\:rt-r-m-4, .xl\:rt-r-m-5, .xl\:rt-r-m-6, .xl\:rt-r-m-7, .xl\:rt-r-m-8, .xl\:rt-r-m-9, .xl\:-rt-r-m-1, .xl\:-rt-r-m-2, .xl\:-rt-r-m-3, .xl\:-rt-r-m-4, .xl\:-rt-r-m-5, .xl\:-rt-r-m-6, .xl\:-rt-r-m-7, .xl\:-rt-r-m-8, .xl\:-rt-r-m-9 {
    margin-top: var(--margin-top-override, var(--margin-top));
    margin-right: var(--margin-right-override, var(--margin-right));
    margin-bottom: var(--margin-bottom-override, var(--margin-bottom));
    margin-left: var(--margin-left-override, var(--margin-left));
  }

  .xl\:rt-r-m {
    --margin-top: var(--m-xl);
    --margin-right: var(--m-xl);
    --margin-bottom: var(--m-xl);
    --margin-left: var(--m-xl);
  }

  .xl\:rt-r-m-0 {
    --margin-top: 0px;
    --margin-right: 0px;
    --margin-bottom: 0px;
    --margin-left: 0px;
  }

  .xl\:rt-r-m-1 {
    --margin-top: var(--space-1);
    --margin-right: var(--space-1);
    --margin-bottom: var(--space-1);
    --margin-left: var(--space-1);
  }

  .xl\:rt-r-m-2 {
    --margin-top: var(--space-2);
    --margin-right: var(--space-2);
    --margin-bottom: var(--space-2);
    --margin-left: var(--space-2);
  }

  .xl\:rt-r-m-3 {
    --margin-top: var(--space-3);
    --margin-right: var(--space-3);
    --margin-bottom: var(--space-3);
    --margin-left: var(--space-3);
  }

  .xl\:rt-r-m-4 {
    --margin-top: var(--space-4);
    --margin-right: var(--space-4);
    --margin-bottom: var(--space-4);
    --margin-left: var(--space-4);
  }

  .xl\:rt-r-m-5 {
    --margin-top: var(--space-5);
    --margin-right: var(--space-5);
    --margin-bottom: var(--space-5);
    --margin-left: var(--space-5);
  }

  .xl\:rt-r-m-6 {
    --margin-top: var(--space-6);
    --margin-right: var(--space-6);
    --margin-bottom: var(--space-6);
    --margin-left: var(--space-6);
  }

  .xl\:rt-r-m-7 {
    --margin-top: var(--space-7);
    --margin-right: var(--space-7);
    --margin-bottom: var(--space-7);
    --margin-left: var(--space-7);
  }

  .xl\:rt-r-m-8 {
    --margin-top: var(--space-8);
    --margin-right: var(--space-8);
    --margin-bottom: var(--space-8);
    --margin-left: var(--space-8);
  }

  .xl\:rt-r-m-9 {
    --margin-top: var(--space-9);
    --margin-right: var(--space-9);
    --margin-bottom: var(--space-9);
    --margin-left: var(--space-9);
  }

  .xl\:-rt-r-m-1 {
    --margin-top: calc(-1 * var(--space-1));
    --margin-right: calc(-1 * var(--space-1));
    --margin-bottom: calc(-1 * var(--space-1));
    --margin-left: calc(-1 * var(--space-1));
  }

  .xl\:-rt-r-m-2 {
    --margin-top: calc(-1 * var(--space-2));
    --margin-right: calc(-1 * var(--space-2));
    --margin-bottom: calc(-1 * var(--space-2));
    --margin-left: calc(-1 * var(--space-2));
  }

  .xl\:-rt-r-m-3 {
    --margin-top: calc(-1 * var(--space-3));
    --margin-right: calc(-1 * var(--space-3));
    --margin-bottom: calc(-1 * var(--space-3));
    --margin-left: calc(-1 * var(--space-3));
  }

  .xl\:-rt-r-m-4 {
    --margin-top: calc(-1 * var(--space-4));
    --margin-right: calc(-1 * var(--space-4));
    --margin-bottom: calc(-1 * var(--space-4));
    --margin-left: calc(-1 * var(--space-4));
  }

  .xl\:-rt-r-m-5 {
    --margin-top: calc(-1 * var(--space-5));
    --margin-right: calc(-1 * var(--space-5));
    --margin-bottom: calc(-1 * var(--space-5));
    --margin-left: calc(-1 * var(--space-5));
  }

  .xl\:-rt-r-m-6 {
    --margin-top: calc(-1 * var(--space-6));
    --margin-right: calc(-1 * var(--space-6));
    --margin-bottom: calc(-1 * var(--space-6));
    --margin-left: calc(-1 * var(--space-6));
  }

  .xl\:-rt-r-m-7 {
    --margin-top: calc(-1 * var(--space-7));
    --margin-right: calc(-1 * var(--space-7));
    --margin-bottom: calc(-1 * var(--space-7));
    --margin-left: calc(-1 * var(--space-7));
  }

  .xl\:-rt-r-m-8 {
    --margin-top: calc(-1 * var(--space-8));
    --margin-right: calc(-1 * var(--space-8));
    --margin-bottom: calc(-1 * var(--space-8));
    --margin-left: calc(-1 * var(--space-8));
  }

  .xl\:-rt-r-m-9 {
    --margin-top: calc(-1 * var(--space-9));
    --margin-right: calc(-1 * var(--space-9));
    --margin-bottom: calc(-1 * var(--space-9));
    --margin-left: calc(-1 * var(--space-9));
  }
}

.rt-r-mx, .rt-r-mx-0, .rt-r-mx-1, .rt-r-mx-2, .rt-r-mx-3, .rt-r-mx-4, .rt-r-mx-5, .rt-r-mx-6, .rt-r-mx-7, .rt-r-mx-8, .rt-r-mx-9, .-rt-r-mx-1, .-rt-r-mx-2, .-rt-r-mx-3, .-rt-r-mx-4, .-rt-r-mx-5, .-rt-r-mx-6, .-rt-r-mx-7, .-rt-r-mx-8, .-rt-r-mx-9 {
  margin-left: var(--margin-left-override, var(--margin-left));
  margin-right: var(--margin-right-override, var(--margin-right));
}

.rt-r-mx {
  --margin-left: var(--ml);
  --margin-right: var(--mr);
}

.rt-r-mx-0 {
  --margin-left: 0px;
  --margin-right: 0px;
}

.rt-r-mx-1 {
  --margin-left: var(--space-1);
  --margin-right: var(--space-1);
}

.rt-r-mx-2 {
  --margin-left: var(--space-2);
  --margin-right: var(--space-2);
}

.rt-r-mx-3 {
  --margin-left: var(--space-3);
  --margin-right: var(--space-3);
}

.rt-r-mx-4 {
  --margin-left: var(--space-4);
  --margin-right: var(--space-4);
}

.rt-r-mx-5 {
  --margin-left: var(--space-5);
  --margin-right: var(--space-5);
}

.rt-r-mx-6 {
  --margin-left: var(--space-6);
  --margin-right: var(--space-6);
}

.rt-r-mx-7 {
  --margin-left: var(--space-7);
  --margin-right: var(--space-7);
}

.rt-r-mx-8 {
  --margin-left: var(--space-8);
  --margin-right: var(--space-8);
}

.rt-r-mx-9 {
  --margin-left: var(--space-9);
  --margin-right: var(--space-9);
}

.-rt-r-mx-1 {
  --margin-left: calc(-1 * var(--space-1));
  --margin-right: calc(-1 * var(--space-1));
}

.-rt-r-mx-2 {
  --margin-left: calc(-1 * var(--space-2));
  --margin-right: calc(-1 * var(--space-2));
}

.-rt-r-mx-3 {
  --margin-left: calc(-1 * var(--space-3));
  --margin-right: calc(-1 * var(--space-3));
}

.-rt-r-mx-4 {
  --margin-left: calc(-1 * var(--space-4));
  --margin-right: calc(-1 * var(--space-4));
}

.-rt-r-mx-5 {
  --margin-left: calc(-1 * var(--space-5));
  --margin-right: calc(-1 * var(--space-5));
}

.-rt-r-mx-6 {
  --margin-left: calc(-1 * var(--space-6));
  --margin-right: calc(-1 * var(--space-6));
}

.-rt-r-mx-7 {
  --margin-left: calc(-1 * var(--space-7));
  --margin-right: calc(-1 * var(--space-7));
}

.-rt-r-mx-8 {
  --margin-left: calc(-1 * var(--space-8));
  --margin-right: calc(-1 * var(--space-8));
}

.-rt-r-mx-9 {
  --margin-left: calc(-1 * var(--space-9));
  --margin-right: calc(-1 * var(--space-9));
}

@media (min-width: 520px) {
  .xs\:rt-r-mx, .xs\:rt-r-mx-0, .xs\:rt-r-mx-1, .xs\:rt-r-mx-2, .xs\:rt-r-mx-3, .xs\:rt-r-mx-4, .xs\:rt-r-mx-5, .xs\:rt-r-mx-6, .xs\:rt-r-mx-7, .xs\:rt-r-mx-8, .xs\:rt-r-mx-9, .xs\:-rt-r-mx-1, .xs\:-rt-r-mx-2, .xs\:-rt-r-mx-3, .xs\:-rt-r-mx-4, .xs\:-rt-r-mx-5, .xs\:-rt-r-mx-6, .xs\:-rt-r-mx-7, .xs\:-rt-r-mx-8, .xs\:-rt-r-mx-9 {
    margin-left: var(--margin-left-override, var(--margin-left));
    margin-right: var(--margin-right-override, var(--margin-right));
  }

  .xs\:rt-r-mx {
    --margin-left: var(--ml-xs);
    --margin-right: var(--mr-xs);
  }

  .xs\:rt-r-mx-0 {
    --margin-left: 0px;
    --margin-right: 0px;
  }

  .xs\:rt-r-mx-1 {
    --margin-left: var(--space-1);
    --margin-right: var(--space-1);
  }

  .xs\:rt-r-mx-2 {
    --margin-left: var(--space-2);
    --margin-right: var(--space-2);
  }

  .xs\:rt-r-mx-3 {
    --margin-left: var(--space-3);
    --margin-right: var(--space-3);
  }

  .xs\:rt-r-mx-4 {
    --margin-left: var(--space-4);
    --margin-right: var(--space-4);
  }

  .xs\:rt-r-mx-5 {
    --margin-left: var(--space-5);
    --margin-right: var(--space-5);
  }

  .xs\:rt-r-mx-6 {
    --margin-left: var(--space-6);
    --margin-right: var(--space-6);
  }

  .xs\:rt-r-mx-7 {
    --margin-left: var(--space-7);
    --margin-right: var(--space-7);
  }

  .xs\:rt-r-mx-8 {
    --margin-left: var(--space-8);
    --margin-right: var(--space-8);
  }

  .xs\:rt-r-mx-9 {
    --margin-left: var(--space-9);
    --margin-right: var(--space-9);
  }

  .xs\:-rt-r-mx-1 {
    --margin-left: calc(-1 * var(--space-1));
    --margin-right: calc(-1 * var(--space-1));
  }

  .xs\:-rt-r-mx-2 {
    --margin-left: calc(-1 * var(--space-2));
    --margin-right: calc(-1 * var(--space-2));
  }

  .xs\:-rt-r-mx-3 {
    --margin-left: calc(-1 * var(--space-3));
    --margin-right: calc(-1 * var(--space-3));
  }

  .xs\:-rt-r-mx-4 {
    --margin-left: calc(-1 * var(--space-4));
    --margin-right: calc(-1 * var(--space-4));
  }

  .xs\:-rt-r-mx-5 {
    --margin-left: calc(-1 * var(--space-5));
    --margin-right: calc(-1 * var(--space-5));
  }

  .xs\:-rt-r-mx-6 {
    --margin-left: calc(-1 * var(--space-6));
    --margin-right: calc(-1 * var(--space-6));
  }

  .xs\:-rt-r-mx-7 {
    --margin-left: calc(-1 * var(--space-7));
    --margin-right: calc(-1 * var(--space-7));
  }

  .xs\:-rt-r-mx-8 {
    --margin-left: calc(-1 * var(--space-8));
    --margin-right: calc(-1 * var(--space-8));
  }

  .xs\:-rt-r-mx-9 {
    --margin-left: calc(-1 * var(--space-9));
    --margin-right: calc(-1 * var(--space-9));
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-mx, .sm\:rt-r-mx-0, .sm\:rt-r-mx-1, .sm\:rt-r-mx-2, .sm\:rt-r-mx-3, .sm\:rt-r-mx-4, .sm\:rt-r-mx-5, .sm\:rt-r-mx-6, .sm\:rt-r-mx-7, .sm\:rt-r-mx-8, .sm\:rt-r-mx-9, .sm\:-rt-r-mx-1, .sm\:-rt-r-mx-2, .sm\:-rt-r-mx-3, .sm\:-rt-r-mx-4, .sm\:-rt-r-mx-5, .sm\:-rt-r-mx-6, .sm\:-rt-r-mx-7, .sm\:-rt-r-mx-8, .sm\:-rt-r-mx-9 {
    margin-left: var(--margin-left-override, var(--margin-left));
    margin-right: var(--margin-right-override, var(--margin-right));
  }

  .sm\:rt-r-mx {
    --margin-left: var(--ml-md);
    --margin-right: var(--mr-md);
  }

  .sm\:rt-r-mx-0 {
    --margin-left: 0px;
    --margin-right: 0px;
  }

  .sm\:rt-r-mx-1 {
    --margin-left: var(--space-1);
    --margin-right: var(--space-1);
  }

  .sm\:rt-r-mx-2 {
    --margin-left: var(--space-2);
    --margin-right: var(--space-2);
  }

  .sm\:rt-r-mx-3 {
    --margin-left: var(--space-3);
    --margin-right: var(--space-3);
  }

  .sm\:rt-r-mx-4 {
    --margin-left: var(--space-4);
    --margin-right: var(--space-4);
  }

  .sm\:rt-r-mx-5 {
    --margin-left: var(--space-5);
    --margin-right: var(--space-5);
  }

  .sm\:rt-r-mx-6 {
    --margin-left: var(--space-6);
    --margin-right: var(--space-6);
  }

  .sm\:rt-r-mx-7 {
    --margin-left: var(--space-7);
    --margin-right: var(--space-7);
  }

  .sm\:rt-r-mx-8 {
    --margin-left: var(--space-8);
    --margin-right: var(--space-8);
  }

  .sm\:rt-r-mx-9 {
    --margin-left: var(--space-9);
    --margin-right: var(--space-9);
  }

  .sm\:-rt-r-mx-1 {
    --margin-left: calc(-1 * var(--space-1));
    --margin-right: calc(-1 * var(--space-1));
  }

  .sm\:-rt-r-mx-2 {
    --margin-left: calc(-1 * var(--space-2));
    --margin-right: calc(-1 * var(--space-2));
  }

  .sm\:-rt-r-mx-3 {
    --margin-left: calc(-1 * var(--space-3));
    --margin-right: calc(-1 * var(--space-3));
  }

  .sm\:-rt-r-mx-4 {
    --margin-left: calc(-1 * var(--space-4));
    --margin-right: calc(-1 * var(--space-4));
  }

  .sm\:-rt-r-mx-5 {
    --margin-left: calc(-1 * var(--space-5));
    --margin-right: calc(-1 * var(--space-5));
  }

  .sm\:-rt-r-mx-6 {
    --margin-left: calc(-1 * var(--space-6));
    --margin-right: calc(-1 * var(--space-6));
  }

  .sm\:-rt-r-mx-7 {
    --margin-left: calc(-1 * var(--space-7));
    --margin-right: calc(-1 * var(--space-7));
  }

  .sm\:-rt-r-mx-8 {
    --margin-left: calc(-1 * var(--space-8));
    --margin-right: calc(-1 * var(--space-8));
  }

  .sm\:-rt-r-mx-9 {
    --margin-left: calc(-1 * var(--space-9));
    --margin-right: calc(-1 * var(--space-9));
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-mx, .md\:rt-r-mx-0, .md\:rt-r-mx-1, .md\:rt-r-mx-2, .md\:rt-r-mx-3, .md\:rt-r-mx-4, .md\:rt-r-mx-5, .md\:rt-r-mx-6, .md\:rt-r-mx-7, .md\:rt-r-mx-8, .md\:rt-r-mx-9, .md\:-rt-r-mx-1, .md\:-rt-r-mx-2, .md\:-rt-r-mx-3, .md\:-rt-r-mx-4, .md\:-rt-r-mx-5, .md\:-rt-r-mx-6, .md\:-rt-r-mx-7, .md\:-rt-r-mx-8, .md\:-rt-r-mx-9 {
    margin-left: var(--margin-left-override, var(--margin-left));
    margin-right: var(--margin-right-override, var(--margin-right));
  }

  .md\:rt-r-mx {
    --margin-left: var(--ml-md);
    --margin-right: var(--mr-md);
  }

  .md\:rt-r-mx-0 {
    --margin-left: 0px;
    --margin-right: 0px;
  }

  .md\:rt-r-mx-1 {
    --margin-left: var(--space-1);
    --margin-right: var(--space-1);
  }

  .md\:rt-r-mx-2 {
    --margin-left: var(--space-2);
    --margin-right: var(--space-2);
  }

  .md\:rt-r-mx-3 {
    --margin-left: var(--space-3);
    --margin-right: var(--space-3);
  }

  .md\:rt-r-mx-4 {
    --margin-left: var(--space-4);
    --margin-right: var(--space-4);
  }

  .md\:rt-r-mx-5 {
    --margin-left: var(--space-5);
    --margin-right: var(--space-5);
  }

  .md\:rt-r-mx-6 {
    --margin-left: var(--space-6);
    --margin-right: var(--space-6);
  }

  .md\:rt-r-mx-7 {
    --margin-left: var(--space-7);
    --margin-right: var(--space-7);
  }

  .md\:rt-r-mx-8 {
    --margin-left: var(--space-8);
    --margin-right: var(--space-8);
  }

  .md\:rt-r-mx-9 {
    --margin-left: var(--space-9);
    --margin-right: var(--space-9);
  }

  .md\:-rt-r-mx-1 {
    --margin-left: calc(-1 * var(--space-1));
    --margin-right: calc(-1 * var(--space-1));
  }

  .md\:-rt-r-mx-2 {
    --margin-left: calc(-1 * var(--space-2));
    --margin-right: calc(-1 * var(--space-2));
  }

  .md\:-rt-r-mx-3 {
    --margin-left: calc(-1 * var(--space-3));
    --margin-right: calc(-1 * var(--space-3));
  }

  .md\:-rt-r-mx-4 {
    --margin-left: calc(-1 * var(--space-4));
    --margin-right: calc(-1 * var(--space-4));
  }

  .md\:-rt-r-mx-5 {
    --margin-left: calc(-1 * var(--space-5));
    --margin-right: calc(-1 * var(--space-5));
  }

  .md\:-rt-r-mx-6 {
    --margin-left: calc(-1 * var(--space-6));
    --margin-right: calc(-1 * var(--space-6));
  }

  .md\:-rt-r-mx-7 {
    --margin-left: calc(-1 * var(--space-7));
    --margin-right: calc(-1 * var(--space-7));
  }

  .md\:-rt-r-mx-8 {
    --margin-left: calc(-1 * var(--space-8));
    --margin-right: calc(-1 * var(--space-8));
  }

  .md\:-rt-r-mx-9 {
    --margin-left: calc(-1 * var(--space-9));
    --margin-right: calc(-1 * var(--space-9));
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-mx, .lg\:rt-r-mx-0, .lg\:rt-r-mx-1, .lg\:rt-r-mx-2, .lg\:rt-r-mx-3, .lg\:rt-r-mx-4, .lg\:rt-r-mx-5, .lg\:rt-r-mx-6, .lg\:rt-r-mx-7, .lg\:rt-r-mx-8, .lg\:rt-r-mx-9, .lg\:-rt-r-mx-1, .lg\:-rt-r-mx-2, .lg\:-rt-r-mx-3, .lg\:-rt-r-mx-4, .lg\:-rt-r-mx-5, .lg\:-rt-r-mx-6, .lg\:-rt-r-mx-7, .lg\:-rt-r-mx-8, .lg\:-rt-r-mx-9 {
    margin-left: var(--margin-left-override, var(--margin-left));
    margin-right: var(--margin-right-override, var(--margin-right));
  }

  .lg\:rt-r-mx {
    --margin-left: var(--ml-lg);
    --margin-right: var(--mr-lg);
  }

  .lg\:rt-r-mx-0 {
    --margin-left: 0px;
    --margin-right: 0px;
  }

  .lg\:rt-r-mx-1 {
    --margin-left: var(--space-1);
    --margin-right: var(--space-1);
  }

  .lg\:rt-r-mx-2 {
    --margin-left: var(--space-2);
    --margin-right: var(--space-2);
  }

  .lg\:rt-r-mx-3 {
    --margin-left: var(--space-3);
    --margin-right: var(--space-3);
  }

  .lg\:rt-r-mx-4 {
    --margin-left: var(--space-4);
    --margin-right: var(--space-4);
  }

  .lg\:rt-r-mx-5 {
    --margin-left: var(--space-5);
    --margin-right: var(--space-5);
  }

  .lg\:rt-r-mx-6 {
    --margin-left: var(--space-6);
    --margin-right: var(--space-6);
  }

  .lg\:rt-r-mx-7 {
    --margin-left: var(--space-7);
    --margin-right: var(--space-7);
  }

  .lg\:rt-r-mx-8 {
    --margin-left: var(--space-8);
    --margin-right: var(--space-8);
  }

  .lg\:rt-r-mx-9 {
    --margin-left: var(--space-9);
    --margin-right: var(--space-9);
  }

  .lg\:-rt-r-mx-1 {
    --margin-left: calc(-1 * var(--space-1));
    --margin-right: calc(-1 * var(--space-1));
  }

  .lg\:-rt-r-mx-2 {
    --margin-left: calc(-1 * var(--space-2));
    --margin-right: calc(-1 * var(--space-2));
  }

  .lg\:-rt-r-mx-3 {
    --margin-left: calc(-1 * var(--space-3));
    --margin-right: calc(-1 * var(--space-3));
  }

  .lg\:-rt-r-mx-4 {
    --margin-left: calc(-1 * var(--space-4));
    --margin-right: calc(-1 * var(--space-4));
  }

  .lg\:-rt-r-mx-5 {
    --margin-left: calc(-1 * var(--space-5));
    --margin-right: calc(-1 * var(--space-5));
  }

  .lg\:-rt-r-mx-6 {
    --margin-left: calc(-1 * var(--space-6));
    --margin-right: calc(-1 * var(--space-6));
  }

  .lg\:-rt-r-mx-7 {
    --margin-left: calc(-1 * var(--space-7));
    --margin-right: calc(-1 * var(--space-7));
  }

  .lg\:-rt-r-mx-8 {
    --margin-left: calc(-1 * var(--space-8));
    --margin-right: calc(-1 * var(--space-8));
  }

  .lg\:-rt-r-mx-9 {
    --margin-left: calc(-1 * var(--space-9));
    --margin-right: calc(-1 * var(--space-9));
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-mx, .xl\:rt-r-mx-0, .xl\:rt-r-mx-1, .xl\:rt-r-mx-2, .xl\:rt-r-mx-3, .xl\:rt-r-mx-4, .xl\:rt-r-mx-5, .xl\:rt-r-mx-6, .xl\:rt-r-mx-7, .xl\:rt-r-mx-8, .xl\:rt-r-mx-9, .xl\:-rt-r-mx-1, .xl\:-rt-r-mx-2, .xl\:-rt-r-mx-3, .xl\:-rt-r-mx-4, .xl\:-rt-r-mx-5, .xl\:-rt-r-mx-6, .xl\:-rt-r-mx-7, .xl\:-rt-r-mx-8, .xl\:-rt-r-mx-9 {
    margin-left: var(--margin-left-override, var(--margin-left));
    margin-right: var(--margin-right-override, var(--margin-right));
  }

  .xl\:rt-r-mx {
    --margin-left: var(--ml-xl);
    --margin-right: var(--mr-xl);
  }

  .xl\:rt-r-mx-0 {
    --margin-left: 0px;
    --margin-right: 0px;
  }

  .xl\:rt-r-mx-1 {
    --margin-left: var(--space-1);
    --margin-right: var(--space-1);
  }

  .xl\:rt-r-mx-2 {
    --margin-left: var(--space-2);
    --margin-right: var(--space-2);
  }

  .xl\:rt-r-mx-3 {
    --margin-left: var(--space-3);
    --margin-right: var(--space-3);
  }

  .xl\:rt-r-mx-4 {
    --margin-left: var(--space-4);
    --margin-right: var(--space-4);
  }

  .xl\:rt-r-mx-5 {
    --margin-left: var(--space-5);
    --margin-right: var(--space-5);
  }

  .xl\:rt-r-mx-6 {
    --margin-left: var(--space-6);
    --margin-right: var(--space-6);
  }

  .xl\:rt-r-mx-7 {
    --margin-left: var(--space-7);
    --margin-right: var(--space-7);
  }

  .xl\:rt-r-mx-8 {
    --margin-left: var(--space-8);
    --margin-right: var(--space-8);
  }

  .xl\:rt-r-mx-9 {
    --margin-left: var(--space-9);
    --margin-right: var(--space-9);
  }

  .xl\:-rt-r-mx-1 {
    --margin-left: calc(-1 * var(--space-1));
    --margin-right: calc(-1 * var(--space-1));
  }

  .xl\:-rt-r-mx-2 {
    --margin-left: calc(-1 * var(--space-2));
    --margin-right: calc(-1 * var(--space-2));
  }

  .xl\:-rt-r-mx-3 {
    --margin-left: calc(-1 * var(--space-3));
    --margin-right: calc(-1 * var(--space-3));
  }

  .xl\:-rt-r-mx-4 {
    --margin-left: calc(-1 * var(--space-4));
    --margin-right: calc(-1 * var(--space-4));
  }

  .xl\:-rt-r-mx-5 {
    --margin-left: calc(-1 * var(--space-5));
    --margin-right: calc(-1 * var(--space-5));
  }

  .xl\:-rt-r-mx-6 {
    --margin-left: calc(-1 * var(--space-6));
    --margin-right: calc(-1 * var(--space-6));
  }

  .xl\:-rt-r-mx-7 {
    --margin-left: calc(-1 * var(--space-7));
    --margin-right: calc(-1 * var(--space-7));
  }

  .xl\:-rt-r-mx-8 {
    --margin-left: calc(-1 * var(--space-8));
    --margin-right: calc(-1 * var(--space-8));
  }

  .xl\:-rt-r-mx-9 {
    --margin-left: calc(-1 * var(--space-9));
    --margin-right: calc(-1 * var(--space-9));
  }
}

.rt-r-my, .rt-r-my-0, .rt-r-my-1, .rt-r-my-2, .rt-r-my-3, .rt-r-my-4, .rt-r-my-5, .rt-r-my-6, .rt-r-my-7, .rt-r-my-8, .rt-r-my-9, .-rt-r-my-1, .-rt-r-my-2, .-rt-r-my-3, .-rt-r-my-4, .-rt-r-my-5, .-rt-r-my-6, .-rt-r-my-7, .-rt-r-my-8, .-rt-r-my-9 {
  margin-top: var(--margin-top-override, var(--margin-top));
  margin-bottom: var(--margin-bottom-override, var(--margin-bottom));
}

.rt-r-my {
  --margin-top: var(--mt);
  --margin-bottom: var(--mb);
}

.rt-r-my-0 {
  --margin-top: 0px;
  --margin-bottom: 0px;
}

.rt-r-my-1 {
  --margin-top: var(--space-1);
  --margin-bottom: var(--space-1);
}

.rt-r-my-2 {
  --margin-top: var(--space-2);
  --margin-bottom: var(--space-2);
}

.rt-r-my-3 {
  --margin-top: var(--space-3);
  --margin-bottom: var(--space-3);
}

.rt-r-my-4 {
  --margin-top: var(--space-4);
  --margin-bottom: var(--space-4);
}

.rt-r-my-5 {
  --margin-top: var(--space-5);
  --margin-bottom: var(--space-5);
}

.rt-r-my-6 {
  --margin-top: var(--space-6);
  --margin-bottom: var(--space-6);
}

.rt-r-my-7 {
  --margin-top: var(--space-7);
  --margin-bottom: var(--space-7);
}

.rt-r-my-8 {
  --margin-top: var(--space-8);
  --margin-bottom: var(--space-8);
}

.rt-r-my-9 {
  --margin-top: var(--space-9);
  --margin-bottom: var(--space-9);
}

.-rt-r-my-1 {
  --margin-top: calc(-1 * var(--space-1));
  --margin-bottom: calc(-1 * var(--space-1));
}

.-rt-r-my-2 {
  --margin-top: calc(-1 * var(--space-2));
  --margin-bottom: calc(-1 * var(--space-2));
}

.-rt-r-my-3 {
  --margin-top: calc(-1 * var(--space-3));
  --margin-bottom: calc(-1 * var(--space-3));
}

.-rt-r-my-4 {
  --margin-top: calc(-1 * var(--space-4));
  --margin-bottom: calc(-1 * var(--space-4));
}

.-rt-r-my-5 {
  --margin-top: calc(-1 * var(--space-5));
  --margin-bottom: calc(-1 * var(--space-5));
}

.-rt-r-my-6 {
  --margin-top: calc(-1 * var(--space-6));
  --margin-bottom: calc(-1 * var(--space-6));
}

.-rt-r-my-7 {
  --margin-top: calc(-1 * var(--space-7));
  --margin-bottom: calc(-1 * var(--space-7));
}

.-rt-r-my-8 {
  --margin-top: calc(-1 * var(--space-8));
  --margin-bottom: calc(-1 * var(--space-8));
}

.-rt-r-my-9 {
  --margin-top: calc(-1 * var(--space-9));
  --margin-bottom: calc(-1 * var(--space-9));
}

@media (min-width: 520px) {
  .xs\:rt-r-my, .xs\:rt-r-my-0, .xs\:rt-r-my-1, .xs\:rt-r-my-2, .xs\:rt-r-my-3, .xs\:rt-r-my-4, .xs\:rt-r-my-5, .xs\:rt-r-my-6, .xs\:rt-r-my-7, .xs\:rt-r-my-8, .xs\:rt-r-my-9, .xs\:-rt-r-my-1, .xs\:-rt-r-my-2, .xs\:-rt-r-my-3, .xs\:-rt-r-my-4, .xs\:-rt-r-my-5, .xs\:-rt-r-my-6, .xs\:-rt-r-my-7, .xs\:-rt-r-my-8, .xs\:-rt-r-my-9 {
    margin-top: var(--margin-top-override, var(--margin-top));
    margin-bottom: var(--margin-bottom-override, var(--margin-bottom));
  }

  .xs\:rt-r-my {
    --margin-top: var(--mt-xs);
    --margin-bottom: var(--mb-xs);
  }

  .xs\:rt-r-my-0 {
    --margin-top: 0px;
    --margin-bottom: 0px;
  }

  .xs\:rt-r-my-1 {
    --margin-top: var(--space-1);
    --margin-bottom: var(--space-1);
  }

  .xs\:rt-r-my-2 {
    --margin-top: var(--space-2);
    --margin-bottom: var(--space-2);
  }

  .xs\:rt-r-my-3 {
    --margin-top: var(--space-3);
    --margin-bottom: var(--space-3);
  }

  .xs\:rt-r-my-4 {
    --margin-top: var(--space-4);
    --margin-bottom: var(--space-4);
  }

  .xs\:rt-r-my-5 {
    --margin-top: var(--space-5);
    --margin-bottom: var(--space-5);
  }

  .xs\:rt-r-my-6 {
    --margin-top: var(--space-6);
    --margin-bottom: var(--space-6);
  }

  .xs\:rt-r-my-7 {
    --margin-top: var(--space-7);
    --margin-bottom: var(--space-7);
  }

  .xs\:rt-r-my-8 {
    --margin-top: var(--space-8);
    --margin-bottom: var(--space-8);
  }

  .xs\:rt-r-my-9 {
    --margin-top: var(--space-9);
    --margin-bottom: var(--space-9);
  }

  .xs\:-rt-r-my-1 {
    --margin-top: calc(-1 * var(--space-1));
    --margin-bottom: calc(-1 * var(--space-1));
  }

  .xs\:-rt-r-my-2 {
    --margin-top: calc(-1 * var(--space-2));
    --margin-bottom: calc(-1 * var(--space-2));
  }

  .xs\:-rt-r-my-3 {
    --margin-top: calc(-1 * var(--space-3));
    --margin-bottom: calc(-1 * var(--space-3));
  }

  .xs\:-rt-r-my-4 {
    --margin-top: calc(-1 * var(--space-4));
    --margin-bottom: calc(-1 * var(--space-4));
  }

  .xs\:-rt-r-my-5 {
    --margin-top: calc(-1 * var(--space-5));
    --margin-bottom: calc(-1 * var(--space-5));
  }

  .xs\:-rt-r-my-6 {
    --margin-top: calc(-1 * var(--space-6));
    --margin-bottom: calc(-1 * var(--space-6));
  }

  .xs\:-rt-r-my-7 {
    --margin-top: calc(-1 * var(--space-7));
    --margin-bottom: calc(-1 * var(--space-7));
  }

  .xs\:-rt-r-my-8 {
    --margin-top: calc(-1 * var(--space-8));
    --margin-bottom: calc(-1 * var(--space-8));
  }

  .xs\:-rt-r-my-9 {
    --margin-top: calc(-1 * var(--space-9));
    --margin-bottom: calc(-1 * var(--space-9));
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-my, .sm\:rt-r-my-0, .sm\:rt-r-my-1, .sm\:rt-r-my-2, .sm\:rt-r-my-3, .sm\:rt-r-my-4, .sm\:rt-r-my-5, .sm\:rt-r-my-6, .sm\:rt-r-my-7, .sm\:rt-r-my-8, .sm\:rt-r-my-9, .sm\:-rt-r-my-1, .sm\:-rt-r-my-2, .sm\:-rt-r-my-3, .sm\:-rt-r-my-4, .sm\:-rt-r-my-5, .sm\:-rt-r-my-6, .sm\:-rt-r-my-7, .sm\:-rt-r-my-8, .sm\:-rt-r-my-9 {
    margin-top: var(--margin-top-override, var(--margin-top));
    margin-bottom: var(--margin-bottom-override, var(--margin-bottom));
  }

  .sm\:rt-r-my {
    --margin-top: var(--mt-sm);
    --margin-bottom: var(--mb-sm);
  }

  .sm\:rt-r-my-0 {
    --margin-top: 0px;
    --margin-bottom: 0px;
  }

  .sm\:rt-r-my-1 {
    --margin-top: var(--space-1);
    --margin-bottom: var(--space-1);
  }

  .sm\:rt-r-my-2 {
    --margin-top: var(--space-2);
    --margin-bottom: var(--space-2);
  }

  .sm\:rt-r-my-3 {
    --margin-top: var(--space-3);
    --margin-bottom: var(--space-3);
  }

  .sm\:rt-r-my-4 {
    --margin-top: var(--space-4);
    --margin-bottom: var(--space-4);
  }

  .sm\:rt-r-my-5 {
    --margin-top: var(--space-5);
    --margin-bottom: var(--space-5);
  }

  .sm\:rt-r-my-6 {
    --margin-top: var(--space-6);
    --margin-bottom: var(--space-6);
  }

  .sm\:rt-r-my-7 {
    --margin-top: var(--space-7);
    --margin-bottom: var(--space-7);
  }

  .sm\:rt-r-my-8 {
    --margin-top: var(--space-8);
    --margin-bottom: var(--space-8);
  }

  .sm\:rt-r-my-9 {
    --margin-top: var(--space-9);
    --margin-bottom: var(--space-9);
  }

  .sm\:-rt-r-my-1 {
    --margin-top: calc(-1 * var(--space-1));
    --margin-bottom: calc(-1 * var(--space-1));
  }

  .sm\:-rt-r-my-2 {
    --margin-top: calc(-1 * var(--space-2));
    --margin-bottom: calc(-1 * var(--space-2));
  }

  .sm\:-rt-r-my-3 {
    --margin-top: calc(-1 * var(--space-3));
    --margin-bottom: calc(-1 * var(--space-3));
  }

  .sm\:-rt-r-my-4 {
    --margin-top: calc(-1 * var(--space-4));
    --margin-bottom: calc(-1 * var(--space-4));
  }

  .sm\:-rt-r-my-5 {
    --margin-top: calc(-1 * var(--space-5));
    --margin-bottom: calc(-1 * var(--space-5));
  }

  .sm\:-rt-r-my-6 {
    --margin-top: calc(-1 * var(--space-6));
    --margin-bottom: calc(-1 * var(--space-6));
  }

  .sm\:-rt-r-my-7 {
    --margin-top: calc(-1 * var(--space-7));
    --margin-bottom: calc(-1 * var(--space-7));
  }

  .sm\:-rt-r-my-8 {
    --margin-top: calc(-1 * var(--space-8));
    --margin-bottom: calc(-1 * var(--space-8));
  }

  .sm\:-rt-r-my-9 {
    --margin-top: calc(-1 * var(--space-9));
    --margin-bottom: calc(-1 * var(--space-9));
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-my, .md\:rt-r-my-0, .md\:rt-r-my-1, .md\:rt-r-my-2, .md\:rt-r-my-3, .md\:rt-r-my-4, .md\:rt-r-my-5, .md\:rt-r-my-6, .md\:rt-r-my-7, .md\:rt-r-my-8, .md\:rt-r-my-9, .md\:-rt-r-my-1, .md\:-rt-r-my-2, .md\:-rt-r-my-3, .md\:-rt-r-my-4, .md\:-rt-r-my-5, .md\:-rt-r-my-6, .md\:-rt-r-my-7, .md\:-rt-r-my-8, .md\:-rt-r-my-9 {
    margin-top: var(--margin-top-override, var(--margin-top));
    margin-bottom: var(--margin-bottom-override, var(--margin-bottom));
  }

  .md\:rt-r-my {
    --margin-top: var(--mt-md);
    --margin-bottom: var(--mb-md);
  }

  .md\:rt-r-my-0 {
    --margin-top: 0px;
    --margin-bottom: 0px;
  }

  .md\:rt-r-my-1 {
    --margin-top: var(--space-1);
    --margin-bottom: var(--space-1);
  }

  .md\:rt-r-my-2 {
    --margin-top: var(--space-2);
    --margin-bottom: var(--space-2);
  }

  .md\:rt-r-my-3 {
    --margin-top: var(--space-3);
    --margin-bottom: var(--space-3);
  }

  .md\:rt-r-my-4 {
    --margin-top: var(--space-4);
    --margin-bottom: var(--space-4);
  }

  .md\:rt-r-my-5 {
    --margin-top: var(--space-5);
    --margin-bottom: var(--space-5);
  }

  .md\:rt-r-my-6 {
    --margin-top: var(--space-6);
    --margin-bottom: var(--space-6);
  }

  .md\:rt-r-my-7 {
    --margin-top: var(--space-7);
    --margin-bottom: var(--space-7);
  }

  .md\:rt-r-my-8 {
    --margin-top: var(--space-8);
    --margin-bottom: var(--space-8);
  }

  .md\:rt-r-my-9 {
    --margin-top: var(--space-9);
    --margin-bottom: var(--space-9);
  }

  .md\:-rt-r-my-1 {
    --margin-top: calc(-1 * var(--space-1));
    --margin-bottom: calc(-1 * var(--space-1));
  }

  .md\:-rt-r-my-2 {
    --margin-top: calc(-1 * var(--space-2));
    --margin-bottom: calc(-1 * var(--space-2));
  }

  .md\:-rt-r-my-3 {
    --margin-top: calc(-1 * var(--space-3));
    --margin-bottom: calc(-1 * var(--space-3));
  }

  .md\:-rt-r-my-4 {
    --margin-top: calc(-1 * var(--space-4));
    --margin-bottom: calc(-1 * var(--space-4));
  }

  .md\:-rt-r-my-5 {
    --margin-top: calc(-1 * var(--space-5));
    --margin-bottom: calc(-1 * var(--space-5));
  }

  .md\:-rt-r-my-6 {
    --margin-top: calc(-1 * var(--space-6));
    --margin-bottom: calc(-1 * var(--space-6));
  }

  .md\:-rt-r-my-7 {
    --margin-top: calc(-1 * var(--space-7));
    --margin-bottom: calc(-1 * var(--space-7));
  }

  .md\:-rt-r-my-8 {
    --margin-top: calc(-1 * var(--space-8));
    --margin-bottom: calc(-1 * var(--space-8));
  }

  .md\:-rt-r-my-9 {
    --margin-top: calc(-1 * var(--space-9));
    --margin-bottom: calc(-1 * var(--space-9));
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-my, .lg\:rt-r-my-0, .lg\:rt-r-my-1, .lg\:rt-r-my-2, .lg\:rt-r-my-3, .lg\:rt-r-my-4, .lg\:rt-r-my-5, .lg\:rt-r-my-6, .lg\:rt-r-my-7, .lg\:rt-r-my-8, .lg\:rt-r-my-9, .lg\:-rt-r-my-1, .lg\:-rt-r-my-2, .lg\:-rt-r-my-3, .lg\:-rt-r-my-4, .lg\:-rt-r-my-5, .lg\:-rt-r-my-6, .lg\:-rt-r-my-7, .lg\:-rt-r-my-8, .lg\:-rt-r-my-9 {
    margin-top: var(--margin-top-override, var(--margin-top));
    margin-bottom: var(--margin-bottom-override, var(--margin-bottom));
  }

  .lg\:rt-r-my {
    --margin-top: var(--mt-lg);
    --margin-bottom: var(--mb-lg);
  }

  .lg\:rt-r-my-0 {
    --margin-top: 0px;
    --margin-bottom: 0px;
  }

  .lg\:rt-r-my-1 {
    --margin-top: var(--space-1);
    --margin-bottom: var(--space-1);
  }

  .lg\:rt-r-my-2 {
    --margin-top: var(--space-2);
    --margin-bottom: var(--space-2);
  }

  .lg\:rt-r-my-3 {
    --margin-top: var(--space-3);
    --margin-bottom: var(--space-3);
  }

  .lg\:rt-r-my-4 {
    --margin-top: var(--space-4);
    --margin-bottom: var(--space-4);
  }

  .lg\:rt-r-my-5 {
    --margin-top: var(--space-5);
    --margin-bottom: var(--space-5);
  }

  .lg\:rt-r-my-6 {
    --margin-top: var(--space-6);
    --margin-bottom: var(--space-6);
  }

  .lg\:rt-r-my-7 {
    --margin-top: var(--space-7);
    --margin-bottom: var(--space-7);
  }

  .lg\:rt-r-my-8 {
    --margin-top: var(--space-8);
    --margin-bottom: var(--space-8);
  }

  .lg\:rt-r-my-9 {
    --margin-top: var(--space-9);
    --margin-bottom: var(--space-9);
  }

  .lg\:-rt-r-my-1 {
    --margin-top: calc(-1 * var(--space-1));
    --margin-bottom: calc(-1 * var(--space-1));
  }

  .lg\:-rt-r-my-2 {
    --margin-top: calc(-1 * var(--space-2));
    --margin-bottom: calc(-1 * var(--space-2));
  }

  .lg\:-rt-r-my-3 {
    --margin-top: calc(-1 * var(--space-3));
    --margin-bottom: calc(-1 * var(--space-3));
  }

  .lg\:-rt-r-my-4 {
    --margin-top: calc(-1 * var(--space-4));
    --margin-bottom: calc(-1 * var(--space-4));
  }

  .lg\:-rt-r-my-5 {
    --margin-top: calc(-1 * var(--space-5));
    --margin-bottom: calc(-1 * var(--space-5));
  }

  .lg\:-rt-r-my-6 {
    --margin-top: calc(-1 * var(--space-6));
    --margin-bottom: calc(-1 * var(--space-6));
  }

  .lg\:-rt-r-my-7 {
    --margin-top: calc(-1 * var(--space-7));
    --margin-bottom: calc(-1 * var(--space-7));
  }

  .lg\:-rt-r-my-8 {
    --margin-top: calc(-1 * var(--space-8));
    --margin-bottom: calc(-1 * var(--space-8));
  }

  .lg\:-rt-r-my-9 {
    --margin-top: calc(-1 * var(--space-9));
    --margin-bottom: calc(-1 * var(--space-9));
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-my, .xl\:rt-r-my-0, .xl\:rt-r-my-1, .xl\:rt-r-my-2, .xl\:rt-r-my-3, .xl\:rt-r-my-4, .xl\:rt-r-my-5, .xl\:rt-r-my-6, .xl\:rt-r-my-7, .xl\:rt-r-my-8, .xl\:rt-r-my-9, .xl\:-rt-r-my-1, .xl\:-rt-r-my-2, .xl\:-rt-r-my-3, .xl\:-rt-r-my-4, .xl\:-rt-r-my-5, .xl\:-rt-r-my-6, .xl\:-rt-r-my-7, .xl\:-rt-r-my-8, .xl\:-rt-r-my-9 {
    margin-top: var(--margin-top-override, var(--margin-top));
    margin-bottom: var(--margin-bottom-override, var(--margin-bottom));
  }

  .xl\:rt-r-my {
    --margin-top: var(--mt-xl);
    --margin-bottom: var(--mb-xl);
  }

  .xl\:rt-r-my-0 {
    --margin-top: 0px;
    --margin-bottom: 0px;
  }

  .xl\:rt-r-my-1 {
    --margin-top: var(--space-1);
    --margin-bottom: var(--space-1);
  }

  .xl\:rt-r-my-2 {
    --margin-top: var(--space-2);
    --margin-bottom: var(--space-2);
  }

  .xl\:rt-r-my-3 {
    --margin-top: var(--space-3);
    --margin-bottom: var(--space-3);
  }

  .xl\:rt-r-my-4 {
    --margin-top: var(--space-4);
    --margin-bottom: var(--space-4);
  }

  .xl\:rt-r-my-5 {
    --margin-top: var(--space-5);
    --margin-bottom: var(--space-5);
  }

  .xl\:rt-r-my-6 {
    --margin-top: var(--space-6);
    --margin-bottom: var(--space-6);
  }

  .xl\:rt-r-my-7 {
    --margin-top: var(--space-7);
    --margin-bottom: var(--space-7);
  }

  .xl\:rt-r-my-8 {
    --margin-top: var(--space-8);
    --margin-bottom: var(--space-8);
  }

  .xl\:rt-r-my-9 {
    --margin-top: var(--space-9);
    --margin-bottom: var(--space-9);
  }

  .xl\:-rt-r-my-1 {
    --margin-top: calc(-1 * var(--space-1));
    --margin-bottom: calc(-1 * var(--space-1));
  }

  .xl\:-rt-r-my-2 {
    --margin-top: calc(-1 * var(--space-2));
    --margin-bottom: calc(-1 * var(--space-2));
  }

  .xl\:-rt-r-my-3 {
    --margin-top: calc(-1 * var(--space-3));
    --margin-bottom: calc(-1 * var(--space-3));
  }

  .xl\:-rt-r-my-4 {
    --margin-top: calc(-1 * var(--space-4));
    --margin-bottom: calc(-1 * var(--space-4));
  }

  .xl\:-rt-r-my-5 {
    --margin-top: calc(-1 * var(--space-5));
    --margin-bottom: calc(-1 * var(--space-5));
  }

  .xl\:-rt-r-my-6 {
    --margin-top: calc(-1 * var(--space-6));
    --margin-bottom: calc(-1 * var(--space-6));
  }

  .xl\:-rt-r-my-7 {
    --margin-top: calc(-1 * var(--space-7));
    --margin-bottom: calc(-1 * var(--space-7));
  }

  .xl\:-rt-r-my-8 {
    --margin-top: calc(-1 * var(--space-8));
    --margin-bottom: calc(-1 * var(--space-8));
  }

  .xl\:-rt-r-my-9 {
    --margin-top: calc(-1 * var(--space-9));
    --margin-bottom: calc(-1 * var(--space-9));
  }
}

.rt-r-mt, .rt-r-mt-0, .rt-r-mt-1, .rt-r-mt-2, .rt-r-mt-3, .rt-r-mt-4, .rt-r-mt-5, .rt-r-mt-6, .rt-r-mt-7, .rt-r-mt-8, .rt-r-mt-9, .-rt-r-mt-1, .-rt-r-mt-2, .-rt-r-mt-3, .-rt-r-mt-4, .-rt-r-mt-5, .-rt-r-mt-6, .-rt-r-mt-7, .-rt-r-mt-8, .-rt-r-mt-9 {
  margin-top: var(--margin-top-override, var(--margin-top));
}

.rt-r-mt {
  --margin-top: var(--mt);
}

.rt-r-mt-0 {
  --margin-top: 0px;
}

.rt-r-mt-1 {
  --margin-top: var(--space-1);
}

.rt-r-mt-2 {
  --margin-top: var(--space-2);
}

.rt-r-mt-3 {
  --margin-top: var(--space-3);
}

.rt-r-mt-4 {
  --margin-top: var(--space-4);
}

.rt-r-mt-5 {
  --margin-top: var(--space-5);
}

.rt-r-mt-6 {
  --margin-top: var(--space-6);
}

.rt-r-mt-7 {
  --margin-top: var(--space-7);
}

.rt-r-mt-8 {
  --margin-top: var(--space-8);
}

.rt-r-mt-9 {
  --margin-top: var(--space-9);
}

.-rt-r-mt-1 {
  --margin-top: calc(-1 * var(--space-1));
}

.-rt-r-mt-2 {
  --margin-top: calc(-1 * var(--space-2));
}

.-rt-r-mt-3 {
  --margin-top: calc(-1 * var(--space-3));
}

.-rt-r-mt-4 {
  --margin-top: calc(-1 * var(--space-4));
}

.-rt-r-mt-5 {
  --margin-top: calc(-1 * var(--space-5));
}

.-rt-r-mt-6 {
  --margin-top: calc(-1 * var(--space-6));
}

.-rt-r-mt-7 {
  --margin-top: calc(-1 * var(--space-7));
}

.-rt-r-mt-8 {
  --margin-top: calc(-1 * var(--space-8));
}

.-rt-r-mt-9 {
  --margin-top: calc(-1 * var(--space-9));
}

@media (min-width: 520px) {
  .xs\:rt-r-mt, .xs\:rt-r-mt-0, .xs\:rt-r-mt-1, .xs\:rt-r-mt-2, .xs\:rt-r-mt-3, .xs\:rt-r-mt-4, .xs\:rt-r-mt-5, .xs\:rt-r-mt-6, .xs\:rt-r-mt-7, .xs\:rt-r-mt-8, .xs\:rt-r-mt-9, .xs\:-rt-r-mt-1, .xs\:-rt-r-mt-2, .xs\:-rt-r-mt-3, .xs\:-rt-r-mt-4, .xs\:-rt-r-mt-5, .xs\:-rt-r-mt-6, .xs\:-rt-r-mt-7, .xs\:-rt-r-mt-8, .xs\:-rt-r-mt-9 {
    margin-top: var(--margin-top-override, var(--margin-top));
  }

  .xs\:rt-r-mt {
    --margin-top: var(--mt-xs);
  }

  .xs\:rt-r-mt-0 {
    --margin-top: 0px;
  }

  .xs\:rt-r-mt-1 {
    --margin-top: var(--space-1);
  }

  .xs\:rt-r-mt-2 {
    --margin-top: var(--space-2);
  }

  .xs\:rt-r-mt-3 {
    --margin-top: var(--space-3);
  }

  .xs\:rt-r-mt-4 {
    --margin-top: var(--space-4);
  }

  .xs\:rt-r-mt-5 {
    --margin-top: var(--space-5);
  }

  .xs\:rt-r-mt-6 {
    --margin-top: var(--space-6);
  }

  .xs\:rt-r-mt-7 {
    --margin-top: var(--space-7);
  }

  .xs\:rt-r-mt-8 {
    --margin-top: var(--space-8);
  }

  .xs\:rt-r-mt-9 {
    --margin-top: var(--space-9);
  }

  .xs\:-rt-r-mt-1 {
    --margin-top: calc(-1 * var(--space-1));
  }

  .xs\:-rt-r-mt-2 {
    --margin-top: calc(-1 * var(--space-2));
  }

  .xs\:-rt-r-mt-3 {
    --margin-top: calc(-1 * var(--space-3));
  }

  .xs\:-rt-r-mt-4 {
    --margin-top: calc(-1 * var(--space-4));
  }

  .xs\:-rt-r-mt-5 {
    --margin-top: calc(-1 * var(--space-5));
  }

  .xs\:-rt-r-mt-6 {
    --margin-top: calc(-1 * var(--space-6));
  }

  .xs\:-rt-r-mt-7 {
    --margin-top: calc(-1 * var(--space-7));
  }

  .xs\:-rt-r-mt-8 {
    --margin-top: calc(-1 * var(--space-8));
  }

  .xs\:-rt-r-mt-9 {
    --margin-top: calc(-1 * var(--space-9));
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-mt, .sm\:rt-r-mt-0, .sm\:rt-r-mt-1, .sm\:rt-r-mt-2, .sm\:rt-r-mt-3, .sm\:rt-r-mt-4, .sm\:rt-r-mt-5, .sm\:rt-r-mt-6, .sm\:rt-r-mt-7, .sm\:rt-r-mt-8, .sm\:rt-r-mt-9, .sm\:-rt-r-mt-1, .sm\:-rt-r-mt-2, .sm\:-rt-r-mt-3, .sm\:-rt-r-mt-4, .sm\:-rt-r-mt-5, .sm\:-rt-r-mt-6, .sm\:-rt-r-mt-7, .sm\:-rt-r-mt-8, .sm\:-rt-r-mt-9 {
    margin-top: var(--margin-top-override, var(--margin-top));
  }

  .sm\:rt-r-mt {
    --margin-top: var(--mt-sm);
  }

  .sm\:rt-r-mt-0 {
    --margin-top: 0px;
  }

  .sm\:rt-r-mt-1 {
    --margin-top: var(--space-1);
  }

  .sm\:rt-r-mt-2 {
    --margin-top: var(--space-2);
  }

  .sm\:rt-r-mt-3 {
    --margin-top: var(--space-3);
  }

  .sm\:rt-r-mt-4 {
    --margin-top: var(--space-4);
  }

  .sm\:rt-r-mt-5 {
    --margin-top: var(--space-5);
  }

  .sm\:rt-r-mt-6 {
    --margin-top: var(--space-6);
  }

  .sm\:rt-r-mt-7 {
    --margin-top: var(--space-7);
  }

  .sm\:rt-r-mt-8 {
    --margin-top: var(--space-8);
  }

  .sm\:rt-r-mt-9 {
    --margin-top: var(--space-9);
  }

  .sm\:-rt-r-mt-1 {
    --margin-top: calc(-1 * var(--space-1));
  }

  .sm\:-rt-r-mt-2 {
    --margin-top: calc(-1 * var(--space-2));
  }

  .sm\:-rt-r-mt-3 {
    --margin-top: calc(-1 * var(--space-3));
  }

  .sm\:-rt-r-mt-4 {
    --margin-top: calc(-1 * var(--space-4));
  }

  .sm\:-rt-r-mt-5 {
    --margin-top: calc(-1 * var(--space-5));
  }

  .sm\:-rt-r-mt-6 {
    --margin-top: calc(-1 * var(--space-6));
  }

  .sm\:-rt-r-mt-7 {
    --margin-top: calc(-1 * var(--space-7));
  }

  .sm\:-rt-r-mt-8 {
    --margin-top: calc(-1 * var(--space-8));
  }

  .sm\:-rt-r-mt-9 {
    --margin-top: calc(-1 * var(--space-9));
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-mt, .md\:rt-r-mt-0, .md\:rt-r-mt-1, .md\:rt-r-mt-2, .md\:rt-r-mt-3, .md\:rt-r-mt-4, .md\:rt-r-mt-5, .md\:rt-r-mt-6, .md\:rt-r-mt-7, .md\:rt-r-mt-8, .md\:rt-r-mt-9, .md\:-rt-r-mt-1, .md\:-rt-r-mt-2, .md\:-rt-r-mt-3, .md\:-rt-r-mt-4, .md\:-rt-r-mt-5, .md\:-rt-r-mt-6, .md\:-rt-r-mt-7, .md\:-rt-r-mt-8, .md\:-rt-r-mt-9 {
    margin-top: var(--margin-top-override, var(--margin-top));
  }

  .md\:rt-r-mt {
    --margin-top: var(--mt-md);
  }

  .md\:rt-r-mt-0 {
    --margin-top: 0px;
  }

  .md\:rt-r-mt-1 {
    --margin-top: var(--space-1);
  }

  .md\:rt-r-mt-2 {
    --margin-top: var(--space-2);
  }

  .md\:rt-r-mt-3 {
    --margin-top: var(--space-3);
  }

  .md\:rt-r-mt-4 {
    --margin-top: var(--space-4);
  }

  .md\:rt-r-mt-5 {
    --margin-top: var(--space-5);
  }

  .md\:rt-r-mt-6 {
    --margin-top: var(--space-6);
  }

  .md\:rt-r-mt-7 {
    --margin-top: var(--space-7);
  }

  .md\:rt-r-mt-8 {
    --margin-top: var(--space-8);
  }

  .md\:rt-r-mt-9 {
    --margin-top: var(--space-9);
  }

  .md\:-rt-r-mt-1 {
    --margin-top: calc(-1 * var(--space-1));
  }

  .md\:-rt-r-mt-2 {
    --margin-top: calc(-1 * var(--space-2));
  }

  .md\:-rt-r-mt-3 {
    --margin-top: calc(-1 * var(--space-3));
  }

  .md\:-rt-r-mt-4 {
    --margin-top: calc(-1 * var(--space-4));
  }

  .md\:-rt-r-mt-5 {
    --margin-top: calc(-1 * var(--space-5));
  }

  .md\:-rt-r-mt-6 {
    --margin-top: calc(-1 * var(--space-6));
  }

  .md\:-rt-r-mt-7 {
    --margin-top: calc(-1 * var(--space-7));
  }

  .md\:-rt-r-mt-8 {
    --margin-top: calc(-1 * var(--space-8));
  }

  .md\:-rt-r-mt-9 {
    --margin-top: calc(-1 * var(--space-9));
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-mt, .lg\:rt-r-mt-0, .lg\:rt-r-mt-1, .lg\:rt-r-mt-2, .lg\:rt-r-mt-3, .lg\:rt-r-mt-4, .lg\:rt-r-mt-5, .lg\:rt-r-mt-6, .lg\:rt-r-mt-7, .lg\:rt-r-mt-8, .lg\:rt-r-mt-9, .lg\:-rt-r-mt-1, .lg\:-rt-r-mt-2, .lg\:-rt-r-mt-3, .lg\:-rt-r-mt-4, .lg\:-rt-r-mt-5, .lg\:-rt-r-mt-6, .lg\:-rt-r-mt-7, .lg\:-rt-r-mt-8, .lg\:-rt-r-mt-9 {
    margin-top: var(--margin-top-override, var(--margin-top));
  }

  .lg\:rt-r-mt {
    --margin-top: var(--mt-lg);
  }

  .lg\:rt-r-mt-0 {
    --margin-top: 0px;
  }

  .lg\:rt-r-mt-1 {
    --margin-top: var(--space-1);
  }

  .lg\:rt-r-mt-2 {
    --margin-top: var(--space-2);
  }

  .lg\:rt-r-mt-3 {
    --margin-top: var(--space-3);
  }

  .lg\:rt-r-mt-4 {
    --margin-top: var(--space-4);
  }

  .lg\:rt-r-mt-5 {
    --margin-top: var(--space-5);
  }

  .lg\:rt-r-mt-6 {
    --margin-top: var(--space-6);
  }

  .lg\:rt-r-mt-7 {
    --margin-top: var(--space-7);
  }

  .lg\:rt-r-mt-8 {
    --margin-top: var(--space-8);
  }

  .lg\:rt-r-mt-9 {
    --margin-top: var(--space-9);
  }

  .lg\:-rt-r-mt-1 {
    --margin-top: calc(-1 * var(--space-1));
  }

  .lg\:-rt-r-mt-2 {
    --margin-top: calc(-1 * var(--space-2));
  }

  .lg\:-rt-r-mt-3 {
    --margin-top: calc(-1 * var(--space-3));
  }

  .lg\:-rt-r-mt-4 {
    --margin-top: calc(-1 * var(--space-4));
  }

  .lg\:-rt-r-mt-5 {
    --margin-top: calc(-1 * var(--space-5));
  }

  .lg\:-rt-r-mt-6 {
    --margin-top: calc(-1 * var(--space-6));
  }

  .lg\:-rt-r-mt-7 {
    --margin-top: calc(-1 * var(--space-7));
  }

  .lg\:-rt-r-mt-8 {
    --margin-top: calc(-1 * var(--space-8));
  }

  .lg\:-rt-r-mt-9 {
    --margin-top: calc(-1 * var(--space-9));
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-mt, .xl\:rt-r-mt-0, .xl\:rt-r-mt-1, .xl\:rt-r-mt-2, .xl\:rt-r-mt-3, .xl\:rt-r-mt-4, .xl\:rt-r-mt-5, .xl\:rt-r-mt-6, .xl\:rt-r-mt-7, .xl\:rt-r-mt-8, .xl\:rt-r-mt-9, .xl\:-rt-r-mt-1, .xl\:-rt-r-mt-2, .xl\:-rt-r-mt-3, .xl\:-rt-r-mt-4, .xl\:-rt-r-mt-5, .xl\:-rt-r-mt-6, .xl\:-rt-r-mt-7, .xl\:-rt-r-mt-8, .xl\:-rt-r-mt-9 {
    margin-top: var(--margin-top-override, var(--margin-top));
  }

  .xl\:rt-r-mt {
    --margin-top: var(--mt-xl);
  }

  .xl\:rt-r-mt-0 {
    --margin-top: 0px;
  }

  .xl\:rt-r-mt-1 {
    --margin-top: var(--space-1);
  }

  .xl\:rt-r-mt-2 {
    --margin-top: var(--space-2);
  }

  .xl\:rt-r-mt-3 {
    --margin-top: var(--space-3);
  }

  .xl\:rt-r-mt-4 {
    --margin-top: var(--space-4);
  }

  .xl\:rt-r-mt-5 {
    --margin-top: var(--space-5);
  }

  .xl\:rt-r-mt-6 {
    --margin-top: var(--space-6);
  }

  .xl\:rt-r-mt-7 {
    --margin-top: var(--space-7);
  }

  .xl\:rt-r-mt-8 {
    --margin-top: var(--space-8);
  }

  .xl\:rt-r-mt-9 {
    --margin-top: var(--space-9);
  }

  .xl\:-rt-r-mt-1 {
    --margin-top: calc(-1 * var(--space-1));
  }

  .xl\:-rt-r-mt-2 {
    --margin-top: calc(-1 * var(--space-2));
  }

  .xl\:-rt-r-mt-3 {
    --margin-top: calc(-1 * var(--space-3));
  }

  .xl\:-rt-r-mt-4 {
    --margin-top: calc(-1 * var(--space-4));
  }

  .xl\:-rt-r-mt-5 {
    --margin-top: calc(-1 * var(--space-5));
  }

  .xl\:-rt-r-mt-6 {
    --margin-top: calc(-1 * var(--space-6));
  }

  .xl\:-rt-r-mt-7 {
    --margin-top: calc(-1 * var(--space-7));
  }

  .xl\:-rt-r-mt-8 {
    --margin-top: calc(-1 * var(--space-8));
  }

  .xl\:-rt-r-mt-9 {
    --margin-top: calc(-1 * var(--space-9));
  }
}

.rt-r-mr, .rt-r-mr-0, .rt-r-mr-1, .rt-r-mr-2, .rt-r-mr-3, .rt-r-mr-4, .rt-r-mr-5, .rt-r-mr-6, .rt-r-mr-7, .rt-r-mr-8, .rt-r-mr-9, .-rt-r-mr-1, .-rt-r-mr-2, .-rt-r-mr-3, .-rt-r-mr-4, .-rt-r-mr-5, .-rt-r-mr-6, .-rt-r-mr-7, .-rt-r-mr-8, .-rt-r-mr-9 {
  margin-right: var(--margin-right-override, var(--margin-right));
}

.rt-r-mr {
  --margin-right: var(--mr);
}

.rt-r-mr-0 {
  --margin-right: 0px;
}

.rt-r-mr-1 {
  --margin-right: var(--space-1);
}

.rt-r-mr-2 {
  --margin-right: var(--space-2);
}

.rt-r-mr-3 {
  --margin-right: var(--space-3);
}

.rt-r-mr-4 {
  --margin-right: var(--space-4);
}

.rt-r-mr-5 {
  --margin-right: var(--space-5);
}

.rt-r-mr-6 {
  --margin-right: var(--space-6);
}

.rt-r-mr-7 {
  --margin-right: var(--space-7);
}

.rt-r-mr-8 {
  --margin-right: var(--space-8);
}

.rt-r-mr-9 {
  --margin-right: var(--space-9);
}

.-rt-r-mr-1 {
  --margin-right: calc(-1 * var(--space-1));
}

.-rt-r-mr-2 {
  --margin-right: calc(-1 * var(--space-2));
}

.-rt-r-mr-3 {
  --margin-right: calc(-1 * var(--space-3));
}

.-rt-r-mr-4 {
  --margin-right: calc(-1 * var(--space-4));
}

.-rt-r-mr-5 {
  --margin-right: calc(-1 * var(--space-5));
}

.-rt-r-mr-6 {
  --margin-right: calc(-1 * var(--space-6));
}

.-rt-r-mr-7 {
  --margin-right: calc(-1 * var(--space-7));
}

.-rt-r-mr-8 {
  --margin-right: calc(-1 * var(--space-8));
}

.-rt-r-mr-9 {
  --margin-right: calc(-1 * var(--space-9));
}

@media (min-width: 520px) {
  .xs\:rt-r-mr, .xs\:rt-r-mr-0, .xs\:rt-r-mr-1, .xs\:rt-r-mr-2, .xs\:rt-r-mr-3, .xs\:rt-r-mr-4, .xs\:rt-r-mr-5, .xs\:rt-r-mr-6, .xs\:rt-r-mr-7, .xs\:rt-r-mr-8, .xs\:rt-r-mr-9, .xs\:-rt-r-mr-1, .xs\:-rt-r-mr-2, .xs\:-rt-r-mr-3, .xs\:-rt-r-mr-4, .xs\:-rt-r-mr-5, .xs\:-rt-r-mr-6, .xs\:-rt-r-mr-7, .xs\:-rt-r-mr-8, .xs\:-rt-r-mr-9 {
    margin-right: var(--margin-right-override, var(--margin-right));
  }

  .xs\:rt-r-mr {
    --margin-right: var(--mr-xs);
  }

  .xs\:rt-r-mr-0 {
    --margin-right: 0px;
  }

  .xs\:rt-r-mr-1 {
    --margin-right: var(--space-1);
  }

  .xs\:rt-r-mr-2 {
    --margin-right: var(--space-2);
  }

  .xs\:rt-r-mr-3 {
    --margin-right: var(--space-3);
  }

  .xs\:rt-r-mr-4 {
    --margin-right: var(--space-4);
  }

  .xs\:rt-r-mr-5 {
    --margin-right: var(--space-5);
  }

  .xs\:rt-r-mr-6 {
    --margin-right: var(--space-6);
  }

  .xs\:rt-r-mr-7 {
    --margin-right: var(--space-7);
  }

  .xs\:rt-r-mr-8 {
    --margin-right: var(--space-8);
  }

  .xs\:rt-r-mr-9 {
    --margin-right: var(--space-9);
  }

  .xs\:-rt-r-mr-1 {
    --margin-right: calc(-1 * var(--space-1));
  }

  .xs\:-rt-r-mr-2 {
    --margin-right: calc(-1 * var(--space-2));
  }

  .xs\:-rt-r-mr-3 {
    --margin-right: calc(-1 * var(--space-3));
  }

  .xs\:-rt-r-mr-4 {
    --margin-right: calc(-1 * var(--space-4));
  }

  .xs\:-rt-r-mr-5 {
    --margin-right: calc(-1 * var(--space-5));
  }

  .xs\:-rt-r-mr-6 {
    --margin-right: calc(-1 * var(--space-6));
  }

  .xs\:-rt-r-mr-7 {
    --margin-right: calc(-1 * var(--space-7));
  }

  .xs\:-rt-r-mr-8 {
    --margin-right: calc(-1 * var(--space-8));
  }

  .xs\:-rt-r-mr-9 {
    --margin-right: calc(-1 * var(--space-9));
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-mr, .sm\:rt-r-mr-0, .sm\:rt-r-mr-1, .sm\:rt-r-mr-2, .sm\:rt-r-mr-3, .sm\:rt-r-mr-4, .sm\:rt-r-mr-5, .sm\:rt-r-mr-6, .sm\:rt-r-mr-7, .sm\:rt-r-mr-8, .sm\:rt-r-mr-9, .sm\:-rt-r-mr-1, .sm\:-rt-r-mr-2, .sm\:-rt-r-mr-3, .sm\:-rt-r-mr-4, .sm\:-rt-r-mr-5, .sm\:-rt-r-mr-6, .sm\:-rt-r-mr-7, .sm\:-rt-r-mr-8, .sm\:-rt-r-mr-9 {
    margin-right: var(--margin-right-override, var(--margin-right));
  }

  .sm\:rt-r-mr {
    --margin-right: var(--mr-sm);
  }

  .sm\:rt-r-mr-0 {
    --margin-right: 0px;
  }

  .sm\:rt-r-mr-1 {
    --margin-right: var(--space-1);
  }

  .sm\:rt-r-mr-2 {
    --margin-right: var(--space-2);
  }

  .sm\:rt-r-mr-3 {
    --margin-right: var(--space-3);
  }

  .sm\:rt-r-mr-4 {
    --margin-right: var(--space-4);
  }

  .sm\:rt-r-mr-5 {
    --margin-right: var(--space-5);
  }

  .sm\:rt-r-mr-6 {
    --margin-right: var(--space-6);
  }

  .sm\:rt-r-mr-7 {
    --margin-right: var(--space-7);
  }

  .sm\:rt-r-mr-8 {
    --margin-right: var(--space-8);
  }

  .sm\:rt-r-mr-9 {
    --margin-right: var(--space-9);
  }

  .sm\:-rt-r-mr-1 {
    --margin-right: calc(-1 * var(--space-1));
  }

  .sm\:-rt-r-mr-2 {
    --margin-right: calc(-1 * var(--space-2));
  }

  .sm\:-rt-r-mr-3 {
    --margin-right: calc(-1 * var(--space-3));
  }

  .sm\:-rt-r-mr-4 {
    --margin-right: calc(-1 * var(--space-4));
  }

  .sm\:-rt-r-mr-5 {
    --margin-right: calc(-1 * var(--space-5));
  }

  .sm\:-rt-r-mr-6 {
    --margin-right: calc(-1 * var(--space-6));
  }

  .sm\:-rt-r-mr-7 {
    --margin-right: calc(-1 * var(--space-7));
  }

  .sm\:-rt-r-mr-8 {
    --margin-right: calc(-1 * var(--space-8));
  }

  .sm\:-rt-r-mr-9 {
    --margin-right: calc(-1 * var(--space-9));
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-mr, .md\:rt-r-mr-0, .md\:rt-r-mr-1, .md\:rt-r-mr-2, .md\:rt-r-mr-3, .md\:rt-r-mr-4, .md\:rt-r-mr-5, .md\:rt-r-mr-6, .md\:rt-r-mr-7, .md\:rt-r-mr-8, .md\:rt-r-mr-9, .md\:-rt-r-mr-1, .md\:-rt-r-mr-2, .md\:-rt-r-mr-3, .md\:-rt-r-mr-4, .md\:-rt-r-mr-5, .md\:-rt-r-mr-6, .md\:-rt-r-mr-7, .md\:-rt-r-mr-8, .md\:-rt-r-mr-9 {
    margin-right: var(--margin-right-override, var(--margin-right));
  }

  .md\:rt-r-mr {
    --margin-right: var(--mr-md);
  }

  .md\:rt-r-mr-0 {
    --margin-right: 0px;
  }

  .md\:rt-r-mr-1 {
    --margin-right: var(--space-1);
  }

  .md\:rt-r-mr-2 {
    --margin-right: var(--space-2);
  }

  .md\:rt-r-mr-3 {
    --margin-right: var(--space-3);
  }

  .md\:rt-r-mr-4 {
    --margin-right: var(--space-4);
  }

  .md\:rt-r-mr-5 {
    --margin-right: var(--space-5);
  }

  .md\:rt-r-mr-6 {
    --margin-right: var(--space-6);
  }

  .md\:rt-r-mr-7 {
    --margin-right: var(--space-7);
  }

  .md\:rt-r-mr-8 {
    --margin-right: var(--space-8);
  }

  .md\:rt-r-mr-9 {
    --margin-right: var(--space-9);
  }

  .md\:-rt-r-mr-1 {
    --margin-right: calc(-1 * var(--space-1));
  }

  .md\:-rt-r-mr-2 {
    --margin-right: calc(-1 * var(--space-2));
  }

  .md\:-rt-r-mr-3 {
    --margin-right: calc(-1 * var(--space-3));
  }

  .md\:-rt-r-mr-4 {
    --margin-right: calc(-1 * var(--space-4));
  }

  .md\:-rt-r-mr-5 {
    --margin-right: calc(-1 * var(--space-5));
  }

  .md\:-rt-r-mr-6 {
    --margin-right: calc(-1 * var(--space-6));
  }

  .md\:-rt-r-mr-7 {
    --margin-right: calc(-1 * var(--space-7));
  }

  .md\:-rt-r-mr-8 {
    --margin-right: calc(-1 * var(--space-8));
  }

  .md\:-rt-r-mr-9 {
    --margin-right: calc(-1 * var(--space-9));
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-mr, .lg\:rt-r-mr-0, .lg\:rt-r-mr-1, .lg\:rt-r-mr-2, .lg\:rt-r-mr-3, .lg\:rt-r-mr-4, .lg\:rt-r-mr-5, .lg\:rt-r-mr-6, .lg\:rt-r-mr-7, .lg\:rt-r-mr-8, .lg\:rt-r-mr-9, .lg\:-rt-r-mr-1, .lg\:-rt-r-mr-2, .lg\:-rt-r-mr-3, .lg\:-rt-r-mr-4, .lg\:-rt-r-mr-5, .lg\:-rt-r-mr-6, .lg\:-rt-r-mr-7, .lg\:-rt-r-mr-8, .lg\:-rt-r-mr-9 {
    margin-right: var(--margin-right-override, var(--margin-right));
  }

  .lg\:rt-r-mr {
    --margin-right: var(--mr-lg);
  }

  .lg\:rt-r-mr-0 {
    --margin-right: 0px;
  }

  .lg\:rt-r-mr-1 {
    --margin-right: var(--space-1);
  }

  .lg\:rt-r-mr-2 {
    --margin-right: var(--space-2);
  }

  .lg\:rt-r-mr-3 {
    --margin-right: var(--space-3);
  }

  .lg\:rt-r-mr-4 {
    --margin-right: var(--space-4);
  }

  .lg\:rt-r-mr-5 {
    --margin-right: var(--space-5);
  }

  .lg\:rt-r-mr-6 {
    --margin-right: var(--space-6);
  }

  .lg\:rt-r-mr-7 {
    --margin-right: var(--space-7);
  }

  .lg\:rt-r-mr-8 {
    --margin-right: var(--space-8);
  }

  .lg\:rt-r-mr-9 {
    --margin-right: var(--space-9);
  }

  .lg\:-rt-r-mr-1 {
    --margin-right: calc(-1 * var(--space-1));
  }

  .lg\:-rt-r-mr-2 {
    --margin-right: calc(-1 * var(--space-2));
  }

  .lg\:-rt-r-mr-3 {
    --margin-right: calc(-1 * var(--space-3));
  }

  .lg\:-rt-r-mr-4 {
    --margin-right: calc(-1 * var(--space-4));
  }

  .lg\:-rt-r-mr-5 {
    --margin-right: calc(-1 * var(--space-5));
  }

  .lg\:-rt-r-mr-6 {
    --margin-right: calc(-1 * var(--space-6));
  }

  .lg\:-rt-r-mr-7 {
    --margin-right: calc(-1 * var(--space-7));
  }

  .lg\:-rt-r-mr-8 {
    --margin-right: calc(-1 * var(--space-8));
  }

  .lg\:-rt-r-mr-9 {
    --margin-right: calc(-1 * var(--space-9));
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-mr, .xl\:rt-r-mr-0, .xl\:rt-r-mr-1, .xl\:rt-r-mr-2, .xl\:rt-r-mr-3, .xl\:rt-r-mr-4, .xl\:rt-r-mr-5, .xl\:rt-r-mr-6, .xl\:rt-r-mr-7, .xl\:rt-r-mr-8, .xl\:rt-r-mr-9, .xl\:-rt-r-mr-1, .xl\:-rt-r-mr-2, .xl\:-rt-r-mr-3, .xl\:-rt-r-mr-4, .xl\:-rt-r-mr-5, .xl\:-rt-r-mr-6, .xl\:-rt-r-mr-7, .xl\:-rt-r-mr-8, .xl\:-rt-r-mr-9 {
    margin-right: var(--margin-right-override, var(--margin-right));
  }

  .xl\:rt-r-mr {
    --margin-right: var(--mr-xl);
  }

  .xl\:rt-r-mr-0 {
    --margin-right: 0px;
  }

  .xl\:rt-r-mr-1 {
    --margin-right: var(--space-1);
  }

  .xl\:rt-r-mr-2 {
    --margin-right: var(--space-2);
  }

  .xl\:rt-r-mr-3 {
    --margin-right: var(--space-3);
  }

  .xl\:rt-r-mr-4 {
    --margin-right: var(--space-4);
  }

  .xl\:rt-r-mr-5 {
    --margin-right: var(--space-5);
  }

  .xl\:rt-r-mr-6 {
    --margin-right: var(--space-6);
  }

  .xl\:rt-r-mr-7 {
    --margin-right: var(--space-7);
  }

  .xl\:rt-r-mr-8 {
    --margin-right: var(--space-8);
  }

  .xl\:rt-r-mr-9 {
    --margin-right: var(--space-9);
  }

  .xl\:-rt-r-mr-1 {
    --margin-right: calc(-1 * var(--space-1));
  }

  .xl\:-rt-r-mr-2 {
    --margin-right: calc(-1 * var(--space-2));
  }

  .xl\:-rt-r-mr-3 {
    --margin-right: calc(-1 * var(--space-3));
  }

  .xl\:-rt-r-mr-4 {
    --margin-right: calc(-1 * var(--space-4));
  }

  .xl\:-rt-r-mr-5 {
    --margin-right: calc(-1 * var(--space-5));
  }

  .xl\:-rt-r-mr-6 {
    --margin-right: calc(-1 * var(--space-6));
  }

  .xl\:-rt-r-mr-7 {
    --margin-right: calc(-1 * var(--space-7));
  }

  .xl\:-rt-r-mr-8 {
    --margin-right: calc(-1 * var(--space-8));
  }

  .xl\:-rt-r-mr-9 {
    --margin-right: calc(-1 * var(--space-9));
  }
}

.rt-r-mb, .rt-r-mb-0, .rt-r-mb-1, .rt-r-mb-2, .rt-r-mb-3, .rt-r-mb-4, .rt-r-mb-5, .rt-r-mb-6, .rt-r-mb-7, .rt-r-mb-8, .rt-r-mb-9, .-rt-r-mb-1, .-rt-r-mb-2, .-rt-r-mb-3, .-rt-r-mb-4, .-rt-r-mb-5, .-rt-r-mb-6, .-rt-r-mb-7, .-rt-r-mb-8, .-rt-r-mb-9 {
  margin-bottom: var(--margin-bottom-override, var(--margin-bottom));
}

.rt-r-mb {
  --margin-bottom: var(--mb);
}

.rt-r-mb-0 {
  --margin-bottom: 0px;
}

.rt-r-mb-1 {
  --margin-bottom: var(--space-1);
}

.rt-r-mb-2 {
  --margin-bottom: var(--space-2);
}

.rt-r-mb-3 {
  --margin-bottom: var(--space-3);
}

.rt-r-mb-4 {
  --margin-bottom: var(--space-4);
}

.rt-r-mb-5 {
  --margin-bottom: var(--space-5);
}

.rt-r-mb-6 {
  --margin-bottom: var(--space-6);
}

.rt-r-mb-7 {
  --margin-bottom: var(--space-7);
}

.rt-r-mb-8 {
  --margin-bottom: var(--space-8);
}

.rt-r-mb-9 {
  --margin-bottom: var(--space-9);
}

.-rt-r-mb-1 {
  --margin-bottom: calc(-1 * var(--space-1));
}

.-rt-r-mb-2 {
  --margin-bottom: calc(-1 * var(--space-2));
}

.-rt-r-mb-3 {
  --margin-bottom: calc(-1 * var(--space-3));
}

.-rt-r-mb-4 {
  --margin-bottom: calc(-1 * var(--space-4));
}

.-rt-r-mb-5 {
  --margin-bottom: calc(-1 * var(--space-5));
}

.-rt-r-mb-6 {
  --margin-bottom: calc(-1 * var(--space-6));
}

.-rt-r-mb-7 {
  --margin-bottom: calc(-1 * var(--space-7));
}

.-rt-r-mb-8 {
  --margin-bottom: calc(-1 * var(--space-8));
}

.-rt-r-mb-9 {
  --margin-bottom: calc(-1 * var(--space-9));
}

@media (min-width: 520px) {
  .xs\:rt-r-mb, .xs\:rt-r-mb-0, .xs\:rt-r-mb-1, .xs\:rt-r-mb-2, .xs\:rt-r-mb-3, .xs\:rt-r-mb-4, .xs\:rt-r-mb-5, .xs\:rt-r-mb-6, .xs\:rt-r-mb-7, .xs\:rt-r-mb-8, .xs\:rt-r-mb-9, .xs\:-rt-r-mb-1, .xs\:-rt-r-mb-2, .xs\:-rt-r-mb-3, .xs\:-rt-r-mb-4, .xs\:-rt-r-mb-5, .xs\:-rt-r-mb-6, .xs\:-rt-r-mb-7, .xs\:-rt-r-mb-8, .xs\:-rt-r-mb-9 {
    margin-bottom: var(--margin-bottom-override, var(--margin-bottom));
  }

  .xs\:rt-r-mb {
    --margin-bottom: var(--mb-xs);
  }

  .xs\:rt-r-mb-0 {
    --margin-bottom: 0px;
  }

  .xs\:rt-r-mb-1 {
    --margin-bottom: var(--space-1);
  }

  .xs\:rt-r-mb-2 {
    --margin-bottom: var(--space-2);
  }

  .xs\:rt-r-mb-3 {
    --margin-bottom: var(--space-3);
  }

  .xs\:rt-r-mb-4 {
    --margin-bottom: var(--space-4);
  }

  .xs\:rt-r-mb-5 {
    --margin-bottom: var(--space-5);
  }

  .xs\:rt-r-mb-6 {
    --margin-bottom: var(--space-6);
  }

  .xs\:rt-r-mb-7 {
    --margin-bottom: var(--space-7);
  }

  .xs\:rt-r-mb-8 {
    --margin-bottom: var(--space-8);
  }

  .xs\:rt-r-mb-9 {
    --margin-bottom: var(--space-9);
  }

  .xs\:-rt-r-mb-1 {
    --margin-bottom: calc(-1 * var(--space-1));
  }

  .xs\:-rt-r-mb-2 {
    --margin-bottom: calc(-1 * var(--space-2));
  }

  .xs\:-rt-r-mb-3 {
    --margin-bottom: calc(-1 * var(--space-3));
  }

  .xs\:-rt-r-mb-4 {
    --margin-bottom: calc(-1 * var(--space-4));
  }

  .xs\:-rt-r-mb-5 {
    --margin-bottom: calc(-1 * var(--space-5));
  }

  .xs\:-rt-r-mb-6 {
    --margin-bottom: calc(-1 * var(--space-6));
  }

  .xs\:-rt-r-mb-7 {
    --margin-bottom: calc(-1 * var(--space-7));
  }

  .xs\:-rt-r-mb-8 {
    --margin-bottom: calc(-1 * var(--space-8));
  }

  .xs\:-rt-r-mb-9 {
    --margin-bottom: calc(-1 * var(--space-9));
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-mb, .sm\:rt-r-mb-0, .sm\:rt-r-mb-1, .sm\:rt-r-mb-2, .sm\:rt-r-mb-3, .sm\:rt-r-mb-4, .sm\:rt-r-mb-5, .sm\:rt-r-mb-6, .sm\:rt-r-mb-7, .sm\:rt-r-mb-8, .sm\:rt-r-mb-9, .sm\:-rt-r-mb-1, .sm\:-rt-r-mb-2, .sm\:-rt-r-mb-3, .sm\:-rt-r-mb-4, .sm\:-rt-r-mb-5, .sm\:-rt-r-mb-6, .sm\:-rt-r-mb-7, .sm\:-rt-r-mb-8, .sm\:-rt-r-mb-9 {
    margin-bottom: var(--margin-bottom-override, var(--margin-bottom));
  }

  .sm\:rt-r-mb {
    --margin-bottom: var(--mb-sm);
  }

  .sm\:rt-r-mb-0 {
    --margin-bottom: 0px;
  }

  .sm\:rt-r-mb-1 {
    --margin-bottom: var(--space-1);
  }

  .sm\:rt-r-mb-2 {
    --margin-bottom: var(--space-2);
  }

  .sm\:rt-r-mb-3 {
    --margin-bottom: var(--space-3);
  }

  .sm\:rt-r-mb-4 {
    --margin-bottom: var(--space-4);
  }

  .sm\:rt-r-mb-5 {
    --margin-bottom: var(--space-5);
  }

  .sm\:rt-r-mb-6 {
    --margin-bottom: var(--space-6);
  }

  .sm\:rt-r-mb-7 {
    --margin-bottom: var(--space-7);
  }

  .sm\:rt-r-mb-8 {
    --margin-bottom: var(--space-8);
  }

  .sm\:rt-r-mb-9 {
    --margin-bottom: var(--space-9);
  }

  .sm\:-rt-r-mb-1 {
    --margin-bottom: calc(-1 * var(--space-1));
  }

  .sm\:-rt-r-mb-2 {
    --margin-bottom: calc(-1 * var(--space-2));
  }

  .sm\:-rt-r-mb-3 {
    --margin-bottom: calc(-1 * var(--space-3));
  }

  .sm\:-rt-r-mb-4 {
    --margin-bottom: calc(-1 * var(--space-4));
  }

  .sm\:-rt-r-mb-5 {
    --margin-bottom: calc(-1 * var(--space-5));
  }

  .sm\:-rt-r-mb-6 {
    --margin-bottom: calc(-1 * var(--space-6));
  }

  .sm\:-rt-r-mb-7 {
    --margin-bottom: calc(-1 * var(--space-7));
  }

  .sm\:-rt-r-mb-8 {
    --margin-bottom: calc(-1 * var(--space-8));
  }

  .sm\:-rt-r-mb-9 {
    --margin-bottom: calc(-1 * var(--space-9));
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-mb, .md\:rt-r-mb-0, .md\:rt-r-mb-1, .md\:rt-r-mb-2, .md\:rt-r-mb-3, .md\:rt-r-mb-4, .md\:rt-r-mb-5, .md\:rt-r-mb-6, .md\:rt-r-mb-7, .md\:rt-r-mb-8, .md\:rt-r-mb-9, .md\:-rt-r-mb-1, .md\:-rt-r-mb-2, .md\:-rt-r-mb-3, .md\:-rt-r-mb-4, .md\:-rt-r-mb-5, .md\:-rt-r-mb-6, .md\:-rt-r-mb-7, .md\:-rt-r-mb-8, .md\:-rt-r-mb-9 {
    margin-bottom: var(--margin-bottom-override, var(--margin-bottom));
  }

  .md\:rt-r-mb {
    --margin-bottom: var(--mb-md);
  }

  .md\:rt-r-mb-0 {
    --margin-bottom: 0px;
  }

  .md\:rt-r-mb-1 {
    --margin-bottom: var(--space-1);
  }

  .md\:rt-r-mb-2 {
    --margin-bottom: var(--space-2);
  }

  .md\:rt-r-mb-3 {
    --margin-bottom: var(--space-3);
  }

  .md\:rt-r-mb-4 {
    --margin-bottom: var(--space-4);
  }

  .md\:rt-r-mb-5 {
    --margin-bottom: var(--space-5);
  }

  .md\:rt-r-mb-6 {
    --margin-bottom: var(--space-6);
  }

  .md\:rt-r-mb-7 {
    --margin-bottom: var(--space-7);
  }

  .md\:rt-r-mb-8 {
    --margin-bottom: var(--space-8);
  }

  .md\:rt-r-mb-9 {
    --margin-bottom: var(--space-9);
  }

  .md\:-rt-r-mb-1 {
    --margin-bottom: calc(-1 * var(--space-1));
  }

  .md\:-rt-r-mb-2 {
    --margin-bottom: calc(-1 * var(--space-2));
  }

  .md\:-rt-r-mb-3 {
    --margin-bottom: calc(-1 * var(--space-3));
  }

  .md\:-rt-r-mb-4 {
    --margin-bottom: calc(-1 * var(--space-4));
  }

  .md\:-rt-r-mb-5 {
    --margin-bottom: calc(-1 * var(--space-5));
  }

  .md\:-rt-r-mb-6 {
    --margin-bottom: calc(-1 * var(--space-6));
  }

  .md\:-rt-r-mb-7 {
    --margin-bottom: calc(-1 * var(--space-7));
  }

  .md\:-rt-r-mb-8 {
    --margin-bottom: calc(-1 * var(--space-8));
  }

  .md\:-rt-r-mb-9 {
    --margin-bottom: calc(-1 * var(--space-9));
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-mb, .lg\:rt-r-mb-0, .lg\:rt-r-mb-1, .lg\:rt-r-mb-2, .lg\:rt-r-mb-3, .lg\:rt-r-mb-4, .lg\:rt-r-mb-5, .lg\:rt-r-mb-6, .lg\:rt-r-mb-7, .lg\:rt-r-mb-8, .lg\:rt-r-mb-9, .lg\:-rt-r-mb-1, .lg\:-rt-r-mb-2, .lg\:-rt-r-mb-3, .lg\:-rt-r-mb-4, .lg\:-rt-r-mb-5, .lg\:-rt-r-mb-6, .lg\:-rt-r-mb-7, .lg\:-rt-r-mb-8, .lg\:-rt-r-mb-9 {
    margin-bottom: var(--margin-bottom-override, var(--margin-bottom));
  }

  .lg\:rt-r-mb {
    --margin-bottom: var(--mb-lg);
  }

  .lg\:rt-r-mb-0 {
    --margin-bottom: 0px;
  }

  .lg\:rt-r-mb-1 {
    --margin-bottom: var(--space-1);
  }

  .lg\:rt-r-mb-2 {
    --margin-bottom: var(--space-2);
  }

  .lg\:rt-r-mb-3 {
    --margin-bottom: var(--space-3);
  }

  .lg\:rt-r-mb-4 {
    --margin-bottom: var(--space-4);
  }

  .lg\:rt-r-mb-5 {
    --margin-bottom: var(--space-5);
  }

  .lg\:rt-r-mb-6 {
    --margin-bottom: var(--space-6);
  }

  .lg\:rt-r-mb-7 {
    --margin-bottom: var(--space-7);
  }

  .lg\:rt-r-mb-8 {
    --margin-bottom: var(--space-8);
  }

  .lg\:rt-r-mb-9 {
    --margin-bottom: var(--space-9);
  }

  .lg\:-rt-r-mb-1 {
    --margin-bottom: calc(-1 * var(--space-1));
  }

  .lg\:-rt-r-mb-2 {
    --margin-bottom: calc(-1 * var(--space-2));
  }

  .lg\:-rt-r-mb-3 {
    --margin-bottom: calc(-1 * var(--space-3));
  }

  .lg\:-rt-r-mb-4 {
    --margin-bottom: calc(-1 * var(--space-4));
  }

  .lg\:-rt-r-mb-5 {
    --margin-bottom: calc(-1 * var(--space-5));
  }

  .lg\:-rt-r-mb-6 {
    --margin-bottom: calc(-1 * var(--space-6));
  }

  .lg\:-rt-r-mb-7 {
    --margin-bottom: calc(-1 * var(--space-7));
  }

  .lg\:-rt-r-mb-8 {
    --margin-bottom: calc(-1 * var(--space-8));
  }

  .lg\:-rt-r-mb-9 {
    --margin-bottom: calc(-1 * var(--space-9));
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-mb, .xl\:rt-r-mb-0, .xl\:rt-r-mb-1, .xl\:rt-r-mb-2, .xl\:rt-r-mb-3, .xl\:rt-r-mb-4, .xl\:rt-r-mb-5, .xl\:rt-r-mb-6, .xl\:rt-r-mb-7, .xl\:rt-r-mb-8, .xl\:rt-r-mb-9, .xl\:-rt-r-mb-1, .xl\:-rt-r-mb-2, .xl\:-rt-r-mb-3, .xl\:-rt-r-mb-4, .xl\:-rt-r-mb-5, .xl\:-rt-r-mb-6, .xl\:-rt-r-mb-7, .xl\:-rt-r-mb-8, .xl\:-rt-r-mb-9 {
    margin-bottom: var(--margin-bottom-override, var(--margin-bottom));
  }

  .xl\:rt-r-mb {
    --margin-bottom: var(--mb-xl);
  }

  .xl\:rt-r-mb-0 {
    --margin-bottom: 0px;
  }

  .xl\:rt-r-mb-1 {
    --margin-bottom: var(--space-1);
  }

  .xl\:rt-r-mb-2 {
    --margin-bottom: var(--space-2);
  }

  .xl\:rt-r-mb-3 {
    --margin-bottom: var(--space-3);
  }

  .xl\:rt-r-mb-4 {
    --margin-bottom: var(--space-4);
  }

  .xl\:rt-r-mb-5 {
    --margin-bottom: var(--space-5);
  }

  .xl\:rt-r-mb-6 {
    --margin-bottom: var(--space-6);
  }

  .xl\:rt-r-mb-7 {
    --margin-bottom: var(--space-7);
  }

  .xl\:rt-r-mb-8 {
    --margin-bottom: var(--space-8);
  }

  .xl\:rt-r-mb-9 {
    --margin-bottom: var(--space-9);
  }

  .xl\:-rt-r-mb-1 {
    --margin-bottom: calc(-1 * var(--space-1));
  }

  .xl\:-rt-r-mb-2 {
    --margin-bottom: calc(-1 * var(--space-2));
  }

  .xl\:-rt-r-mb-3 {
    --margin-bottom: calc(-1 * var(--space-3));
  }

  .xl\:-rt-r-mb-4 {
    --margin-bottom: calc(-1 * var(--space-4));
  }

  .xl\:-rt-r-mb-5 {
    --margin-bottom: calc(-1 * var(--space-5));
  }

  .xl\:-rt-r-mb-6 {
    --margin-bottom: calc(-1 * var(--space-6));
  }

  .xl\:-rt-r-mb-7 {
    --margin-bottom: calc(-1 * var(--space-7));
  }

  .xl\:-rt-r-mb-8 {
    --margin-bottom: calc(-1 * var(--space-8));
  }

  .xl\:-rt-r-mb-9 {
    --margin-bottom: calc(-1 * var(--space-9));
  }
}

.rt-r-ml, .rt-r-ml-0, .rt-r-ml-1, .rt-r-ml-2, .rt-r-ml-3, .rt-r-ml-4, .rt-r-ml-5, .rt-r-ml-6, .rt-r-ml-7, .rt-r-ml-8, .rt-r-ml-9, .-rt-r-ml-1, .-rt-r-ml-2, .-rt-r-ml-3, .-rt-r-ml-4, .-rt-r-ml-5, .-rt-r-ml-6, .-rt-r-ml-7, .-rt-r-ml-8, .-rt-r-ml-9 {
  margin-left: var(--margin-left-override, var(--margin-left));
}

.rt-r-ml {
  --margin-left: var(--ml);
}

.rt-r-ml-0 {
  --margin-left: 0px;
}

.rt-r-ml-1 {
  --margin-left: var(--space-1);
}

.rt-r-ml-2 {
  --margin-left: var(--space-2);
}

.rt-r-ml-3 {
  --margin-left: var(--space-3);
}

.rt-r-ml-4 {
  --margin-left: var(--space-4);
}

.rt-r-ml-5 {
  --margin-left: var(--space-5);
}

.rt-r-ml-6 {
  --margin-left: var(--space-6);
}

.rt-r-ml-7 {
  --margin-left: var(--space-7);
}

.rt-r-ml-8 {
  --margin-left: var(--space-8);
}

.rt-r-ml-9 {
  --margin-left: var(--space-9);
}

.-rt-r-ml-1 {
  --margin-left: calc(-1 * var(--space-1));
}

.-rt-r-ml-2 {
  --margin-left: calc(-1 * var(--space-2));
}

.-rt-r-ml-3 {
  --margin-left: calc(-1 * var(--space-3));
}

.-rt-r-ml-4 {
  --margin-left: calc(-1 * var(--space-4));
}

.-rt-r-ml-5 {
  --margin-left: calc(-1 * var(--space-5));
}

.-rt-r-ml-6 {
  --margin-left: calc(-1 * var(--space-6));
}

.-rt-r-ml-7 {
  --margin-left: calc(-1 * var(--space-7));
}

.-rt-r-ml-8 {
  --margin-left: calc(-1 * var(--space-8));
}

.-rt-r-ml-9 {
  --margin-left: calc(-1 * var(--space-9));
}

@media (min-width: 520px) {
  .xs\:rt-r-ml, .xs\:rt-r-ml-0, .xs\:rt-r-ml-1, .xs\:rt-r-ml-2, .xs\:rt-r-ml-3, .xs\:rt-r-ml-4, .xs\:rt-r-ml-5, .xs\:rt-r-ml-6, .xs\:rt-r-ml-7, .xs\:rt-r-ml-8, .xs\:rt-r-ml-9, .xs\:-rt-r-ml-1, .xs\:-rt-r-ml-2, .xs\:-rt-r-ml-3, .xs\:-rt-r-ml-4, .xs\:-rt-r-ml-5, .xs\:-rt-r-ml-6, .xs\:-rt-r-ml-7, .xs\:-rt-r-ml-8, .xs\:-rt-r-ml-9 {
    margin-left: var(--margin-left-override, var(--margin-left));
  }

  .xs\:rt-r-ml {
    --margin-left: var(--ml-xs);
  }

  .xs\:rt-r-ml-0 {
    --margin-left: 0px;
  }

  .xs\:rt-r-ml-1 {
    --margin-left: var(--space-1);
  }

  .xs\:rt-r-ml-2 {
    --margin-left: var(--space-2);
  }

  .xs\:rt-r-ml-3 {
    --margin-left: var(--space-3);
  }

  .xs\:rt-r-ml-4 {
    --margin-left: var(--space-4);
  }

  .xs\:rt-r-ml-5 {
    --margin-left: var(--space-5);
  }

  .xs\:rt-r-ml-6 {
    --margin-left: var(--space-6);
  }

  .xs\:rt-r-ml-7 {
    --margin-left: var(--space-7);
  }

  .xs\:rt-r-ml-8 {
    --margin-left: var(--space-8);
  }

  .xs\:rt-r-ml-9 {
    --margin-left: var(--space-9);
  }

  .xs\:-rt-r-ml-1 {
    --margin-left: calc(-1 * var(--space-1));
  }

  .xs\:-rt-r-ml-2 {
    --margin-left: calc(-1 * var(--space-2));
  }

  .xs\:-rt-r-ml-3 {
    --margin-left: calc(-1 * var(--space-3));
  }

  .xs\:-rt-r-ml-4 {
    --margin-left: calc(-1 * var(--space-4));
  }

  .xs\:-rt-r-ml-5 {
    --margin-left: calc(-1 * var(--space-5));
  }

  .xs\:-rt-r-ml-6 {
    --margin-left: calc(-1 * var(--space-6));
  }

  .xs\:-rt-r-ml-7 {
    --margin-left: calc(-1 * var(--space-7));
  }

  .xs\:-rt-r-ml-8 {
    --margin-left: calc(-1 * var(--space-8));
  }

  .xs\:-rt-r-ml-9 {
    --margin-left: calc(-1 * var(--space-9));
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-ml, .sm\:rt-r-ml-0, .sm\:rt-r-ml-1, .sm\:rt-r-ml-2, .sm\:rt-r-ml-3, .sm\:rt-r-ml-4, .sm\:rt-r-ml-5, .sm\:rt-r-ml-6, .sm\:rt-r-ml-7, .sm\:rt-r-ml-8, .sm\:rt-r-ml-9, .sm\:-rt-r-ml-1, .sm\:-rt-r-ml-2, .sm\:-rt-r-ml-3, .sm\:-rt-r-ml-4, .sm\:-rt-r-ml-5, .sm\:-rt-r-ml-6, .sm\:-rt-r-ml-7, .sm\:-rt-r-ml-8, .sm\:-rt-r-ml-9 {
    margin-left: var(--margin-left-override, var(--margin-left));
  }

  .sm\:rt-r-ml {
    --margin-left: var(--ml-sm);
  }

  .sm\:rt-r-ml-0 {
    --margin-left: 0px;
  }

  .sm\:rt-r-ml-1 {
    --margin-left: var(--space-1);
  }

  .sm\:rt-r-ml-2 {
    --margin-left: var(--space-2);
  }

  .sm\:rt-r-ml-3 {
    --margin-left: var(--space-3);
  }

  .sm\:rt-r-ml-4 {
    --margin-left: var(--space-4);
  }

  .sm\:rt-r-ml-5 {
    --margin-left: var(--space-5);
  }

  .sm\:rt-r-ml-6 {
    --margin-left: var(--space-6);
  }

  .sm\:rt-r-ml-7 {
    --margin-left: var(--space-7);
  }

  .sm\:rt-r-ml-8 {
    --margin-left: var(--space-8);
  }

  .sm\:rt-r-ml-9 {
    --margin-left: var(--space-9);
  }

  .sm\:-rt-r-ml-1 {
    --margin-left: calc(-1 * var(--space-1));
  }

  .sm\:-rt-r-ml-2 {
    --margin-left: calc(-1 * var(--space-2));
  }

  .sm\:-rt-r-ml-3 {
    --margin-left: calc(-1 * var(--space-3));
  }

  .sm\:-rt-r-ml-4 {
    --margin-left: calc(-1 * var(--space-4));
  }

  .sm\:-rt-r-ml-5 {
    --margin-left: calc(-1 * var(--space-5));
  }

  .sm\:-rt-r-ml-6 {
    --margin-left: calc(-1 * var(--space-6));
  }

  .sm\:-rt-r-ml-7 {
    --margin-left: calc(-1 * var(--space-7));
  }

  .sm\:-rt-r-ml-8 {
    --margin-left: calc(-1 * var(--space-8));
  }

  .sm\:-rt-r-ml-9 {
    --margin-left: calc(-1 * var(--space-9));
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-ml, .md\:rt-r-ml-0, .md\:rt-r-ml-1, .md\:rt-r-ml-2, .md\:rt-r-ml-3, .md\:rt-r-ml-4, .md\:rt-r-ml-5, .md\:rt-r-ml-6, .md\:rt-r-ml-7, .md\:rt-r-ml-8, .md\:rt-r-ml-9, .md\:-rt-r-ml-1, .md\:-rt-r-ml-2, .md\:-rt-r-ml-3, .md\:-rt-r-ml-4, .md\:-rt-r-ml-5, .md\:-rt-r-ml-6, .md\:-rt-r-ml-7, .md\:-rt-r-ml-8, .md\:-rt-r-ml-9 {
    margin-left: var(--margin-left-override, var(--margin-left));
  }

  .md\:rt-r-ml {
    --margin-left: var(--ml-md);
  }

  .md\:rt-r-ml-0 {
    --margin-left: 0px;
  }

  .md\:rt-r-ml-1 {
    --margin-left: var(--space-1);
  }

  .md\:rt-r-ml-2 {
    --margin-left: var(--space-2);
  }

  .md\:rt-r-ml-3 {
    --margin-left: var(--space-3);
  }

  .md\:rt-r-ml-4 {
    --margin-left: var(--space-4);
  }

  .md\:rt-r-ml-5 {
    --margin-left: var(--space-5);
  }

  .md\:rt-r-ml-6 {
    --margin-left: var(--space-6);
  }

  .md\:rt-r-ml-7 {
    --margin-left: var(--space-7);
  }

  .md\:rt-r-ml-8 {
    --margin-left: var(--space-8);
  }

  .md\:rt-r-ml-9 {
    --margin-left: var(--space-9);
  }

  .md\:-rt-r-ml-1 {
    --margin-left: calc(-1 * var(--space-1));
  }

  .md\:-rt-r-ml-2 {
    --margin-left: calc(-1 * var(--space-2));
  }

  .md\:-rt-r-ml-3 {
    --margin-left: calc(-1 * var(--space-3));
  }

  .md\:-rt-r-ml-4 {
    --margin-left: calc(-1 * var(--space-4));
  }

  .md\:-rt-r-ml-5 {
    --margin-left: calc(-1 * var(--space-5));
  }

  .md\:-rt-r-ml-6 {
    --margin-left: calc(-1 * var(--space-6));
  }

  .md\:-rt-r-ml-7 {
    --margin-left: calc(-1 * var(--space-7));
  }

  .md\:-rt-r-ml-8 {
    --margin-left: calc(-1 * var(--space-8));
  }

  .md\:-rt-r-ml-9 {
    --margin-left: calc(-1 * var(--space-9));
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-ml, .lg\:rt-r-ml-0, .lg\:rt-r-ml-1, .lg\:rt-r-ml-2, .lg\:rt-r-ml-3, .lg\:rt-r-ml-4, .lg\:rt-r-ml-5, .lg\:rt-r-ml-6, .lg\:rt-r-ml-7, .lg\:rt-r-ml-8, .lg\:rt-r-ml-9, .lg\:-rt-r-ml-1, .lg\:-rt-r-ml-2, .lg\:-rt-r-ml-3, .lg\:-rt-r-ml-4, .lg\:-rt-r-ml-5, .lg\:-rt-r-ml-6, .lg\:-rt-r-ml-7, .lg\:-rt-r-ml-8, .lg\:-rt-r-ml-9 {
    margin-left: var(--margin-left-override, var(--margin-left));
  }

  .lg\:rt-r-ml {
    --margin-left: var(--ml-lg);
  }

  .lg\:rt-r-ml-0 {
    --margin-left: 0px;
  }

  .lg\:rt-r-ml-1 {
    --margin-left: var(--space-1);
  }

  .lg\:rt-r-ml-2 {
    --margin-left: var(--space-2);
  }

  .lg\:rt-r-ml-3 {
    --margin-left: var(--space-3);
  }

  .lg\:rt-r-ml-4 {
    --margin-left: var(--space-4);
  }

  .lg\:rt-r-ml-5 {
    --margin-left: var(--space-5);
  }

  .lg\:rt-r-ml-6 {
    --margin-left: var(--space-6);
  }

  .lg\:rt-r-ml-7 {
    --margin-left: var(--space-7);
  }

  .lg\:rt-r-ml-8 {
    --margin-left: var(--space-8);
  }

  .lg\:rt-r-ml-9 {
    --margin-left: var(--space-9);
  }

  .lg\:-rt-r-ml-1 {
    --margin-left: calc(-1 * var(--space-1));
  }

  .lg\:-rt-r-ml-2 {
    --margin-left: calc(-1 * var(--space-2));
  }

  .lg\:-rt-r-ml-3 {
    --margin-left: calc(-1 * var(--space-3));
  }

  .lg\:-rt-r-ml-4 {
    --margin-left: calc(-1 * var(--space-4));
  }

  .lg\:-rt-r-ml-5 {
    --margin-left: calc(-1 * var(--space-5));
  }

  .lg\:-rt-r-ml-6 {
    --margin-left: calc(-1 * var(--space-6));
  }

  .lg\:-rt-r-ml-7 {
    --margin-left: calc(-1 * var(--space-7));
  }

  .lg\:-rt-r-ml-8 {
    --margin-left: calc(-1 * var(--space-8));
  }

  .lg\:-rt-r-ml-9 {
    --margin-left: calc(-1 * var(--space-9));
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-ml, .xl\:rt-r-ml-0, .xl\:rt-r-ml-1, .xl\:rt-r-ml-2, .xl\:rt-r-ml-3, .xl\:rt-r-ml-4, .xl\:rt-r-ml-5, .xl\:rt-r-ml-6, .xl\:rt-r-ml-7, .xl\:rt-r-ml-8, .xl\:rt-r-ml-9, .xl\:-rt-r-ml-1, .xl\:-rt-r-ml-2, .xl\:-rt-r-ml-3, .xl\:-rt-r-ml-4, .xl\:-rt-r-ml-5, .xl\:-rt-r-ml-6, .xl\:-rt-r-ml-7, .xl\:-rt-r-ml-8, .xl\:-rt-r-ml-9 {
    margin-left: var(--margin-left-override, var(--margin-left));
  }

  .xl\:rt-r-ml {
    --margin-left: var(--ml-xl);
  }

  .xl\:rt-r-ml-0 {
    --margin-left: 0px;
  }

  .xl\:rt-r-ml-1 {
    --margin-left: var(--space-1);
  }

  .xl\:rt-r-ml-2 {
    --margin-left: var(--space-2);
  }

  .xl\:rt-r-ml-3 {
    --margin-left: var(--space-3);
  }

  .xl\:rt-r-ml-4 {
    --margin-left: var(--space-4);
  }

  .xl\:rt-r-ml-5 {
    --margin-left: var(--space-5);
  }

  .xl\:rt-r-ml-6 {
    --margin-left: var(--space-6);
  }

  .xl\:rt-r-ml-7 {
    --margin-left: var(--space-7);
  }

  .xl\:rt-r-ml-8 {
    --margin-left: var(--space-8);
  }

  .xl\:rt-r-ml-9 {
    --margin-left: var(--space-9);
  }

  .xl\:-rt-r-ml-1 {
    --margin-left: calc(-1 * var(--space-1));
  }

  .xl\:-rt-r-ml-2 {
    --margin-left: calc(-1 * var(--space-2));
  }

  .xl\:-rt-r-ml-3 {
    --margin-left: calc(-1 * var(--space-3));
  }

  .xl\:-rt-r-ml-4 {
    --margin-left: calc(-1 * var(--space-4));
  }

  .xl\:-rt-r-ml-5 {
    --margin-left: calc(-1 * var(--space-5));
  }

  .xl\:-rt-r-ml-6 {
    --margin-left: calc(-1 * var(--space-6));
  }

  .xl\:-rt-r-ml-7 {
    --margin-left: calc(-1 * var(--space-7));
  }

  .xl\:-rt-r-ml-8 {
    --margin-left: calc(-1 * var(--space-8));
  }

  .xl\:-rt-r-ml-9 {
    --margin-left: calc(-1 * var(--space-9));
  }
}

.rt-r-overflow-visible {
  overflow: visible;
}

.rt-r-overflow-hidden {
  overflow: hidden;
}

.rt-r-overflow-clip {
  overflow: clip;
}

.rt-r-overflow-scroll {
  overflow: scroll;
}

.rt-r-overflow-auto {
  overflow: auto;
}

.rt-r-ox-visible {
  overflow-x: visible;
}

.rt-r-ox-hidden {
  overflow-x: hidden;
}

.rt-r-ox-clip {
  overflow-x: clip;
}

.rt-r-ox-scroll {
  overflow-x: scroll;
}

.rt-r-ox-auto {
  overflow-x: auto;
}

.rt-r-oy-visible {
  overflow-y: visible;
}

.rt-r-oy-hidden {
  overflow-y: hidden;
}

.rt-r-oy-clip {
  overflow-y: clip;
}

.rt-r-oy-scroll {
  overflow-y: scroll;
}

.rt-r-oy-auto {
  overflow-y: auto;
}

@media (min-width: 520px) {
  .xs\:rt-r-overflow-visible {
    overflow: visible;
  }

  .xs\:rt-r-overflow-hidden {
    overflow: hidden;
  }

  .xs\:rt-r-overflow-clip {
    overflow: clip;
  }

  .xs\:rt-r-overflow-scroll {
    overflow: scroll;
  }

  .xs\:rt-r-overflow-auto {
    overflow: auto;
  }

  .xs\:rt-r-ox-visible {
    overflow-x: visible;
  }

  .xs\:rt-r-ox-hidden {
    overflow-x: hidden;
  }

  .xs\:rt-r-ox-clip {
    overflow-x: clip;
  }

  .xs\:rt-r-ox-scroll {
    overflow-x: scroll;
  }

  .xs\:rt-r-ox-auto {
    overflow-x: auto;
  }

  .xs\:rt-r-oy-visible {
    overflow-y: visible;
  }

  .xs\:rt-r-oy-hidden {
    overflow-y: hidden;
  }

  .xs\:rt-r-oy-clip {
    overflow-y: clip;
  }

  .xs\:rt-r-oy-scroll {
    overflow-y: scroll;
  }

  .xs\:rt-r-oy-auto {
    overflow-y: auto;
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-overflow-visible {
    overflow: visible;
  }

  .sm\:rt-r-overflow-hidden {
    overflow: hidden;
  }

  .sm\:rt-r-overflow-clip {
    overflow: clip;
  }

  .sm\:rt-r-overflow-scroll {
    overflow: scroll;
  }

  .sm\:rt-r-overflow-auto {
    overflow: auto;
  }

  .sm\:rt-r-ox-visible {
    overflow-x: visible;
  }

  .sm\:rt-r-ox-hidden {
    overflow-x: hidden;
  }

  .sm\:rt-r-ox-clip {
    overflow-x: clip;
  }

  .sm\:rt-r-ox-scroll {
    overflow-x: scroll;
  }

  .sm\:rt-r-ox-auto {
    overflow-x: auto;
  }

  .sm\:rt-r-oy-visible {
    overflow-y: visible;
  }

  .sm\:rt-r-oy-hidden {
    overflow-y: hidden;
  }

  .sm\:rt-r-oy-clip {
    overflow-y: clip;
  }

  .sm\:rt-r-oy-scroll {
    overflow-y: scroll;
  }

  .sm\:rt-r-oy-auto {
    overflow-y: auto;
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-overflow-visible {
    overflow: visible;
  }

  .md\:rt-r-overflow-hidden {
    overflow: hidden;
  }

  .md\:rt-r-overflow-clip {
    overflow: clip;
  }

  .md\:rt-r-overflow-scroll {
    overflow: scroll;
  }

  .md\:rt-r-overflow-auto {
    overflow: auto;
  }

  .md\:rt-r-ox-visible {
    overflow-x: visible;
  }

  .md\:rt-r-ox-hidden {
    overflow-x: hidden;
  }

  .md\:rt-r-ox-clip {
    overflow-x: clip;
  }

  .md\:rt-r-ox-scroll {
    overflow-x: scroll;
  }

  .md\:rt-r-ox-auto {
    overflow-x: auto;
  }

  .md\:rt-r-oy-visible {
    overflow-y: visible;
  }

  .md\:rt-r-oy-hidden {
    overflow-y: hidden;
  }

  .md\:rt-r-oy-clip {
    overflow-y: clip;
  }

  .md\:rt-r-oy-scroll {
    overflow-y: scroll;
  }

  .md\:rt-r-oy-auto {
    overflow-y: auto;
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-overflow-visible {
    overflow: visible;
  }

  .lg\:rt-r-overflow-hidden {
    overflow: hidden;
  }

  .lg\:rt-r-overflow-clip {
    overflow: clip;
  }

  .lg\:rt-r-overflow-scroll {
    overflow: scroll;
  }

  .lg\:rt-r-overflow-auto {
    overflow: auto;
  }

  .lg\:rt-r-ox-visible {
    overflow-x: visible;
  }

  .lg\:rt-r-ox-hidden {
    overflow-x: hidden;
  }

  .lg\:rt-r-ox-clip {
    overflow-x: clip;
  }

  .lg\:rt-r-ox-scroll {
    overflow-x: scroll;
  }

  .lg\:rt-r-ox-auto {
    overflow-x: auto;
  }

  .lg\:rt-r-oy-visible {
    overflow-y: visible;
  }

  .lg\:rt-r-oy-hidden {
    overflow-y: hidden;
  }

  .lg\:rt-r-oy-clip {
    overflow-y: clip;
  }

  .lg\:rt-r-oy-scroll {
    overflow-y: scroll;
  }

  .lg\:rt-r-oy-auto {
    overflow-y: auto;
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-overflow-visible {
    overflow: visible;
  }

  .xl\:rt-r-overflow-hidden {
    overflow: hidden;
  }

  .xl\:rt-r-overflow-clip {
    overflow: clip;
  }

  .xl\:rt-r-overflow-scroll {
    overflow: scroll;
  }

  .xl\:rt-r-overflow-auto {
    overflow: auto;
  }

  .xl\:rt-r-ox-visible {
    overflow-x: visible;
  }

  .xl\:rt-r-ox-hidden {
    overflow-x: hidden;
  }

  .xl\:rt-r-ox-clip {
    overflow-x: clip;
  }

  .xl\:rt-r-ox-scroll {
    overflow-x: scroll;
  }

  .xl\:rt-r-ox-auto {
    overflow-x: auto;
  }

  .xl\:rt-r-oy-visible {
    overflow-y: visible;
  }

  .xl\:rt-r-oy-hidden {
    overflow-y: hidden;
  }

  .xl\:rt-r-oy-clip {
    overflow-y: clip;
  }

  .xl\:rt-r-oy-scroll {
    overflow-y: scroll;
  }

  .xl\:rt-r-oy-auto {
    overflow-y: auto;
  }
}

.rt-r-p {
  padding: var(--p);
}

.rt-r-p-0 {
  padding: 0;
}

.rt-r-p-1 {
  padding: var(--space-1);
}

.rt-r-p-2 {
  padding: var(--space-2);
}

.rt-r-p-3 {
  padding: var(--space-3);
}

.rt-r-p-4 {
  padding: var(--space-4);
}

.rt-r-p-5 {
  padding: var(--space-5);
}

.rt-r-p-6 {
  padding: var(--space-6);
}

.rt-r-p-7 {
  padding: var(--space-7);
}

.rt-r-p-8 {
  padding: var(--space-8);
}

.rt-r-p-9 {
  padding: var(--space-9);
}

.rt-r-p-inset {
  padding-top: var(--inset-padding-top);
  padding-right: var(--inset-padding-right);
  padding-bottom: var(--inset-padding-bottom);
  padding-left: var(--inset-padding-left);
}

@media (min-width: 520px) {
  .xs\:rt-r-p {
    padding: var(--p-xs);
  }

  .xs\:rt-r-p-0 {
    padding: 0;
  }

  .xs\:rt-r-p-1 {
    padding: var(--space-1);
  }

  .xs\:rt-r-p-2 {
    padding: var(--space-2);
  }

  .xs\:rt-r-p-3 {
    padding: var(--space-3);
  }

  .xs\:rt-r-p-4 {
    padding: var(--space-4);
  }

  .xs\:rt-r-p-5 {
    padding: var(--space-5);
  }

  .xs\:rt-r-p-6 {
    padding: var(--space-6);
  }

  .xs\:rt-r-p-7 {
    padding: var(--space-7);
  }

  .xs\:rt-r-p-8 {
    padding: var(--space-8);
  }

  .xs\:rt-r-p-9 {
    padding: var(--space-9);
  }

  .xs\:rt-r-p-inset {
    padding-top: var(--inset-padding-top);
    padding-right: var(--inset-padding-right);
    padding-bottom: var(--inset-padding-bottom);
    padding-left: var(--inset-padding-left);
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-p {
    padding: var(--p-sm);
  }

  .sm\:rt-r-p-0 {
    padding: 0;
  }

  .sm\:rt-r-p-1 {
    padding: var(--space-1);
  }

  .sm\:rt-r-p-2 {
    padding: var(--space-2);
  }

  .sm\:rt-r-p-3 {
    padding: var(--space-3);
  }

  .sm\:rt-r-p-4 {
    padding: var(--space-4);
  }

  .sm\:rt-r-p-5 {
    padding: var(--space-5);
  }

  .sm\:rt-r-p-6 {
    padding: var(--space-6);
  }

  .sm\:rt-r-p-7 {
    padding: var(--space-7);
  }

  .sm\:rt-r-p-8 {
    padding: var(--space-8);
  }

  .sm\:rt-r-p-9 {
    padding: var(--space-9);
  }

  .sm\:rt-r-p-inset {
    padding-top: var(--inset-padding-top);
    padding-right: var(--inset-padding-right);
    padding-bottom: var(--inset-padding-bottom);
    padding-left: var(--inset-padding-left);
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-p {
    padding: var(--p-md);
  }

  .md\:rt-r-p-0 {
    padding: 0;
  }

  .md\:rt-r-p-1 {
    padding: var(--space-1);
  }

  .md\:rt-r-p-2 {
    padding: var(--space-2);
  }

  .md\:rt-r-p-3 {
    padding: var(--space-3);
  }

  .md\:rt-r-p-4 {
    padding: var(--space-4);
  }

  .md\:rt-r-p-5 {
    padding: var(--space-5);
  }

  .md\:rt-r-p-6 {
    padding: var(--space-6);
  }

  .md\:rt-r-p-7 {
    padding: var(--space-7);
  }

  .md\:rt-r-p-8 {
    padding: var(--space-8);
  }

  .md\:rt-r-p-9 {
    padding: var(--space-9);
  }

  .md\:rt-r-p-inset {
    padding-top: var(--inset-padding-top);
    padding-right: var(--inset-padding-right);
    padding-bottom: var(--inset-padding-bottom);
    padding-left: var(--inset-padding-left);
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-p {
    padding: var(--p-lg);
  }

  .lg\:rt-r-p-0 {
    padding: 0;
  }

  .lg\:rt-r-p-1 {
    padding: var(--space-1);
  }

  .lg\:rt-r-p-2 {
    padding: var(--space-2);
  }

  .lg\:rt-r-p-3 {
    padding: var(--space-3);
  }

  .lg\:rt-r-p-4 {
    padding: var(--space-4);
  }

  .lg\:rt-r-p-5 {
    padding: var(--space-5);
  }

  .lg\:rt-r-p-6 {
    padding: var(--space-6);
  }

  .lg\:rt-r-p-7 {
    padding: var(--space-7);
  }

  .lg\:rt-r-p-8 {
    padding: var(--space-8);
  }

  .lg\:rt-r-p-9 {
    padding: var(--space-9);
  }

  .lg\:rt-r-p-inset {
    padding-top: var(--inset-padding-top);
    padding-right: var(--inset-padding-right);
    padding-bottom: var(--inset-padding-bottom);
    padding-left: var(--inset-padding-left);
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-p {
    padding: var(--p-xl);
  }

  .xl\:rt-r-p-0 {
    padding: 0;
  }

  .xl\:rt-r-p-1 {
    padding: var(--space-1);
  }

  .xl\:rt-r-p-2 {
    padding: var(--space-2);
  }

  .xl\:rt-r-p-3 {
    padding: var(--space-3);
  }

  .xl\:rt-r-p-4 {
    padding: var(--space-4);
  }

  .xl\:rt-r-p-5 {
    padding: var(--space-5);
  }

  .xl\:rt-r-p-6 {
    padding: var(--space-6);
  }

  .xl\:rt-r-p-7 {
    padding: var(--space-7);
  }

  .xl\:rt-r-p-8 {
    padding: var(--space-8);
  }

  .xl\:rt-r-p-9 {
    padding: var(--space-9);
  }

  .xl\:rt-r-p-inset {
    padding-top: var(--inset-padding-top);
    padding-right: var(--inset-padding-right);
    padding-bottom: var(--inset-padding-bottom);
    padding-left: var(--inset-padding-left);
  }
}

.rt-r-px {
  padding-left: var(--pl);
  padding-right: var(--pr);
}

.rt-r-px-0 {
  padding-left: 0;
  padding-right: 0;
}

.rt-r-px-1 {
  padding-left: var(--space-1);
  padding-right: var(--space-1);
}

.rt-r-px-2 {
  padding-left: var(--space-2);
  padding-right: var(--space-2);
}

.rt-r-px-3 {
  padding-left: var(--space-3);
  padding-right: var(--space-3);
}

.rt-r-px-4 {
  padding-left: var(--space-4);
  padding-right: var(--space-4);
}

.rt-r-px-5 {
  padding-left: var(--space-5);
  padding-right: var(--space-5);
}

.rt-r-px-6 {
  padding-left: var(--space-6);
  padding-right: var(--space-6);
}

.rt-r-px-7 {
  padding-left: var(--space-7);
  padding-right: var(--space-7);
}

.rt-r-px-8 {
  padding-left: var(--space-8);
  padding-right: var(--space-8);
}

.rt-r-px-9 {
  padding-left: var(--space-9);
  padding-right: var(--space-9);
}

.rt-r-px-inset {
  padding-left: var(--inset-padding-left);
  padding-right: var(--inset-padding-right);
}

@media (min-width: 520px) {
  .xs\:rt-r-px {
    padding-left: var(--pl-xs);
    padding-right: var(--pr-xs);
  }

  .xs\:rt-r-px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .xs\:rt-r-px-1 {
    padding-left: var(--space-1);
    padding-right: var(--space-1);
  }

  .xs\:rt-r-px-2 {
    padding-left: var(--space-2);
    padding-right: var(--space-2);
  }

  .xs\:rt-r-px-3 {
    padding-left: var(--space-3);
    padding-right: var(--space-3);
  }

  .xs\:rt-r-px-4 {
    padding-left: var(--space-4);
    padding-right: var(--space-4);
  }

  .xs\:rt-r-px-5 {
    padding-left: var(--space-5);
    padding-right: var(--space-5);
  }

  .xs\:rt-r-px-6 {
    padding-left: var(--space-6);
    padding-right: var(--space-6);
  }

  .xs\:rt-r-px-7 {
    padding-left: var(--space-7);
    padding-right: var(--space-7);
  }

  .xs\:rt-r-px-8 {
    padding-left: var(--space-8);
    padding-right: var(--space-8);
  }

  .xs\:rt-r-px-9 {
    padding-left: var(--space-9);
    padding-right: var(--space-9);
  }

  .xs\:rt-r-px-inset {
    padding-left: var(--inset-padding-left);
    padding-right: var(--inset-padding-right);
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-px {
    padding-left: var(--pl-sm);
    padding-right: var(--pr-sm);
  }

  .sm\:rt-r-px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .sm\:rt-r-px-1 {
    padding-left: var(--space-1);
    padding-right: var(--space-1);
  }

  .sm\:rt-r-px-2 {
    padding-left: var(--space-2);
    padding-right: var(--space-2);
  }

  .sm\:rt-r-px-3 {
    padding-left: var(--space-3);
    padding-right: var(--space-3);
  }

  .sm\:rt-r-px-4 {
    padding-left: var(--space-4);
    padding-right: var(--space-4);
  }

  .sm\:rt-r-px-5 {
    padding-left: var(--space-5);
    padding-right: var(--space-5);
  }

  .sm\:rt-r-px-6 {
    padding-left: var(--space-6);
    padding-right: var(--space-6);
  }

  .sm\:rt-r-px-7 {
    padding-left: var(--space-7);
    padding-right: var(--space-7);
  }

  .sm\:rt-r-px-8 {
    padding-left: var(--space-8);
    padding-right: var(--space-8);
  }

  .sm\:rt-r-px-9 {
    padding-left: var(--space-9);
    padding-right: var(--space-9);
  }

  .sm\:rt-r-px-inset {
    padding-left: var(--inset-padding-left);
    padding-right: var(--inset-padding-right);
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-px {
    padding-left: var(--pl-md);
    padding-right: var(--pr-md);
  }

  .md\:rt-r-px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .md\:rt-r-px-1 {
    padding-left: var(--space-1);
    padding-right: var(--space-1);
  }

  .md\:rt-r-px-2 {
    padding-left: var(--space-2);
    padding-right: var(--space-2);
  }

  .md\:rt-r-px-3 {
    padding-left: var(--space-3);
    padding-right: var(--space-3);
  }

  .md\:rt-r-px-4 {
    padding-left: var(--space-4);
    padding-right: var(--space-4);
  }

  .md\:rt-r-px-5 {
    padding-left: var(--space-5);
    padding-right: var(--space-5);
  }

  .md\:rt-r-px-6 {
    padding-left: var(--space-6);
    padding-right: var(--space-6);
  }

  .md\:rt-r-px-7 {
    padding-left: var(--space-7);
    padding-right: var(--space-7);
  }

  .md\:rt-r-px-8 {
    padding-left: var(--space-8);
    padding-right: var(--space-8);
  }

  .md\:rt-r-px-9 {
    padding-left: var(--space-9);
    padding-right: var(--space-9);
  }

  .md\:rt-r-px-inset {
    padding-left: var(--inset-padding-left);
    padding-right: var(--inset-padding-right);
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-px {
    padding-left: var(--pl-lg);
    padding-right: var(--pr-lg);
  }

  .lg\:rt-r-px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .lg\:rt-r-px-1 {
    padding-left: var(--space-1);
    padding-right: var(--space-1);
  }

  .lg\:rt-r-px-2 {
    padding-left: var(--space-2);
    padding-right: var(--space-2);
  }

  .lg\:rt-r-px-3 {
    padding-left: var(--space-3);
    padding-right: var(--space-3);
  }

  .lg\:rt-r-px-4 {
    padding-left: var(--space-4);
    padding-right: var(--space-4);
  }

  .lg\:rt-r-px-5 {
    padding-left: var(--space-5);
    padding-right: var(--space-5);
  }

  .lg\:rt-r-px-6 {
    padding-left: var(--space-6);
    padding-right: var(--space-6);
  }

  .lg\:rt-r-px-7 {
    padding-left: var(--space-7);
    padding-right: var(--space-7);
  }

  .lg\:rt-r-px-8 {
    padding-left: var(--space-8);
    padding-right: var(--space-8);
  }

  .lg\:rt-r-px-9 {
    padding-left: var(--space-9);
    padding-right: var(--space-9);
  }

  .lg\:rt-r-px-inset {
    padding-left: var(--inset-padding-left);
    padding-right: var(--inset-padding-right);
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-px {
    padding-left: var(--pl-xl);
    padding-right: var(--pr-xl);
  }

  .xl\:rt-r-px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .xl\:rt-r-px-1 {
    padding-left: var(--space-1);
    padding-right: var(--space-1);
  }

  .xl\:rt-r-px-2 {
    padding-left: var(--space-2);
    padding-right: var(--space-2);
  }

  .xl\:rt-r-px-3 {
    padding-left: var(--space-3);
    padding-right: var(--space-3);
  }

  .xl\:rt-r-px-4 {
    padding-left: var(--space-4);
    padding-right: var(--space-4);
  }

  .xl\:rt-r-px-5 {
    padding-left: var(--space-5);
    padding-right: var(--space-5);
  }

  .xl\:rt-r-px-6 {
    padding-left: var(--space-6);
    padding-right: var(--space-6);
  }

  .xl\:rt-r-px-7 {
    padding-left: var(--space-7);
    padding-right: var(--space-7);
  }

  .xl\:rt-r-px-8 {
    padding-left: var(--space-8);
    padding-right: var(--space-8);
  }

  .xl\:rt-r-px-9 {
    padding-left: var(--space-9);
    padding-right: var(--space-9);
  }

  .xl\:rt-r-px-inset {
    padding-left: var(--inset-padding-left);
    padding-right: var(--inset-padding-right);
  }
}

.rt-r-py {
  padding-top: var(--pt);
  padding-bottom: var(--pb);
}

.rt-r-py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.rt-r-py-1 {
  padding-top: var(--space-1);
  padding-bottom: var(--space-1);
}

.rt-r-py-2 {
  padding-top: var(--space-2);
  padding-bottom: var(--space-2);
}

.rt-r-py-3 {
  padding-top: var(--space-3);
  padding-bottom: var(--space-3);
}

.rt-r-py-4 {
  padding-top: var(--space-4);
  padding-bottom: var(--space-4);
}

.rt-r-py-5 {
  padding-top: var(--space-5);
  padding-bottom: var(--space-5);
}

.rt-r-py-6 {
  padding-top: var(--space-6);
  padding-bottom: var(--space-6);
}

.rt-r-py-7 {
  padding-top: var(--space-7);
  padding-bottom: var(--space-7);
}

.rt-r-py-8 {
  padding-top: var(--space-8);
  padding-bottom: var(--space-8);
}

.rt-r-py-9 {
  padding-top: var(--space-9);
  padding-bottom: var(--space-9);
}

.rt-r-py-inset {
  padding-top: var(--inset-padding-top);
  padding-bottom: var(--inset-padding-bottom);
}

@media (min-width: 520px) {
  .xs\:rt-r-py {
    padding-top: var(--pt-xs);
    padding-bottom: var(--pb-xs);
  }

  .xs\:rt-r-py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .xs\:rt-r-py-1 {
    padding-top: var(--space-1);
    padding-bottom: var(--space-1);
  }

  .xs\:rt-r-py-2 {
    padding-top: var(--space-2);
    padding-bottom: var(--space-2);
  }

  .xs\:rt-r-py-3 {
    padding-top: var(--space-3);
    padding-bottom: var(--space-3);
  }

  .xs\:rt-r-py-4 {
    padding-top: var(--space-4);
    padding-bottom: var(--space-4);
  }

  .xs\:rt-r-py-5 {
    padding-top: var(--space-5);
    padding-bottom: var(--space-5);
  }

  .xs\:rt-r-py-6 {
    padding-top: var(--space-6);
    padding-bottom: var(--space-6);
  }

  .xs\:rt-r-py-7 {
    padding-top: var(--space-7);
    padding-bottom: var(--space-7);
  }

  .xs\:rt-r-py-8 {
    padding-top: var(--space-8);
    padding-bottom: var(--space-8);
  }

  .xs\:rt-r-py-9 {
    padding-top: var(--space-9);
    padding-bottom: var(--space-9);
  }

  .xs\:rt-r-py-inset {
    padding-top: var(--inset-padding-top);
    padding-bottom: var(--inset-padding-bottom);
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-py {
    padding-top: var(--pt-sm);
    padding-bottom: var(--pb-sm);
  }

  .sm\:rt-r-py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .sm\:rt-r-py-1 {
    padding-top: var(--space-1);
    padding-bottom: var(--space-1);
  }

  .sm\:rt-r-py-2 {
    padding-top: var(--space-2);
    padding-bottom: var(--space-2);
  }

  .sm\:rt-r-py-3 {
    padding-top: var(--space-3);
    padding-bottom: var(--space-3);
  }

  .sm\:rt-r-py-4 {
    padding-top: var(--space-4);
    padding-bottom: var(--space-4);
  }

  .sm\:rt-r-py-5 {
    padding-top: var(--space-5);
    padding-bottom: var(--space-5);
  }

  .sm\:rt-r-py-6 {
    padding-top: var(--space-6);
    padding-bottom: var(--space-6);
  }

  .sm\:rt-r-py-7 {
    padding-top: var(--space-7);
    padding-bottom: var(--space-7);
  }

  .sm\:rt-r-py-8 {
    padding-top: var(--space-8);
    padding-bottom: var(--space-8);
  }

  .sm\:rt-r-py-9 {
    padding-top: var(--space-9);
    padding-bottom: var(--space-9);
  }

  .sm\:rt-r-py-inset {
    padding-top: var(--inset-padding-top);
    padding-bottom: var(--inset-padding-bottom);
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-py {
    padding-top: var(--pt-md);
    padding-bottom: var(--pb-md);
  }

  .md\:rt-r-py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .md\:rt-r-py-1 {
    padding-top: var(--space-1);
    padding-bottom: var(--space-1);
  }

  .md\:rt-r-py-2 {
    padding-top: var(--space-2);
    padding-bottom: var(--space-2);
  }

  .md\:rt-r-py-3 {
    padding-top: var(--space-3);
    padding-bottom: var(--space-3);
  }

  .md\:rt-r-py-4 {
    padding-top: var(--space-4);
    padding-bottom: var(--space-4);
  }

  .md\:rt-r-py-5 {
    padding-top: var(--space-5);
    padding-bottom: var(--space-5);
  }

  .md\:rt-r-py-6 {
    padding-top: var(--space-6);
    padding-bottom: var(--space-6);
  }

  .md\:rt-r-py-7 {
    padding-top: var(--space-7);
    padding-bottom: var(--space-7);
  }

  .md\:rt-r-py-8 {
    padding-top: var(--space-8);
    padding-bottom: var(--space-8);
  }

  .md\:rt-r-py-9 {
    padding-top: var(--space-9);
    padding-bottom: var(--space-9);
  }

  .md\:rt-r-py-inset {
    padding-top: var(--inset-padding-top);
    padding-bottom: var(--inset-padding-bottom);
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-py {
    padding-top: var(--pt-lg);
    padding-bottom: var(--pb-lg);
  }

  .lg\:rt-r-py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .lg\:rt-r-py-1 {
    padding-top: var(--space-1);
    padding-bottom: var(--space-1);
  }

  .lg\:rt-r-py-2 {
    padding-top: var(--space-2);
    padding-bottom: var(--space-2);
  }

  .lg\:rt-r-py-3 {
    padding-top: var(--space-3);
    padding-bottom: var(--space-3);
  }

  .lg\:rt-r-py-4 {
    padding-top: var(--space-4);
    padding-bottom: var(--space-4);
  }

  .lg\:rt-r-py-5 {
    padding-top: var(--space-5);
    padding-bottom: var(--space-5);
  }

  .lg\:rt-r-py-6 {
    padding-top: var(--space-6);
    padding-bottom: var(--space-6);
  }

  .lg\:rt-r-py-7 {
    padding-top: var(--space-7);
    padding-bottom: var(--space-7);
  }

  .lg\:rt-r-py-8 {
    padding-top: var(--space-8);
    padding-bottom: var(--space-8);
  }

  .lg\:rt-r-py-9 {
    padding-top: var(--space-9);
    padding-bottom: var(--space-9);
  }

  .lg\:rt-r-py-inset {
    padding-top: var(--inset-padding-top);
    padding-bottom: var(--inset-padding-bottom);
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-py {
    padding-top: var(--pt-xl);
    padding-bottom: var(--pb-xl);
  }

  .xl\:rt-r-py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .xl\:rt-r-py-1 {
    padding-top: var(--space-1);
    padding-bottom: var(--space-1);
  }

  .xl\:rt-r-py-2 {
    padding-top: var(--space-2);
    padding-bottom: var(--space-2);
  }

  .xl\:rt-r-py-3 {
    padding-top: var(--space-3);
    padding-bottom: var(--space-3);
  }

  .xl\:rt-r-py-4 {
    padding-top: var(--space-4);
    padding-bottom: var(--space-4);
  }

  .xl\:rt-r-py-5 {
    padding-top: var(--space-5);
    padding-bottom: var(--space-5);
  }

  .xl\:rt-r-py-6 {
    padding-top: var(--space-6);
    padding-bottom: var(--space-6);
  }

  .xl\:rt-r-py-7 {
    padding-top: var(--space-7);
    padding-bottom: var(--space-7);
  }

  .xl\:rt-r-py-8 {
    padding-top: var(--space-8);
    padding-bottom: var(--space-8);
  }

  .xl\:rt-r-py-9 {
    padding-top: var(--space-9);
    padding-bottom: var(--space-9);
  }

  .xl\:rt-r-py-inset {
    padding-top: var(--inset-padding-top);
    padding-bottom: var(--inset-padding-bottom);
  }
}

.rt-r-pt {
  padding-top: var(--pt);
}

.rt-r-pt-0 {
  padding-top: 0;
}

.rt-r-pt-1 {
  padding-top: var(--space-1);
}

.rt-r-pt-2 {
  padding-top: var(--space-2);
}

.rt-r-pt-3 {
  padding-top: var(--space-3);
}

.rt-r-pt-4 {
  padding-top: var(--space-4);
}

.rt-r-pt-5 {
  padding-top: var(--space-5);
}

.rt-r-pt-6 {
  padding-top: var(--space-6);
}

.rt-r-pt-7 {
  padding-top: var(--space-7);
}

.rt-r-pt-8 {
  padding-top: var(--space-8);
}

.rt-r-pt-9 {
  padding-top: var(--space-9);
}

.rt-r-pt-inset {
  padding-top: var(--inset-padding-top);
}

@media (min-width: 520px) {
  .xs\:rt-r-pt {
    padding-top: var(--pt-xs);
  }

  .xs\:rt-r-pt-0 {
    padding-top: 0;
  }

  .xs\:rt-r-pt-1 {
    padding-top: var(--space-1);
  }

  .xs\:rt-r-pt-2 {
    padding-top: var(--space-2);
  }

  .xs\:rt-r-pt-3 {
    padding-top: var(--space-3);
  }

  .xs\:rt-r-pt-4 {
    padding-top: var(--space-4);
  }

  .xs\:rt-r-pt-5 {
    padding-top: var(--space-5);
  }

  .xs\:rt-r-pt-6 {
    padding-top: var(--space-6);
  }

  .xs\:rt-r-pt-7 {
    padding-top: var(--space-7);
  }

  .xs\:rt-r-pt-8 {
    padding-top: var(--space-8);
  }

  .xs\:rt-r-pt-9 {
    padding-top: var(--space-9);
  }

  .xs\:rt-r-pt-inset {
    padding-top: var(--inset-padding-top);
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-pt {
    padding-top: var(--pt-sm);
  }

  .sm\:rt-r-pt-0 {
    padding-top: 0;
  }

  .sm\:rt-r-pt-1 {
    padding-top: var(--space-1);
  }

  .sm\:rt-r-pt-2 {
    padding-top: var(--space-2);
  }

  .sm\:rt-r-pt-3 {
    padding-top: var(--space-3);
  }

  .sm\:rt-r-pt-4 {
    padding-top: var(--space-4);
  }

  .sm\:rt-r-pt-5 {
    padding-top: var(--space-5);
  }

  .sm\:rt-r-pt-6 {
    padding-top: var(--space-6);
  }

  .sm\:rt-r-pt-7 {
    padding-top: var(--space-7);
  }

  .sm\:rt-r-pt-8 {
    padding-top: var(--space-8);
  }

  .sm\:rt-r-pt-9 {
    padding-top: var(--space-9);
  }

  .sm\:rt-r-pt-inset {
    padding-top: var(--inset-padding-top);
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-pt {
    padding-top: var(--pt-md);
  }

  .md\:rt-r-pt-0 {
    padding-top: 0;
  }

  .md\:rt-r-pt-1 {
    padding-top: var(--space-1);
  }

  .md\:rt-r-pt-2 {
    padding-top: var(--space-2);
  }

  .md\:rt-r-pt-3 {
    padding-top: var(--space-3);
  }

  .md\:rt-r-pt-4 {
    padding-top: var(--space-4);
  }

  .md\:rt-r-pt-5 {
    padding-top: var(--space-5);
  }

  .md\:rt-r-pt-6 {
    padding-top: var(--space-6);
  }

  .md\:rt-r-pt-7 {
    padding-top: var(--space-7);
  }

  .md\:rt-r-pt-8 {
    padding-top: var(--space-8);
  }

  .md\:rt-r-pt-9 {
    padding-top: var(--space-9);
  }

  .md\:rt-r-pt-inset {
    padding-top: var(--inset-padding-top);
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-pt {
    padding-top: var(--pt-lg);
  }

  .lg\:rt-r-pt-0 {
    padding-top: 0;
  }

  .lg\:rt-r-pt-1 {
    padding-top: var(--space-1);
  }

  .lg\:rt-r-pt-2 {
    padding-top: var(--space-2);
  }

  .lg\:rt-r-pt-3 {
    padding-top: var(--space-3);
  }

  .lg\:rt-r-pt-4 {
    padding-top: var(--space-4);
  }

  .lg\:rt-r-pt-5 {
    padding-top: var(--space-5);
  }

  .lg\:rt-r-pt-6 {
    padding-top: var(--space-6);
  }

  .lg\:rt-r-pt-7 {
    padding-top: var(--space-7);
  }

  .lg\:rt-r-pt-8 {
    padding-top: var(--space-8);
  }

  .lg\:rt-r-pt-9 {
    padding-top: var(--space-9);
  }

  .lg\:rt-r-pt-inset {
    padding-top: var(--inset-padding-top);
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-pt {
    padding-top: var(--pt-xl);
  }

  .xl\:rt-r-pt-0 {
    padding-top: 0;
  }

  .xl\:rt-r-pt-1 {
    padding-top: var(--space-1);
  }

  .xl\:rt-r-pt-2 {
    padding-top: var(--space-2);
  }

  .xl\:rt-r-pt-3 {
    padding-top: var(--space-3);
  }

  .xl\:rt-r-pt-4 {
    padding-top: var(--space-4);
  }

  .xl\:rt-r-pt-5 {
    padding-top: var(--space-5);
  }

  .xl\:rt-r-pt-6 {
    padding-top: var(--space-6);
  }

  .xl\:rt-r-pt-7 {
    padding-top: var(--space-7);
  }

  .xl\:rt-r-pt-8 {
    padding-top: var(--space-8);
  }

  .xl\:rt-r-pt-9 {
    padding-top: var(--space-9);
  }

  .xl\:rt-r-pt-inset {
    padding-top: var(--inset-padding-top);
  }
}

.rt-r-pr {
  padding-right: var(--pr);
}

.rt-r-pr-0 {
  padding-right: 0;
}

.rt-r-pr-1 {
  padding-right: var(--space-1);
}

.rt-r-pr-2 {
  padding-right: var(--space-2);
}

.rt-r-pr-3 {
  padding-right: var(--space-3);
}

.rt-r-pr-4 {
  padding-right: var(--space-4);
}

.rt-r-pr-5 {
  padding-right: var(--space-5);
}

.rt-r-pr-6 {
  padding-right: var(--space-6);
}

.rt-r-pr-7 {
  padding-right: var(--space-7);
}

.rt-r-pr-8 {
  padding-right: var(--space-8);
}

.rt-r-pr-9 {
  padding-right: var(--space-9);
}

.rt-r-pr-inset {
  padding-right: var(--inset-padding-right);
}

@media (min-width: 520px) {
  .xs\:rt-r-pr {
    padding-right: var(--pr-xs);
  }

  .xs\:rt-r-pr-0 {
    padding-right: 0;
  }

  .xs\:rt-r-pr-1 {
    padding-right: var(--space-1);
  }

  .xs\:rt-r-pr-2 {
    padding-right: var(--space-2);
  }

  .xs\:rt-r-pr-3 {
    padding-right: var(--space-3);
  }

  .xs\:rt-r-pr-4 {
    padding-right: var(--space-4);
  }

  .xs\:rt-r-pr-5 {
    padding-right: var(--space-5);
  }

  .xs\:rt-r-pr-6 {
    padding-right: var(--space-6);
  }

  .xs\:rt-r-pr-7 {
    padding-right: var(--space-7);
  }

  .xs\:rt-r-pr-8 {
    padding-right: var(--space-8);
  }

  .xs\:rt-r-pr-9 {
    padding-right: var(--space-9);
  }

  .xs\:rt-r-pr-inset {
    padding-right: var(--inset-padding-right);
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-pr {
    padding-right: var(--pr-sm);
  }

  .sm\:rt-r-pr-0 {
    padding-right: 0;
  }

  .sm\:rt-r-pr-1 {
    padding-right: var(--space-1);
  }

  .sm\:rt-r-pr-2 {
    padding-right: var(--space-2);
  }

  .sm\:rt-r-pr-3 {
    padding-right: var(--space-3);
  }

  .sm\:rt-r-pr-4 {
    padding-right: var(--space-4);
  }

  .sm\:rt-r-pr-5 {
    padding-right: var(--space-5);
  }

  .sm\:rt-r-pr-6 {
    padding-right: var(--space-6);
  }

  .sm\:rt-r-pr-7 {
    padding-right: var(--space-7);
  }

  .sm\:rt-r-pr-8 {
    padding-right: var(--space-8);
  }

  .sm\:rt-r-pr-9 {
    padding-right: var(--space-9);
  }

  .sm\:rt-r-pr-inset {
    padding-right: var(--inset-padding-right);
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-pr {
    padding-right: var(--pr-md);
  }

  .md\:rt-r-pr-0 {
    padding-right: 0;
  }

  .md\:rt-r-pr-1 {
    padding-right: var(--space-1);
  }

  .md\:rt-r-pr-2 {
    padding-right: var(--space-2);
  }

  .md\:rt-r-pr-3 {
    padding-right: var(--space-3);
  }

  .md\:rt-r-pr-4 {
    padding-right: var(--space-4);
  }

  .md\:rt-r-pr-5 {
    padding-right: var(--space-5);
  }

  .md\:rt-r-pr-6 {
    padding-right: var(--space-6);
  }

  .md\:rt-r-pr-7 {
    padding-right: var(--space-7);
  }

  .md\:rt-r-pr-8 {
    padding-right: var(--space-8);
  }

  .md\:rt-r-pr-9 {
    padding-right: var(--space-9);
  }

  .md\:rt-r-pr-inset {
    padding-right: var(--inset-padding-right);
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-pr {
    padding-right: var(--pr-lg);
  }

  .lg\:rt-r-pr-0 {
    padding-right: 0;
  }

  .lg\:rt-r-pr-1 {
    padding-right: var(--space-1);
  }

  .lg\:rt-r-pr-2 {
    padding-right: var(--space-2);
  }

  .lg\:rt-r-pr-3 {
    padding-right: var(--space-3);
  }

  .lg\:rt-r-pr-4 {
    padding-right: var(--space-4);
  }

  .lg\:rt-r-pr-5 {
    padding-right: var(--space-5);
  }

  .lg\:rt-r-pr-6 {
    padding-right: var(--space-6);
  }

  .lg\:rt-r-pr-7 {
    padding-right: var(--space-7);
  }

  .lg\:rt-r-pr-8 {
    padding-right: var(--space-8);
  }

  .lg\:rt-r-pr-9 {
    padding-right: var(--space-9);
  }

  .lg\:rt-r-pr-inset {
    padding-right: var(--inset-padding-right);
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-pr {
    padding-right: var(--pr-xl);
  }

  .xl\:rt-r-pr-0 {
    padding-right: 0;
  }

  .xl\:rt-r-pr-1 {
    padding-right: var(--space-1);
  }

  .xl\:rt-r-pr-2 {
    padding-right: var(--space-2);
  }

  .xl\:rt-r-pr-3 {
    padding-right: var(--space-3);
  }

  .xl\:rt-r-pr-4 {
    padding-right: var(--space-4);
  }

  .xl\:rt-r-pr-5 {
    padding-right: var(--space-5);
  }

  .xl\:rt-r-pr-6 {
    padding-right: var(--space-6);
  }

  .xl\:rt-r-pr-7 {
    padding-right: var(--space-7);
  }

  .xl\:rt-r-pr-8 {
    padding-right: var(--space-8);
  }

  .xl\:rt-r-pr-9 {
    padding-right: var(--space-9);
  }

  .xl\:rt-r-pr-inset {
    padding-right: var(--inset-padding-right);
  }
}

.rt-r-pb {
  padding-bottom: var(--pb);
}

.rt-r-pb-0 {
  padding-bottom: 0;
}

.rt-r-pb-1 {
  padding-bottom: var(--space-1);
}

.rt-r-pb-2 {
  padding-bottom: var(--space-2);
}

.rt-r-pb-3 {
  padding-bottom: var(--space-3);
}

.rt-r-pb-4 {
  padding-bottom: var(--space-4);
}

.rt-r-pb-5 {
  padding-bottom: var(--space-5);
}

.rt-r-pb-6 {
  padding-bottom: var(--space-6);
}

.rt-r-pb-7 {
  padding-bottom: var(--space-7);
}

.rt-r-pb-8 {
  padding-bottom: var(--space-8);
}

.rt-r-pb-9 {
  padding-bottom: var(--space-9);
}

.rt-r-pb-inset {
  padding-bottom: var(--inset-padding-bottom);
}

@media (min-width: 520px) {
  .xs\:rt-r-pb {
    padding-bottom: var(--pb-xs);
  }

  .xs\:rt-r-pb-0 {
    padding-bottom: 0;
  }

  .xs\:rt-r-pb-1 {
    padding-bottom: var(--space-1);
  }

  .xs\:rt-r-pb-2 {
    padding-bottom: var(--space-2);
  }

  .xs\:rt-r-pb-3 {
    padding-bottom: var(--space-3);
  }

  .xs\:rt-r-pb-4 {
    padding-bottom: var(--space-4);
  }

  .xs\:rt-r-pb-5 {
    padding-bottom: var(--space-5);
  }

  .xs\:rt-r-pb-6 {
    padding-bottom: var(--space-6);
  }

  .xs\:rt-r-pb-7 {
    padding-bottom: var(--space-7);
  }

  .xs\:rt-r-pb-8 {
    padding-bottom: var(--space-8);
  }

  .xs\:rt-r-pb-9 {
    padding-bottom: var(--space-9);
  }

  .xs\:rt-r-pb-inset {
    padding-bottom: var(--inset-padding-bottom);
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-pb {
    padding-bottom: var(--pb-sm);
  }

  .sm\:rt-r-pb-0 {
    padding-bottom: 0;
  }

  .sm\:rt-r-pb-1 {
    padding-bottom: var(--space-1);
  }

  .sm\:rt-r-pb-2 {
    padding-bottom: var(--space-2);
  }

  .sm\:rt-r-pb-3 {
    padding-bottom: var(--space-3);
  }

  .sm\:rt-r-pb-4 {
    padding-bottom: var(--space-4);
  }

  .sm\:rt-r-pb-5 {
    padding-bottom: var(--space-5);
  }

  .sm\:rt-r-pb-6 {
    padding-bottom: var(--space-6);
  }

  .sm\:rt-r-pb-7 {
    padding-bottom: var(--space-7);
  }

  .sm\:rt-r-pb-8 {
    padding-bottom: var(--space-8);
  }

  .sm\:rt-r-pb-9 {
    padding-bottom: var(--space-9);
  }

  .sm\:rt-r-pb-inset {
    padding-bottom: var(--inset-padding-bottom);
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-pb {
    padding-bottom: var(--pb-md);
  }

  .md\:rt-r-pb-0 {
    padding-bottom: 0;
  }

  .md\:rt-r-pb-1 {
    padding-bottom: var(--space-1);
  }

  .md\:rt-r-pb-2 {
    padding-bottom: var(--space-2);
  }

  .md\:rt-r-pb-3 {
    padding-bottom: var(--space-3);
  }

  .md\:rt-r-pb-4 {
    padding-bottom: var(--space-4);
  }

  .md\:rt-r-pb-5 {
    padding-bottom: var(--space-5);
  }

  .md\:rt-r-pb-6 {
    padding-bottom: var(--space-6);
  }

  .md\:rt-r-pb-7 {
    padding-bottom: var(--space-7);
  }

  .md\:rt-r-pb-8 {
    padding-bottom: var(--space-8);
  }

  .md\:rt-r-pb-9 {
    padding-bottom: var(--space-9);
  }

  .md\:rt-r-pb-inset {
    padding-bottom: var(--inset-padding-bottom);
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-pb {
    padding-bottom: var(--pb-lg);
  }

  .lg\:rt-r-pb-0 {
    padding-bottom: 0;
  }

  .lg\:rt-r-pb-1 {
    padding-bottom: var(--space-1);
  }

  .lg\:rt-r-pb-2 {
    padding-bottom: var(--space-2);
  }

  .lg\:rt-r-pb-3 {
    padding-bottom: var(--space-3);
  }

  .lg\:rt-r-pb-4 {
    padding-bottom: var(--space-4);
  }

  .lg\:rt-r-pb-5 {
    padding-bottom: var(--space-5);
  }

  .lg\:rt-r-pb-6 {
    padding-bottom: var(--space-6);
  }

  .lg\:rt-r-pb-7 {
    padding-bottom: var(--space-7);
  }

  .lg\:rt-r-pb-8 {
    padding-bottom: var(--space-8);
  }

  .lg\:rt-r-pb-9 {
    padding-bottom: var(--space-9);
  }

  .lg\:rt-r-pb-inset {
    padding-bottom: var(--inset-padding-bottom);
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-pb {
    padding-bottom: var(--pb-xl);
  }

  .xl\:rt-r-pb-0 {
    padding-bottom: 0;
  }

  .xl\:rt-r-pb-1 {
    padding-bottom: var(--space-1);
  }

  .xl\:rt-r-pb-2 {
    padding-bottom: var(--space-2);
  }

  .xl\:rt-r-pb-3 {
    padding-bottom: var(--space-3);
  }

  .xl\:rt-r-pb-4 {
    padding-bottom: var(--space-4);
  }

  .xl\:rt-r-pb-5 {
    padding-bottom: var(--space-5);
  }

  .xl\:rt-r-pb-6 {
    padding-bottom: var(--space-6);
  }

  .xl\:rt-r-pb-7 {
    padding-bottom: var(--space-7);
  }

  .xl\:rt-r-pb-8 {
    padding-bottom: var(--space-8);
  }

  .xl\:rt-r-pb-9 {
    padding-bottom: var(--space-9);
  }

  .xl\:rt-r-pb-inset {
    padding-bottom: var(--inset-padding-bottom);
  }
}

.rt-r-pl {
  padding-left: var(--pl);
}

.rt-r-pl-0 {
  padding-left: 0;
}

.rt-r-pl-1 {
  padding-left: var(--space-1);
}

.rt-r-pl-2 {
  padding-left: var(--space-2);
}

.rt-r-pl-3 {
  padding-left: var(--space-3);
}

.rt-r-pl-4 {
  padding-left: var(--space-4);
}

.rt-r-pl-5 {
  padding-left: var(--space-5);
}

.rt-r-pl-6 {
  padding-left: var(--space-6);
}

.rt-r-pl-7 {
  padding-left: var(--space-7);
}

.rt-r-pl-8 {
  padding-left: var(--space-8);
}

.rt-r-pl-9 {
  padding-left: var(--space-9);
}

.rt-r-pl-inset {
  padding-left: var(--inset-padding-left);
}

@media (min-width: 520px) {
  .xs\:rt-r-pl {
    padding-left: var(--pl-xs);
  }

  .xs\:rt-r-pl-0 {
    padding-left: 0;
  }

  .xs\:rt-r-pl-1 {
    padding-left: var(--space-1);
  }

  .xs\:rt-r-pl-2 {
    padding-left: var(--space-2);
  }

  .xs\:rt-r-pl-3 {
    padding-left: var(--space-3);
  }

  .xs\:rt-r-pl-4 {
    padding-left: var(--space-4);
  }

  .xs\:rt-r-pl-5 {
    padding-left: var(--space-5);
  }

  .xs\:rt-r-pl-6 {
    padding-left: var(--space-6);
  }

  .xs\:rt-r-pl-7 {
    padding-left: var(--space-7);
  }

  .xs\:rt-r-pl-8 {
    padding-left: var(--space-8);
  }

  .xs\:rt-r-pl-9 {
    padding-left: var(--space-9);
  }

  .xs\:rt-r-pl-inset {
    padding-left: var(--inset-padding-left);
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-pl {
    padding-left: var(--pl-sm);
  }

  .sm\:rt-r-pl-0 {
    padding-left: 0;
  }

  .sm\:rt-r-pl-1 {
    padding-left: var(--space-1);
  }

  .sm\:rt-r-pl-2 {
    padding-left: var(--space-2);
  }

  .sm\:rt-r-pl-3 {
    padding-left: var(--space-3);
  }

  .sm\:rt-r-pl-4 {
    padding-left: var(--space-4);
  }

  .sm\:rt-r-pl-5 {
    padding-left: var(--space-5);
  }

  .sm\:rt-r-pl-6 {
    padding-left: var(--space-6);
  }

  .sm\:rt-r-pl-7 {
    padding-left: var(--space-7);
  }

  .sm\:rt-r-pl-8 {
    padding-left: var(--space-8);
  }

  .sm\:rt-r-pl-9 {
    padding-left: var(--space-9);
  }

  .sm\:rt-r-pl-inset {
    padding-left: var(--inset-padding-left);
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-pl {
    padding-left: var(--pl-md);
  }

  .md\:rt-r-pl-0 {
    padding-left: 0;
  }

  .md\:rt-r-pl-1 {
    padding-left: var(--space-1);
  }

  .md\:rt-r-pl-2 {
    padding-left: var(--space-2);
  }

  .md\:rt-r-pl-3 {
    padding-left: var(--space-3);
  }

  .md\:rt-r-pl-4 {
    padding-left: var(--space-4);
  }

  .md\:rt-r-pl-5 {
    padding-left: var(--space-5);
  }

  .md\:rt-r-pl-6 {
    padding-left: var(--space-6);
  }

  .md\:rt-r-pl-7 {
    padding-left: var(--space-7);
  }

  .md\:rt-r-pl-8 {
    padding-left: var(--space-8);
  }

  .md\:rt-r-pl-9 {
    padding-left: var(--space-9);
  }

  .md\:rt-r-pl-inset {
    padding-left: var(--inset-padding-left);
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-pl {
    padding-left: var(--pl-lg);
  }

  .lg\:rt-r-pl-0 {
    padding-left: 0;
  }

  .lg\:rt-r-pl-1 {
    padding-left: var(--space-1);
  }

  .lg\:rt-r-pl-2 {
    padding-left: var(--space-2);
  }

  .lg\:rt-r-pl-3 {
    padding-left: var(--space-3);
  }

  .lg\:rt-r-pl-4 {
    padding-left: var(--space-4);
  }

  .lg\:rt-r-pl-5 {
    padding-left: var(--space-5);
  }

  .lg\:rt-r-pl-6 {
    padding-left: var(--space-6);
  }

  .lg\:rt-r-pl-7 {
    padding-left: var(--space-7);
  }

  .lg\:rt-r-pl-8 {
    padding-left: var(--space-8);
  }

  .lg\:rt-r-pl-9 {
    padding-left: var(--space-9);
  }

  .lg\:rt-r-pl-inset {
    padding-left: var(--inset-padding-left);
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-pl {
    padding-left: var(--pl-xl);
  }

  .xl\:rt-r-pl-0 {
    padding-left: 0;
  }

  .xl\:rt-r-pl-1 {
    padding-left: var(--space-1);
  }

  .xl\:rt-r-pl-2 {
    padding-left: var(--space-2);
  }

  .xl\:rt-r-pl-3 {
    padding-left: var(--space-3);
  }

  .xl\:rt-r-pl-4 {
    padding-left: var(--space-4);
  }

  .xl\:rt-r-pl-5 {
    padding-left: var(--space-5);
  }

  .xl\:rt-r-pl-6 {
    padding-left: var(--space-6);
  }

  .xl\:rt-r-pl-7 {
    padding-left: var(--space-7);
  }

  .xl\:rt-r-pl-8 {
    padding-left: var(--space-8);
  }

  .xl\:rt-r-pl-9 {
    padding-left: var(--space-9);
  }

  .xl\:rt-r-pl-inset {
    padding-left: var(--inset-padding-left);
  }
}

.rt-r-position-static {
  position: static;
}

.rt-r-position-absolute {
  position: absolute;
}

.rt-r-position-relative {
  position: relative;
}

.rt-r-position-fixed {
  position: fixed;
}

.rt-r-position-sticky {
  position: -webkit-sticky;
  position: sticky;
}

@media (min-width: 520px) {
  .xs\:rt-r-position-static {
    position: static;
  }

  .xs\:rt-r-position-absolute {
    position: absolute;
  }

  .xs\:rt-r-position-relative {
    position: relative;
  }

  .xs\:rt-r-position-fixed {
    position: fixed;
  }

  .xs\:rt-r-position-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-position-static {
    position: static;
  }

  .sm\:rt-r-position-absolute {
    position: absolute;
  }

  .sm\:rt-r-position-relative {
    position: relative;
  }

  .sm\:rt-r-position-fixed {
    position: fixed;
  }

  .sm\:rt-r-position-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-position-static {
    position: static;
  }

  .md\:rt-r-position-absolute {
    position: absolute;
  }

  .md\:rt-r-position-relative {
    position: relative;
  }

  .md\:rt-r-position-fixed {
    position: fixed;
  }

  .md\:rt-r-position-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-position-static {
    position: static;
  }

  .lg\:rt-r-position-absolute {
    position: absolute;
  }

  .lg\:rt-r-position-relative {
    position: relative;
  }

  .lg\:rt-r-position-fixed {
    position: fixed;
  }

  .lg\:rt-r-position-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-position-static {
    position: static;
  }

  .xl\:rt-r-position-absolute {
    position: absolute;
  }

  .xl\:rt-r-position-relative {
    position: relative;
  }

  .xl\:rt-r-position-fixed {
    position: fixed;
  }

  .xl\:rt-r-position-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

.rt-r-w {
  width: var(--width);
}

@media (min-width: 520px) {
  .xs\:rt-r-w {
    width: var(--width-xs);
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-w {
    width: var(--width-sm);
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-w {
    width: var(--width-md);
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-w {
    width: var(--width-lg);
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-w {
    width: var(--width-xl);
  }
}

.rt-r-min-w {
  min-width: var(--min-width);
}

@media (min-width: 520px) {
  .xs\:rt-r-min-w {
    min-width: var(--min-width-xs);
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-min-w {
    min-width: var(--min-width-sm);
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-min-w {
    min-width: var(--min-width-md);
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-min-w {
    min-width: var(--min-width-lg);
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-min-w {
    min-width: var(--min-width-xl);
  }
}

.rt-r-max-w {
  max-width: var(--max-width);
}

@media (min-width: 520px) {
  .xs\:rt-r-max-w {
    max-width: var(--max-width-xs);
  }
}

@media (min-width: 768px) {
  .sm\:rt-r-max-w {
    max-width: var(--max-width-sm);
  }
}

@media (min-width: 1024px) {
  .md\:rt-r-max-w {
    max-width: var(--max-width-md);
  }
}

@media (min-width: 1280px) {
  .lg\:rt-r-max-w {
    max-width: var(--max-width-lg);
  }
}

@media (min-width: 1640px) {
  .xl\:rt-r-max-w {
    max-width: var(--max-width-xl);
  }
}

